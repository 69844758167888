@import '../../styles/base';

.OverlaySessionDetails {
  border-top: color('primary') 3px solid;
  background-image: linear-gradient(to bottom, color('primary', .3), color('primary', .1) calc(var(--AppPageHeaderHeight) + 3em));
  .AppPageContent {
    padding-top: size(md);
  }
  .AppPageAfterTitle {
    color: inherit;
  }
  .SessionSummaryAssignmentList {
    h4 {
      color: inherit;
    }
  }
}