@import '../../styles/base';

@keyframes toastEnter {
  from {
    opacity: 0;
    transform: translateY(-100%);
    box-shadow: 0 1.5em 5em color('dark',0);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 1.5em 5em color('dark',.3);
  }
}

@keyframes toastExit {
  from {
    transform: translateY(0);
    box-shadow: 0 1.5em 3em color('dark',.3);
  }
  to {
    transform: translateY(-138%);
    box-shadow: 0 1.5em 3em color('dark',0);
  }
}

.ToastLayer {
  .ToastStack & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @media print {
      display: none;
    }
  }
  font-size: 1.4rem;
  @extend %colorCodedStates;
  &.secondary {
    @include setPrimaryColor('secondary');
  }
  &.opening {
    pointer-events: none;
  }
  &.noGestures {
    .ToastDeepInner {
      background-color: color('background');
      padding: 0;
      display: flex;
      align-self: stretch;
    }
    .ToastContent {
      padding: 1em;
    }
  }
}

.ToastCard {
  display: block;
  text-decoration: none;
  .ToastStack & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    padding: .5em;
  }
  overflow: visible;
  transform: translate3d(0,0,0);
  code {
    overflow-wrap: break-word;
  }
  ion-item-sliding {
    border-radius: .5em;
  }
  ion-item {
    --ion-border-color: transparent;
  }
  transition: .1s;
  &:active {
    transform: scale(.99);
  }
}

.ToastInner {
  margin-left: auto;
  margin-right: auto;
  color: color('foreground');
  width: 100%;
  overflow: hidden;
  border-radius: .5em;
  max-width: 100%;
  @media #{$tablet} {
    width: 50em;
    [data-device-model="iPad"][data-orientation="portrait"] & {
      width: 50rem
    }
  }
  .ToastStack & {
    border-width: 4px 0 0;
    border-style: solid;
    border-color: color('primary');
    opacity: 0;
    animation: toastEnter .38s cubic-bezier(.38,0,0,1) forwards;
    .--cypress & {
      animation-duration: 0s;
    }
  }
  .ToastStack .closing & {
    pointer-events: none;
    opacity: 1;
    animation: toastExit .38s cubic-bezier(.19,0,0,1) forwards;
    .--cypress & {
      animation-duration: 0s;
    }
  }
  ion-item-sliding {
    background-color: color('alert');
    transform: translate3d(0,0,0);
  }
}

.ToastDeepInner {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: .75em;
  padding-bottom: .75em;
  transform: translate3d(0,0,0);
  .ToastStack & {
    align-items: center;
    @media #{$only-phone} {
      min-height: 5em;
    }
  }
}


.ToastIconWrapper {
  flex: 0 0 3.6em;
  overflow: hidden;
  height: 3.6em;
  margin-right: .62em;
  border-radius: 50%;
  > * {
    width: 100%;
    height: 100%;
  }
}

.ToastContent {
  transform: translate3d(0,0,0);
  width: 100%;
  .TimeDisplayer {
    float: right;
    opacity: .38;
    font-weight: 600;
    font-size: 80%;
    font-weight: 700;
    font-feature-settings: none;
    font-family: inherit;
    margin-top: .19em;
    margin-left: .5em;
  }
}

.ToastHeading {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.19;
}
.ToastBody {
  margin-top: .19em;
  font-size: 1.4rem;
  font-weight: 400;
}

.ToastMeta {
  margin-top: .25em;
  font-weight: 700;
  font-size: 95%;
}

.ToastControls {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  .BaseIcon {
    width: 1.4em;
    height: 1.4em;
  }
  .BaseButtonLabel {
    font-weight: 700;
  }
}