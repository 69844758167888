@import '../../styles/base';

.TwoColumnView {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	@media #{$tablet} {
		display: flex;
		.ModuleAdmin & {
			height: calc(var(--AppHeight) - var(--TitleBarHeight));
			overflow: hidden;
		}
		.ModuleClient & {
			height: 100%;
			overflow: hidden;
		}
	}
  @media print {
		display: block;
		height: auto;
		overflow: visible;
	}
}
.TwoColumnViewAside {
	position: relative;
	@media #{$tablet} {
		flex: 0 0 25rem;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
		.--cypress & {
			scroll-behavior: unset;
		}
		height: 100%;
	}
	@media #{$desktop-md} {
		flex: 0 0 27.5rem;
	}
	@media #{$desktop-lg} {
		flex: 0 0 30rem;
	}
  @media print {
		display: none;
	}
	.HeadingAllcaps {
		margin-top: 1em;
		font-size: 1.4rem;
		@media #{$tablet} {
			font-size: 1.2rem;
			padding-top: .19em;
			&:first-of-type {
				margin-top: 0;
			}
		}
	}
	.BaseMenu {
		@media #{$tablet} {
			font-size: 1.4rem;
		}
	}
	.BaseMenuItem {
		@media #{$tablet} {
			padding-left: .62em;
			padding-right: .5em;
		}
	}
}
.TwoColumnViewContentPanel {
	// background-color: color('background');
	z-index: 1;
	@media #{$only-phone} {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		.hasVisibleContent & {
			display: block;
		}
	}
	@media #{$tablet} {
		position: relative;
		flex: 1 1 auto;
		.RouterStack {
			height: 100%;
		}
	}
	.RouterStackLayer {
		height: 100%;
		overflow: hidden;
		.AppPage {
			min-height: 100%;
			height: 100%;
			max-height: 100%;
		}
		@media print {
			display: block;
			height: auto;
			overflow: visible;
		}
	}
}

.TwoColumnViewContentPanelEmptyViewNotice {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: size(lg);
	opacity: .5;
  user-select: none;
	text-align: center;
	@media print {
		display: none;
	}
	svg {
		margin-bottom: .5em;
	}
}
