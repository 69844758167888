@import '../../styles/base';

@keyframes OverlayThoughtThoughtCardEnter {
  from {
    transform: translateY(1em);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.OverlayThought {
  .ThoughtCard {
    box-shadow: 0 .2em .62em color('dark', .1);
    animation: OverlayThoughtThoughtCardEnter .62s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  transition: 1s;
  background-image: linear-gradient(175.79deg, color('white', 0) -23.79%, color('white', 1) 38%);
  &[data-emotion="1"] {
    background-color: #ebc9b6;
    @include setPrimaryColor('orange');
  }
  &[data-emotion="2"] {
    background-color: #b1dbb8;
    @include setPrimaryColor('blueGreen');
  }
  &[data-emotion="3"] {
    background-color: #b1d5db;
    @include setPrimaryColor('skyblue');
  }
  &[data-emotion="4"] {
    background-color: #b1c0db;
    @include setPrimaryColor('brightPurple');
  }
  &[data-emotion="5"] {
    background-color: #bfc3cf;
    @include setPrimaryColor('deepPurple');
  }
}

.OverlayThoughtCompact {
  &[data-emotion="1"] {
    @include setPrimaryColor('orange');
  }
  &[data-emotion="2"] {
    @include setPrimaryColor('blueGreen');
  }
  &[data-emotion="3"] {
    @include setPrimaryColor('skyblue');
  }
  &[data-emotion="4"] {
    @include setPrimaryColor('brightPurple');
  }
  &[data-emotion="5"] {
    @include setPrimaryColor('deepPurple');
  }
  .OverlayCloseButton {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .ThoughtCard {
    overflow: visible;
  }
  .CommentComposer {
    background-color: color('background');
    position: sticky;
    bottom: 0;
  }
}