@import '../../../../../styles/base';

.DateAndWeatherGreeting {
  display: flex;
  padding-left: size(lg);
  padding-right: size(lg);
  padding-bottom: size(md);
  align-items: center;
  header {
    flex: 1 1 auto;
  }
  h2 {
    font-size: inherit;
    font-weight: 800;
    @extend %allcaps;
  }
  p {
    @extend %allcaps;
    opacity: .62;
  }
  svg {
    margin-top: -.5em;
  }
}