@import '../../styles/base';

.UserInfoTable {
  word-break: break-word;
  &.shaded {
    background-color: color('primary', .1);
    border: 2px solid color('primary', .38);
    padding: 1em;
    border-radius: var(--CardRadius);
    + .UserInfoTable {
      margin-top: .5em;
    }
  }
  .BaseGrid[data-columns="1"] {
    > * {
      + * {
        border-top: 1px solid color('primary', .1)
      }
    }
  }
  td.UserAddress {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .BaseButton {
      white-space: nowrap;
    }
  }
}