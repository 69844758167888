@import '../../styles/base/index';

.RatingInputStar {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: transparent;
  stroke-width: 1.5;
  stroke: currentColor;
  transition: .1s;
  &.isFull {
    fill: currentColor;
  }
  &:active {
    transform: scale(.9);
  }
}