@import '../../../../../styles/base';

.AccountPagePrivacy {
  .AppPageHeaderSlot.topEnd {
    min-height: 2em;
  }
}
.AccountPagePrivacySaveNotice {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: color('green', .2);
  color: color('blueGreen');
  border-radius: 3em;
  font-weight: 800;
  padding: .5em 1em;
  margin-top: -.19em;
  margin-bottom: -.19em;
}