@import '../../../../styles/base';

.DashBlockCountBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
	color: color('white');
	background-color: color('red');
	font-weight: 800;
	border-radius: 1em;
	padding: .19em .5em;
	font-size: 1.4rem;
}