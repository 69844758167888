$defaultFontStack: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Ubuntu","Helvetica Neue",sans-serif';

$fonts: (
  system: $defaultFontStack,
  sans: '"Atten New","atten-new",' + $defaultFontStack,
  ui: '"Atten Round New","atten-round-new",' + $defaultFontStack,
  heading: '"Atten Round New","atten-round-new",' + $defaultFontStack,
  monospace: 'Menlo,Monaco,Consolas,"Courier New",monospace',
) !default;

@function getFont($name) {
  @return var(--#{$namespace}-f-#{$name});
};
@function font($name) {
  @return getFont($name);
}
@mixin setFont($name, $value) {
  $preset: map-get($fonts, $name);
  @if ($preset) {
    @if type-of($preset) == 'map' {
      --#{$namespace}-f-#{$name}: #{map-get($preset, default)};
      @supports (font-variation-settings: normal) {
        --#{$namespace}-f-#{$name}: #{map-get($preset, variable)};
      }
    } @else {
      --#{$namespace}-f-#{$name}: #{$preset};
    }
  } @else {
    --#{$namespace}-f-#{$name}: #{var(--#{$namespace}-f-#{$value})};
  }
};

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

