@import '../../../../../styles/base';

.ExploreGroupRecommendations {
  > * {
    + * {
      margin-top: size(md);
    }
  }
}

.ExploreGroupRecommendationsEmptyNotice {
  padding: 1em;
  text-align: center;
  background-color: color('background');
  border-radius: .5em;
  &.--loading {
    color: color('blueGreen');
  }
}

.ExploreGroupRecommendationsFindMoreButton {
  @extend %cardShadow;
}