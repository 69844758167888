@import '../../../../../styles/base';

.AccountPageSupportGroups {
  .IndexDirectorySearchBarAndControlsWrapper {
    padding-top: 0;
  }
  .SupportGroupSummaryCardInner {
    border: 2px solid color('primary', .19);
  }
  .IndexDirectoryListEmptyListNotice {
    background-color: color('primary', .19);
    border-radius: .5em;
  }
}

.ExploreGroupRecommendationsFindMoreButton {
  @extend %cardShadow;
}