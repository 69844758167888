@import '../../styles/base';

.TimeframeSelectorSet {
  background-color: color('background');
  border-bottom: 1px solid color('primary', .19);
  display: flex;
  align-items: center;
  &[data-display-mode="phone"] {
    font-size: 1.4rem;
  }
  &[data-display-mode="tablet"],
  &[data-display-mode="desktop"] {
    padding: 1em size(md);
  }
}

.TimeframeSelectorSetInner {
  flex: 1 1 auto;
  .TimeframeSelectorSet[data-display-mode="phone"] & {
    order: 2;
    text-align: center;
  }
  h3 {
    font-size: inherit;
    color: color('primary');
    @media print {
      display: none;
    }
  }
  data {
    .TimeframeSelectorSet[data-display-mode="tablet"],
    .TimeframeSelectorSet[data-display-mode="desktop"] & {
      font-size: 119%;
    }
    @media print {
      font-size: inherit;
    }
  }
  time {
    font-weight: 700;
  }
}

.TimeframeSelectorSetControls {
  flex: 0 0 auto;
  text-align: right;
  .BaseButtonLabel {
    font-size: 1.4rem;
  }
  .TimeframeSelectorSet[data-display-mode="phone"] & {
    margin-top: .5em;
  }
  .TimeframeSelectorSet[data-display-mode="tablet"],
  .TimeframeSelectorSet[data-display-mode="desktop"] & {
    margin-left: 1em;
  }
  @media print {
    display: none;
  }
}

.TimeframeSelectorPaginationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;
  flex: 0 0 auto;
  color: color('primary');
  background-color: transparent;
  @media print {
    display: none;
  }
  .TimeframeSelectorSet[data-display-mode="phone"] & {
    width: 2em;
    height: 2em;
    &.--prev { order: 1;}
    &.--next { order: 3;}
  }
  .TimeframeSelectorSet[data-display-mode="desktop"] &,
  .TimeframeSelectorSet[data-display-mode="tablet"] & {
    margin-right: .62em;
    background-color: color('primary', .19);
    border-radius: 50%;
  }
  .TimeframeSelectorSet[data-display-mode="tablet"] & {
    width: 2.4em;
    height: 2.4em;
  }
  .TimeframeSelectorSet[data-display-mode="desktop"] & {
    width: 3.6em;
    height: 3.6em;
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.95);
  }
}