@import '../../styles/base';

.OverlayView[data-appearance="card"][data-name="OverlayThoughtComposer"] {
  .Overlay {
    @media #{$tablet} {
      border-radius: 1.75em;
    }
  }
}

.OverlayThoughtComposer {
  color: color('white');
  transition: background-color .62s;
  &[data-emotion="1"] {
    background-color: #ec6f1c;
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(240, 213, 147, 0.95) 0%, rgba(243, 218, 126, 0) 66.67%);
  }
  &[data-emotion="2"] {
    background-color: color('blueGreen');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(44, 193, 204, 0.95) 0%, rgba(182, 251, 255, 0) 66.67%);
  }
  &[data-emotion="3"] {
    background-color: rgb(20, 121, 238);
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(31, 203, 233, 0.95) 0%, rgba(26, 115, 218, 0) 66.67%);
  }
  &[data-emotion="4"] {
    background-color: color('blue');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(212, 182, 236, 0.95) 0%, rgba(239, 226, 241, 0) 66.67%);
  }
  &[data-emotion="5"] {
    background-color: color('dark');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(112, 154, 189, 0.95) 0%, rgba(40, 244, 255, 0.014) 66.67%);
  }
  .OverlayCloseButton {
    position: absolute;
    top: 1em;
    right: 1em;
  }
}

.OverlayThoughtComposerHeader {
  text-align: center;
  padding-top: 1.62em;
  text-shadow: 0 .1em .2em rgba(0,0,0,0.2);
  > * {
    + * {
      margin-top: .5em;
    }
  }
  p {
    font-weight: 700;
    padding-left: 3em;
    padding-right: 3em;
  }
  h3 {
    font-weight: 400;
    font-size: 2.2rem;
    @media #{$tablet} {
      font-size: 2.4rem;
    }
  }
}

.OverlayThoughtComposerContent {
  padding-top: .5em;
  // @media #{$tablet} {
  //   padding-top: .75em;
  // }
  padding-left: size(md);
  padding-right: size(md);
  padding-bottom: size(md);
  .ThoughtEditorTextareaFieldSet {
    margin-top: .75em;
    @media #{$only-phone} {
      > * {
        + * {
          margin-top: .5em;
        }
      }
    }
    @media #{$tablet} {
      display: grid;
      grid-gap: .5em .75em;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: 'content content' 'situation responsee';
      .nogrid & {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        [data-name="content"] {
          flex: 1 1 100%;
          margin-bottom: .5em;
        }
        [data-name="situation"] {
          padding-right: .25em;
          flex: 1 1 50%;
        }
        [data-name="response"] {
          padding-left: .25em;
          flex: 1 1 50%;
        }
      }
    }
  }
  [data-name="content"] {
    grid-area: content;
    .BaseLabel {
      display: none;
    }
  }
  .ThoughtEditorSaveButton,
  .ThoughtEditorCancelButton {
    @media #{$tablet} {
      min-width: 12em;
    }
  }
}
