@import '../../styles/base';

.ChatWindowStack {
  &[data-mode='docked'] {
    z-index: 1;
    @media #{$only-phone} {
      display: none !important;
    }
    @media screen {
      position: fixed;
      right: 0;
      bottom: 0;
      min-width: 100%;
    }
    &[data-number-of-opened-windows="2"] {
      .ChatWindowStackInner {
        > * {
          &.--opened {
            @media #{$tablet} { 
              min-width: 36em; 
            }
          }
        }
      }
    }
    &[data-number-of-opened-windows="3"],
    &[data-number-of-opened-windows="4"],
    &[data-number-of-opened-windows="5"] {
      .ChatWindowStackInner {
        > * {
          &.--opened {
            @media #{$tablet} { 
              min-width: 34em; 
            }
          }
        }
      }
    }
    &[data-number-of-opened-windows="many"] {
      .ChatWindowStackInner {
        > * {
          &.--opened {
            @media #{$tablet} { 
              min-width: 33em; 
            }
          }
        }
      }
    }
  }
}

.ChatWindowStackInner {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: flex-end;
  padding-right: .5em;
  > * {
    flex: 0 1 auto;
    &.--opened {
      min-width: 68rem;
      @media #{$desktop-md} { min-width: 72rem; }
      @media #{$desktop-lg} { min-width: 76rem; }
    }
    + * {
      margin-left: .19em;
    }
  }
}