@import '../../../styles/base';

.ModuleNotifications {
  min-height: var(--AppHeight);
	@media #{$only-phone} {
    padding-bottom: var(--AppNavigationHeight);
  }
  .ModuleClient & {
    @media #{$tablet} {
      padding-top: size(md);
      background-color: color('primary', .19);
    }
  }
}
.ModuleNotificationsInner {
  padding: 1em;
  > * {
    + * {
      margin-top: 1em;
    }
  }
}

.ModuleNotificationsEmpty {
  text-align: center;
  margin-top: 2em;
}