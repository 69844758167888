@import '../../../../styles/base';

.AdminTitleBar {
	position: sticky;
	top: 0;
	user-select: none;
	.UserComputedRoleList {
		overflow: auto;
		white-space: nowrap;
		max-width: calc(100vw - 8em);
		@extend %hideScrollbars;
	}
}
.AdminTitleBarInner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: size(md);
	padding-right: size(md);
	position: relative;
	top: 0;
	right: 0;
	padding-top: env(safe-area-inset-top);
	background-color: color('background');
	color: color('foreground');
	@media #{$only-phone} {
		left: 0;
	}
	@media #{$tablet} {
    left: var(--AppNavigationWidth);
	}
	[data-enable-blur-effect="true"] & {
		background-color: color('background', .9);
		backdrop-filter: blur(1em);
	}
}
.AdminTitleBarStart {
	padding-top: .19em;
	padding-bottom: .5em;
	flex: 1 1 1px;
}
.AdminTitleBarRoleDisplay {
	text-transform: uppercase;
}
.AdminTitleBarCenter {
	display: none;
	text-align: center;
	h1 {
		font-size: inherit;
	}
	@media #{$tablet} {
		display: block;
	}
}
.AdminTitleBarEnd {
  .AdminNavControlSet {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.AdminTitleBarAccountButton {
	text-decoration: none;
}