@import '../../styles/base';

@keyframes nextSessionHighlighter {
  from {
    background-position: left 0% top 50%;
  }
  to {
    background-position: left 100% top 50%;
  }
}
.SessionSummaryCard {
  width: 100%;
  &.upcoming {
    &:before {
      content: '';
      background-color: color('red');
      background-image: linear-gradient(45deg, 
        color('red'), color('yellow'), 
        color('red'), color('yellow'),
        color('red')
      );
      background-size: 262% 100%;
      background-repeat: no-repeat;
      animation: nextSessionHighlighter 2s infinite linear;
      display: block;
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border-radius: calc(var(--CardRadius) + 3px);
      .--cypress & {
        animation: none;
      }
    }
  }
  .SmallHeaderButton {
		color: inherit;
		background-color: color('foreground', .19);
    font-size: 1.2rem;
    margin-top: -.38em;
	}
  transition: transform .1s;
  cursor: pointer;
  &:active {
    transform: scale(.99);
  }
  .SessionOrGroupTimeDisplayDate {
    padding-right: 0;
  }
  &.upcoming {
    .SessionOrGroupTimeDisplayDateStartingTag {
      span {
        background-color: color('orange');
        color: color('white');
        border-radius: .62em;
        line-height: 1;
        padding: .19em .38em;
        border: 1px solid color('white', .1);
      }
    } 
  }
}

.SessionSummaryCardInner {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1em;
  overflow: hidden;
  background-color: color('background');
  color: color('foreground');
	min-height: 100%;
  max-width: 100%;
  border-radius: var(--CardRadius);
  .upcoming & {
    background-image: linear-gradient(133deg, contrastColor('primary', .1), contrastColor('primary', 0));
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.SessionSummaryCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.SessionSummaryCardHeaderInner {
  flex: 1 1 auto;
}

.SessionSummaryCardTitle {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
}

.SessionSummaryCardClientDetails {
  font-size: 1.8rem;
  margin-top: .38em;
  font-weight: 500;
}


.SessionSummaryStartingTag {
  color: white;
  background-color: color('red');
  padding: .18em .5em;
  border-radius: 1em;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
}

.SessionSummaryAssignmentList {
  display: flex;
  flex: 1 0 auto;
  max-width: calc(100% + 2em);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @extend %hideScrollbars;
  margin-left: -1em;
  margin-right: -1em;
  padding-left: 1em;
  padding-right: 1em;
  align-items: stretch;
  > * {
    flex-basis: 33.33%;
    + * {
      margin-left: .5em;
    }
  }
}