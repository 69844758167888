@import '../../styles/base';

.ThoughtPrivateTag {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 80%;
  background-color: color('foreground', .19);
  border-radius: 1em;
  padding: 0 .5em;
  line-height: 1;
  white-space: nowrap;
  @media #{$only-phone} {
    padding: .38em;
    .ThoughtCard & {
      margin-top: -.09em;
      margin-right: -.09em;
    }
  }
  strong {
    position: relative;
    top: .1em;
    @media #{$only-phone} {
      display: none !important;
    }
  }
}