@import '../../styles/base';

.ColorLabelDot {
  display: inline-block;
  width: 9px;
  border-radius: 50%;
  * + &,
  & + * {
    margin-left: .33em;
  }
  vertical-align: baseline;
  -webkit-print-color-adjust: exact;
  &.noColor {
    border: 1px solid color('foreground',.19);
  }
  &:before {
    content: '';
    padding-top: 100%;
    display: block;
  }
}