@import '../../../../../styles/base';

.CounsellingApplicationStepWelcome {
  .IrishFlag {
    position: absolute;
    top: 0;
    right: 1em;
    transform: translateY(-38%) rotate(5deg);
    border-radius: .3em;
    box-shadow: 0 1px 3px rgba(0,0,0,0.19);
  }

  .CounsellingApplicationStepWelcomeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$only-phone} {
      flex-direction: column;
      align-items: flex-start;
      h3 {
        order: 1;
      }
      h2 {
        order: 0;
      }
    }
  }

  .CounsellingApplicationStepWelcomeTermsCheckbox {
    background-color: color('yellow', .2);
    padding: .5em 1em;
    border-radius: .5em;

    .SubInfo {
      font-size: 80%;
      font-weight: 400;
    }
  }
}