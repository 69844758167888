@import '../../styles/base';

.OptionsPanel {
  align-self: stretch;
  max-height: 100%;
}
.OptionsPanelInner {
  position: sticky;
  top: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - var(--AppPageHeaderHeight));
}
.OptionsPanelHeader {
  padding: 1em;
  h3 {
    color: color('primary');
  }
  + .OptionsPanelSection {
    margin-top: -.5em;
  }
}