@import '../../styles/base';

.CommentList {
  background-color: color('primary', .062);
  padding-top: .62em;
  padding-bottom: .62em;
  > * {
    padding: .38em 1em;
    + * {
      border-top: 1px solid color('primary', .1);
    }
  }
}