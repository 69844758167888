@import '../../styles/base';

.DOMCopyButton {
	border-radius: 50%;
	user-select: none;
	&:hover {
		opacity: 1;
	}
	.BaseIcon {
		width: 1.8em;
		height: 1.8em;
	}
	&.showButtonBackground {
		background-color: color('primary', .1);
	}
}
