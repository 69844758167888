@import '../../styles/base';

.AppPage {
  color: color('foreground');
  background-color: color('background');
  --AppPageWidth: 100%;
  --AppPageHeight: 100%;
  .Overlay {
    overflow: hidden;
    @media #{$only-phone} {
      height: var(--AppHeight);
      max-height: var(--AppHeight);
    }
  }
  @media print {
    overflow: auto;
    height: auto;
  }

  .UICard & {
    width: var(--UICardOpenedWidth, var(--AppWidth));
  }
}