@import '../../styles/base';

.DatePicker {
  .SingleDatePicker {
    color: inherit;
  }
  .SingleDatePicker,
  .SingleDatePickerInput,
  .DateInput {
    background-color: transparent;
    display: block;
    width: 100%;
  }
  input[type="date"],
  .DateInput_input {
    appearance: none;
    font-size: inherit;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    @extend %defaultInput;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
  .CalendarMonth_caption {
    color: inherit;
  }
  table {
    border-color: color('foreground', .2);
    color: color('foreground');
  }
  .DayPickerNavigation_button__default {
    color: color('foreground');
    border-color: color('foreground', .2);
  }
  .DayPicker_weekHeader {
    color: color('foreground');
  }
  .CalendarDay__default {
    color: color('foreground');
  }
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background-color: color('primary');
    color: contrastColor('primary');
    border-color: color('primary');
  }
  .DateInput_fang {
    top: 100% !important;
  }
  .SingleDatePicker_picker {
    top: calc(100% + 10px) !important;
  }
}