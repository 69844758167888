@import '../../styles/base';

.ChatWindowVideoFrame {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 100%);
  grid-template-rows: minmax(0, 1fr) auto;
  background-color: color('dark', .875);
  [data-mode='docked'] & {
    min-height: 61.8%;
  }
  .nogrid & {
    display: flex;
    flex-direction: column;
    height: 300px;
    min-height: 300px;
  }
  color: color('white');
  min-height: 300px;
  overflow: hidden;
  @media print {
    display: none;
  }
  &.fill-viewport {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    width: var(--vw);
    height: 100vh;
    height: var(--vh);
    background-color: color('dark', .98);
  }
  &.shouldDisplaySelfInCorner {
    .ChatWindowVideoRoomParticipant[data-is-self="true"] {
      position: absolute;
      bottom: .5em;
      right: .5em;
      width: 5em;
      z-index: 1;
      .ChatWindowVideoRoomParticipantNameTag {
        display: none;
      }
    }
  }
  &.hasOnlyOneVideoStream {
    overflow: hidden;
  }
}

.ChatWindowVideoFrameInner {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: inherit;
  .nogrid & {
    flex: 1 1 auto;
  }
}

.ChatWindowVideoRoomParticipantList {
  position: relative;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.ChatWindowVideoRoomParticipantListInner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1 1 100%;
  max-height: 100%;
  > .ChatWindowVideoRoomParticipant {
    max-height: 100%;
    height: 100%;
    max-height: var(--VideoFrameContainerHeight);
    height: var(--VideoFrameContainerHeight);
    .shouldDisplaySelfInCorner & {
      height: auto;
    }
    &:only-child {
      flex: 1 1 100%;
    }
  }
  .hasOnlyOneVideoStream & {
    height: 100%;
  }
}

.ChatWindowVideoFrameMessage {
  text-align: center;
  font-style: italic;
  position: absolute;
  top: .5em;
  max-width: 95%;
  background-color: color('foreground', .5);
  color: color('background', .75);
  left: 50%;
  transform: translateX(-50%);
  padding: .19em 1em;
  border-radius: 1em;
  font-size: 1.4rem;
}

.ChatWindowVideoFrameFullscreenToggle {
  position: absolute;
  right: .5em;
  bottom: .5em;
  min-width: 0;
}

.ChatWindowVideoFrameFooter {
  display: flex;
  background-color: color('dark', .8);
  position: sticky;
  bottom: 0;
  padding-left: .5em;
  font-size: 1.4rem;
  .nogrid & {
    flex: 0 0 auto;
  }
  .fill-viewport & {
    @media #{$tablet} {
      font-size: 1.6rem;
    }
  }
  .BaseButton {
    padding: 1em .5em;
    font-size: inherit;
    &:hover {
      background-color: color('white', .1);
    }
  }
}
.ChatWindowVideoFrameFooterInner {
  flex: 1 1 auto;
  display: flex;
  .BaseButton {
    .BaseButtonLabel {
      text-align: left;
    }
  }
}
.ChatWindowVideoFrameFooterEndSlot {
  flex: 0 0 auto;
  .BaseButton {
    padding: 1em;
  }
}

.ChatWindowVideoFrameCallDeclinedNotice {
  display: flex;
  align-items: center;
  text-align: center;
  > div {
    flex: 1 1 auto;
    padding-left: 1em;
    padding-right: 1em;
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
}