@import '../../styles/base';

.TelInputInner {
  position: relative;
  .CountryDialCodeSelector {
    position: absolute;
    left: .38em;
    top: 50%;
    transform: translateY(-50%);
    padding: .25em;
  }
  .BaseInputInput {
    padding-left: 6em;
    text-align: left;
  }
}