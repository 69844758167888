@import '../../styles/base';

.SupportGroupTopicEditor {
  .BaseHeading {
    color: color('primary');
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 800;
    + * {
      margin-top: .5em;
    }
  }
}
.SupportGroupTopicEditorInner {
  display: grid;
  grid-gap: 1em;
  .nogrid & {
    display: flex;
    gap: 0;
  }
  .SupportGroupTopicEditor[data-from-tablet="true"] & {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "form footer" "form preview";
    align-items: start;
    > * {
      background-color: color('primary', .1);
      border-radius: .75em;
      padding: 1em;
    }
  }
}
.SupportGroupTopicEditorForm {
  .SupportGroupTopicEditor[data-from-tablet="true"] & {
    grid-area: form;
  }
}
.SupportGroupTopicEditorPreviewWrapper {
  .SupportGroupTopicEditor[data-from-tablet="true"] & {
    grid-area: preview;
  }
  .SupportGroupSummaryCard {
    .SupportGroupTopicEditor[data-only-phones="true"] & {
      width: 100%;
    }
    .SupportGroupTopicEditor[data-from-tablet="true"] & {
      width: 32.5rem;
    }
  }
}
.SupportGroupTopicEditorFooter {
  .SupportGroupTopicEditor[data-from-tablet="true"] & {
    grid-area: footer;
    .nogrid & {
      flex: 0 0 30rem;
      margin-left: 1em;
    }
  }
}
.SupportGroupTopicEditorFooterClickableItem,
.SupportGroupTopicEditorFooterControls {  
  background-color: color('primary', .1);
  padding: 1em;
  border-radius: .62em;
}
.SupportGroupTopicEditorFooterClickableItem {
  cursor: pointer;
  &:hover {
    background-color: color('primary', .05);
  }
  &:active {
    background-color: color('primary', .15);
  }
}
.SupportGroupTopicEditorSlugFieldGroup {
  display: grid;
  grid-gap: .5em;
  grid-template-rows: auto auto;
  .nogrid & {
    display: block;
  }
  .SupportGroupTopicEditor[data-from-tablet="true"] & {
    grid-template-columns: 1fr auto;
    align-items: end;
    .nogrid & {
      display: flex;
      .BaseInput {
        flex: 1 1 auto;
      }
    }
  }
}