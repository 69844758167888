@import '../../../../../styles/base';

.CounsellingApplicationStepReviewPersonalInfo {
  .formWrapper {
    @media #{$tablet} {
      padding: 1em;
      background-color: color('background');
      border-radius: .5em;
      margin-top: .5em;
      box-shadow: 0 .1em .6em color('primary', .2);
    }
  }
}