@import '../../styles/base';

.ColorPickerInner {
  background-color: color('primary', .1);
  padding: 1em;
  border-radius: .38em;
  .twitter-picker {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    @media #{$only-phone} {
      max-width: 300px;
    }
    > div {
      &:nth-child(1) {
        display: none !important;
      }
      &:nth-child(2) {
        display: none !important;
      }
      &:nth-child(3) {
        padding: 0 !important;
      }
    }
  }
  input {
    font-family: font(monospace) !important;
  }
}