@import '../../styles/base';

.BaseTableBodyEmptyNotice {
  pointer-events: none;
  td {
    background-color: color('foreground', .05);
    text-align: center;
    color: color('foreground', .62);
    font-style: italic;
    padding-top: .5em;
    padding-bottom: .5em;
  }
}