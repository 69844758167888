@import '../../../styles/base';

.ModuleAdminDashboard {
}

.ModuleAdminDashboardGrid {
  display: grid;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  grid-template-columns: 100%;
  grid-gap: .5rem;
  background-color: color('skyblue', .19);
  min-height: var(--AppHeight);
  @media #{$only-phone} {
    padding-bottom: var(--AppNavigationHeight);
    .nogrid & {
      > * {
        flex: 1 1 100%;
        margin-top: .25em;
      }
    }
  }
  .nogrid & {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }
  @media #{$tablet} {
    padding: .5em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'upcomingSessions upcomingSessions' 'alertList supportGroups' 'alertList supportGroups';
    .nogrid & {
       > * {
        flex: 1 1 50%;
        border: color('skyblue', .19) 1px solid;
      }
    }
  }
  @media #{$desktop} {
    padding: 1em;
    grid-gap: 1em;
    .DashBlock {
      border-radius: 1em;
    }
  }
  .SessionSummaryCardInner {
    box-shadow: 0 .1em .5em rgba(0,0,0,0.08);
  }
}


@media #{$tablet} {
  .AdminDashBlockUpcomingSessionsAndGroups {
    grid-area: upcomingSessions;
    .nogrid & {
      flex: 1 1 100%;
    }
  }
  .AdminDashBlockAlertList {
    grid-area: alertList;
  }
  .AdminDashBlockTeamSchedule {
    grid-area: teamSchedule;
  }
  .AdminDashBlockSupportGroups {
    grid-area: supportGroups;
  }
}

.AdminDashActionsRequiredEmpty {
  background-color: color('skyblue', .1);
  text-align: center;
  padding: .62em .75em .75em;
  font-size: 1.6rem;
  border-radius: .38em;
  color: color('blueGreen');
  .BaseIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.AdminDashSupportGroupListButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(133deg, color('blueGreen', .19), color('neonGreen', .1));
  padding: 1em;
  border-radius: .5em;
  color: color('blueGreen');
  font-weight: 700;
  text-decoration: none;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.9);
  }
}
.AdminDashSupportGroupListButtonIcon {
  background-color: color('white', .38);
  border-radius: 50%;
  padding: .75em;
  margin-right: .5em;
  .BaseIcon {
    display: block;
  }
}

.AdminDashSupportGroupList {
  margin-top: 1em;
  > * {
    + * {
      margin-top: 1em;
    }
  }
}