@import '../../styles/base';

.BaseCalendarBodyContent {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  .shouldDisplayAsTimeline &,
  .fitInnerToAvailableHeight & {
    display: block;
    height: 100%;
    tbody,
    .BaseCalendarBodyContentInner {
      display: flex;
      flex-direction: column;
      height: 100%;
      > * {
        flex: 0 0 100% / 7;
        max-width: 100% / 7;
        overflow: auto;
      }
    }
  }
  .shouldDisplayAsTimeline & {
    position: relative;
  }
}