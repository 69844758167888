@import '../../../../../../styles/base';

@keyframes CounsellingApplicationSubmissionScreenEnter {
  from { opacity: 0; transform: scale(1.25) }
  to { opacity: 1; transform: scale(1) }
}
@keyframes CounsellingApplicationSubmissionScreenExit {
  from { opacity: 1; transform: scale(1) }
  to { opacity: 0; transform: scale(1.19) }
}

.CounsellingApplicationSubmissionScreen {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-height: 640px) {
    align-items: center;
  }
  padding: size(lg);
  text-align: center;
  background-color: color('primary');
  color: contrastColor('primary');
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: unset;
  }
  animation: CounsellingApplicationSubmissionScreenEnter .38s;
  &.closing {
    animation: CounsellingApplicationSubmissionScreenExit .19s forwards;
  }
}

.CounsellingApplicationSubmissionScreenLoadingMessage {
  > * {
    + * {
      margin-top: 2rem;
    }
  }
}

.CounsellingApplicationSubmissionScreenSuccessMessage {
  display: inline-block;
  animation: CounsellingApplicationSubmissionScreenEnter .75s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-left: auto;
  margin-right: auto;
  max-width: 38em;
  padding: size(lg);
  padding-bottom: calc(#{size(lg)} + env(safe-area-inset-bottom));
  > * {
    + * {
      margin-top: 2rem;
    }
  }
  h1 {
    font-weight: 500;
    font-size: 2.6rem;
  }
}

.CounsellingApplicationSubmissionScreenSuccessMessageInvitationInfo {
  background-color: color('white', .1);
  padding: 1em;
  border: 2px solid color('white', .2);
  border-radius: 1em;
  .ClickToCopy {
    @include setPrimaryColor('green');
    background-color: color('green', .2);
    padding: 1em;
    border: 2px solid color('green', .5);
    border-radius: .5em;
    margin-top: .5em;
    word-break: break-all;
  }
}