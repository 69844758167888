@import '../../styles/base';

.MessengerPanelSessionList {
  border-top: 1px solid color('primary', .2);
  > header {
    display: flex;
    align-items: center;
    user-select: none;
    background-color: color('primary', .062);
    padding: .5em .5em .5em 1em;
    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      color: color('primary');
      flex: 1 1 auto;
      [data-mode="normal"] & {
        padding-left: size(md);
      }
    }
    &:hover {
      background-color: color('primary', .1);
    }
    &:active {
      background-color: color('primary', .15);
    }
    .BaseIcon {
      color: color('primary');
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .MessengerPanelChatEntry {
    + .MessengerPanelChatEntry {
      border-top: var(--BorderMinor);
    }
  }
}

.MessengerPanelSessionListEmptyNotice {
  padding: .5em 1em;
  opacity: .62;
  font-style: italic;
  [data-mode="normal"] & {
    padding-left: size(md);
  }
}