@import '../../../../../styles/base';

.StatDataItemGrid {
  page-break-inside: avoid;
}
.StatDataItemGridInner {
  display: grid;
  grid-gap: .5em;
  .nogrid & {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex: 1 1 auto;
    }
  }
  @media print {
    grid-template-columns: repeat(6, 1fr);
    [data-max-columns="3"] & {
      grid-template-columns: repeat(3, 1fr);
    }
    [data-max-columns="4"] & {
      grid-template-columns: repeat(4, 1fr);
    }
    > * {
      page-break-inside: avoid;
    }
  }
}
