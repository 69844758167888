@import '../../styles/base';

.BaseToggle {
  display: flex;
  position: relative;
  transform: translateZ(0);
  user-select: none;
  [type="radio"],
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  &.fullWidth {
    display: block;
    .BaseToggleInner {
      display: flex;
    }
  }
  &.BaseToggleToggle {
    .BaseToggleBox {
      width: 16px * 2;
      height: 16px;
    }
    .BaseToggleBoxMarker {
      border-radius: 5em;
      width: 14px;
      height: 14px;
      background-color: color('background');
    }
    &.true {
      .BaseToggleBoxMarker {
        right: 1px;
        background-color: color('white');
      }
    }
    &.false {
      .BaseToggleBoxMarker {
        right: 50%;
        // opacity: .5;
      }
    }
  }
  &.BaseToggleCheckbox,
  &.BaseToggleRadio {
    .BaseToggleBox {
      width: 16px;
      height: 16px;
    }
    .BaseToggleBoxMarker {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    &.true {
      .BaseToggleBoxMarker {
        opacity: 1;
      }
    }
  }
  &.BaseToggleCheckbox {
    .BaseToggleBox {
      border-radius: .3em;
    }
  }
  &.BaseToggleRadio {
    .BaseToggleBox {
      border-radius: 50%;
    }
  }
  &.true {
    .BaseToggleBox {
      background-color: color('primary',.9);
    }
    .BaseToggleBoxCheck {
      width: 66.67%;
      height: 66.67%;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: .62;
  }
}
.BaseToggleInner {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: .38em;
  margin-left: -.38em;
  margin-right: -.38em;
  cursor: pointer;
  border-radius: .2em;
  width: calc(100% + .62em);
  .BaseToggle.readonly & {
    cursor: default;
  }
  .BaseToggle:not(.readonly) &:hover {
    background-color: color('primary', .1);
    .BaseToggleBox {
      filter: brightness(1.1);
    }
  }
  .BaseToggle:not(.readonly) input:focus + & {
    background-color: color('primary', .15);
  }
  .BaseToggle.inverted {
    &:hover {
      background-color: color('white', .1);
    }
    input:focus + & {
      background-color: color('white', .15);
    }
  }
  .BaseToggle:not(.readonly) &:active {
    .BaseToggleBox {
      filter: brightness(.9);
    }
  }
  &.disabled {
    @include setPrimaryColor('gray');
  }
}
.BaseToggleLabel {
  display: block;
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
  a {
    color: color('primary');
    font-weight: 700;
    @extend %underlinedLinks;
    .inverted & {
      color: inherit;
    }
  }
}
.BaseToggleBox {
  position: relative;
  box-sizing: content-box;
  display: block;
  flex: 0 0 auto;
  background-color: color('foreground',.2);
  border: 1.5px solid color('foreground',.3);
  border-radius: 5em;
  margin-right: .5em;
  transform: translateZ(0);
  .inverted & {
    background-color: color('white',.2);
    border: 1.5px solid color('white',.3);
  }
}
.BaseToggleBoxMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 85%;
  letter-spacing: normal;
  font-size: 1rem;
}
.BaseToggleBoxCheck {
  width: 75%;
  height: 75%;
  color: contrastColor('primary');
}