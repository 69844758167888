@import '../../../../styles/base';

.OverlayUserManager {
  &.young-people-12-14 {
    .AppPageHeaderInner {
      background-color: color('yellow', .2);
    }
  }
  &.young-people-15-17 {
    .AppPageHeaderInner {
      background-color: color('orange', .2);
    }
  }
  &.suspended {
    .AppPageHeaderInner {
      background-color: color('red', .2);
    }
  }
  &.deleted {
    .AppPageHeaderInner {
      background-color: color('median', .2);
    }
  }
  .AppPageAfterTitle {
    .UserComputedRoleList {
      display: inline-block;
    }
  }
}

.OverlayUserManagerInner {
  padding-left: size(md);
  padding-right: size(md);
  @media #{$tablet} {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 25rem;
    grid-gap: 1em;
    .nogrid & {
      display: flex;
    }
  }
  .ThoughtFeed {
    background-color: color('primary', .1);
    padding: size(md);
    border-radius: 1.5em;
    border: 2px solid color('primary', .19);
  }
}

@media #{$tablet} {

  .OverlayUserManagerMainColumn {
    .nogrid & {
      flex: 1 1 auto;
    }
  }

  .OverlayUserManagerAside {
    .nogrid & {
      flex: 0 0 27.5rem;
    }
  }

}