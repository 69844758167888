@import '../../styles/base';

.TimeframeQuickPickerButton {
	appearance: none;
	border: none;
	color: color('primary');
	font-weight: 700;
	padding: .5em .75em;
	border-radius: .3em;
	background-color: color('primary', .2);
	&:hover {
		filter: brightness(1.1);
	}
	&:active {
		filter: brightness(.9);
	}
	&.selected {
		background-color: color('positive');
		color: contrastColor('positive');
	}
}