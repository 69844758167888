@import '../../../../../styles/base';

.CounsellingApplicationStepRiskAssessmentOption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
  background: color('background');
  color: color('foreground');
  font-weight: 700;
  &[data-selected=true] {
    &.--true {
      background-color: color('alert');
      color: contrastColor('alert');
    }
    &.--false {
      background-color: color('primary');
      color: contrastColor('primary');
    }
  }
}

.CounsellingApplicationStepRiskAssessmentOptionGroup {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2,1fr);
  max-width: 25em;
  .nogrid & {
    display: block;
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
}

.CounsellingApplicationStepRiskAssessmentWarning {
  background-color: color('orange', .2);
  background-image: linear-gradient(133deg, color('orange', .3), color('orange', 0));
  border: 1px solid color('orange');
  border-radius: .5em;
  padding: size(ml);
  color: color('dark');
  > .BaseIcon {
    color: color('dark');
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
}