@import '../../styles/base';

.ThoughtTypeRenderer {
  text-transform: capitalize;
  white-space: nowrap;
  &[data-type="public"] {
    color: color('foreground', .75);
  }
  &[data-type="private"] {
    color: #7976FF;
  }
}