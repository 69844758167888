@import '../../styles/base';

.BaseHeading {
  display: block;
  font-family: font(heading);
  @extend %paddable;
  + * {
    margin-top: .5em;
  }
  &[data-primary-color] {
    color: color('primary');
  }
}