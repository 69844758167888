@import '../../styles/base';

.JoinVideoCallConfirmation {
  &[data-video-on="false"] {
    video {
      opacity: .3;
    }
  }
  .BaseToggleLabel {
    white-space: nowrap;
  }
  @media #{$landscape-phone} {
    display: flex;
    flex-direction: row-reverse;
    .JoinVideoCallConfirmationToggleList {
      display: block;
      flex: 1 1 auto;
      padding-left: 1em;
    }
  }
}

.JoinVideoCallConfirmationToggleList {
  display: flex;
  > * {
    margin-right: .5em;
  }
}

.LocalTrackPreviewWrapper {
  @media #{$landscape-phone} {
    width: 225px;
  }
  @media #{$only-phone} {
    width: 250px;
    margin-left: auto; 
    margin-right: auto; 
  }
}
.LocalTrackPreview {
  position: relative;
  background-color: color('dark');
  color: color('white');
  margin-top: .5em;
  border-radius: .38em;
  overflow: hidden;
  video {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  p {
    position: absolute;
    left: 1em;
    right: 1em;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}