@import '../../styles/base';

.IconWithCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 3.4rem;
  height: 3.4rem;
  &[data-appearance="dot"] {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-weight: 800;
      background-color: color('red');
      font-size: 1rem;
      color: contrastColor('red');
      padding: .2em .3em .1em .3em;
      border-radius: 3em;
      top: 0;
      right: 19%;
      transform: translateX(50%);
      min-width: 1.2em;
      transition: .1s;
    }
  }
  &[data-appearance="number"] {
    span {
      font-weight: 700;
      color: color('foreground', .75);
    }
  }
  &.roundedWithBackground {
    background-color: color('primary', .1);
    border-radius: 50%;
  }
  span {
    &:empty {
      opacity: 0;
      font-size: 0;
    }
  }
}