@import '../../styles/base';

.AlertCardList {
  max-width: 38em;
  margin-left: auto;
  margin-right: auto;
  > * {
    + * {
      margin-top: .62em;
      @media #{$tablet} {
        margin-top: 1em;
      }
    }
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
  }
  &[data-should-drop-shadow="true"] {
    .ToastCard {
      box-shadow: 0 .5em 1em color('dark', .1);
    }
  }
}

.AlertCardListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AlertCardListCounter {
  display: inline-block;
  background-color: color('foreground', .1);
  font-size: 75%;
  padding: .19em .5em;
  border-radius: 1em;
  margin-left: .38em;
}