@import '../../styles/base';

.OverlaySubmitFlag {
  background-color: color('alert', .1);
  .ThoughtCard,
  .CommentEntry {
    border: 2px solid color('alert');
  }
  .CommentEntry {
    background-color: color('background');
    padding: 1em;
    border-radius: .5em;
  }
}

.OverlaySubmitFlagConfirmHeading {
  color: color('alert');
  margin-bottom: .5em;
}