@import '../../styles/base';
 
.OverlaySurveyGeneral {
  .AppPageHeaderInner {
    padding-bottom: size(md);
  }
  .Turn2MeLogo {
    color: color('primary');
  }
  .AppPageContent {
    .UIBlock {
      padding-top: size(md);
      padding-bottom: size(md);
    }
  }
}