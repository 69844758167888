@import '../../styles/base';

.PrintButton {
  @media #{$only-phone} {
    border-radius: 50%;
    .BaseButtonInner {
      border-radius: 50%;
    }
  }
  @media print {
    display: none !important;
  }
}