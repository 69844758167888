@import '../../styles/base';

.OverlayGoalSheetViewer {
  background-color: color('blueGreen', .1);
  .BaseHeading {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 800;
    margin-top: 1.6rem;
    + * {
      margin-top: .5em;
    }
  }
  .BodyCopyRenderer {
    display: block;
    background-color: color('blueGreen', .1);
    border: 2px solid color('blueGreen', .2);
    padding: 1em;
    border-radius: .5em;
    min-height: 6em;
  }
  .AppPageContent {
    .BaseButton {
      min-width: 10em;
    }
  }
}