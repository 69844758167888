@import '../../styles/base';

.CommentEntry {
  h3 {
    display: inline;
    margin-right: .38em;
    font-size: inherit;
    font-weight: 700;
    strong {
      font-weight: inherit;
    }
  }
  .CommentEntryBody {
    display: inline;
  }
  &.edit {
    .CommentEntryBody {
      display: block;
    }
  }
  .BodyCopyRenderer {
    display: inline;
  }
  .CommentEntryTimestamp {
    margin-left: .38em;
    opacity: .5;
    font-family: inherit;
    font-feature-settings: normal;
  }
  .FlaggableItemManager {
    margin-top: .25em;
  }
  &.hidden {
    background-color: color('dark', .05);
    .BodyCopyRenderer {
      font-style: italic;
      em {
        opacity: .5;
      }
    }
  }
  &.deleted {
    background-color: color('dark', .19);
    filter: grayscale(1);
  }
  &.menuOpen {
    box-shadow: inset 0 0 1px 2px color('primary', .5);
  }
  &.block {
    .CommentEntryBody {
      display: block;
    }
    .CommentEntryBodyContent {
      display: block;
      margin-top: .5em;
      margin-bottom: .5em;
    }
    .CommentEntryTimestamp {
      margin-left: 0;
      font-weight: 400;
    }
  }
  .FlagCounter {
    margin-right: .5em;
    background-color: color('red', .19);
    border-radius: .3em;
    padding: .1em .5em;
    font-weight: 800;
    .BaseIcon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.CommentEntryContent {
  display: flex;
  .ColorTag {
    margin-right: .5em;
  }
}
.CommentEntryContentInner {
  flex: 1 1 auto;
}

.CommentEntryOriginalCopy {
  opacity: .75;
  font-style: italic;
}

.CommentEntryControls {
  margin-top: -.19em;
  display: flex;
  align-items: center;
  padding-left: .5em;
}