@import '../../styles/base';

.ChatWindow {
  [data-mode='docked'] & {
    position: relative;
    min-width: 25rem;
    transition: min-width .1s;
    @media print {
      display: none;
    }
  }
  &[data-mode="standalone"] {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    min-height: 0;
    border-radius: 0;
    .nogrid & {
      display: block;
    }
    @media print {
      display: flex;
      flex-direction: column;
    }
  }
  @media print {
    height: unset !important;
    max-height: unset !important;
    border: none !important;
  }
  .ColorTag {
    font-size: 1rem;
  }
}