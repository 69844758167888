.CounsellingApplicationStepCommunicationType {
  > * {
    + * {
      margin-top: 1em;
    }
  }
}

.CommunicationTypeSelectorGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1em;
  .nogrid & {
    display: block;
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
}