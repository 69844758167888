@import '../../styles/base';

.NavBarHeightSpacer {
	width: 100%;
	.Overlay & {
		display: none;
	}
	@media #{$tablet} {
		display: none;
	}
}