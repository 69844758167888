@import '../../styles/base';

.MessengerPanelChatEntry {
  display: block;
  text-decoration: none;
  padding: .62em 1em;
  [data-mode="normal"] & {
    padding-left: size(md);
  }
  cursor: pointer;
  &:hover {
    background-color: color('primary', .1);
  }
  h4 {
    font-size: inherit;
  }
  &.active {
    background-color: color('primary',.9);
    color: contrastColor('primary');
  }
}
.MessengerPanelChatEntryInner {
  display: flex;
  .SupportGroupIcon {
    margin-right: .5em;
    flex: 0 0 auto;
  }
}
.MessengerPanelChatEntryPreTitle {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  opacity: .62;
}