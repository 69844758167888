@import '../../styles/base';

.OverlaySupportGroupViewer {
  background-image: linear-gradient(to bottom, color('primary', .19), color('primary', 0) 10em);
  border-top: 2px solid color('primary');
  .AppPageHeaderSlot.start {
    align-self: start;
  }
  .AppPageBeforeTitle {
    font-size: 1.2rem;
    @media #{$tablet} {
      font-size: 1.4rem;
    }
    margin-bottom: .19em;
  }
  &.for-front-line-workers {
    .AppPageHeaderInner {
      background-color: color('aqua', .19);
    }
    .AppPageContent {
      padding-top: size(md);
    }
  }
  &.archived {
    .AppPageHeaderInner {
      background-color: color('median', .1);
    }
    .AppPageContent {
      padding-top: size(md);
    }
  }
}

.OverlaySupportGroupViewerSubtitle {
  font-size: 1.6rem;
  + * {
    margin-top: .38em;
  }
}

.SupportGroupViewerAvailabilityDisplayInner {
  &:empty {
    display: none;
  }
  h4 {
    font-size: 2rem;
    color: color('primary');
    @media #{$tablet} {
      font-size: 2.4rem;
    }
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.SupportGroupViewerStartingTag {
  color: white;
  background-color: color('red');
  padding: .18em .5em;
  border-radius: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: .19em;
  display: inline-block;
}


.SupportGroupViewerTimeDisplay {
  display: flex;
  align-items: center;
  color: color('primary');
	border-radius: .38em;
	.BaseButton {
		color: inherit;
	}
}

.SupportGroupViewerTimeDisplayTime {
  display: block;
  font-size: 3.8rem;
  font-weight: 700;
}

.SupportGroupViewerTimeDisplayDate {
  display: block;
  font-weight: 600;
  flex: 1 1 auto;
  padding: 0 .5em;
}

