.BaseCalendarTimelineAxis {
	display: inline-block;
	padding-top: var(--BaseCalendarDayHeaderHeight);
}
.BaseCalendarTimelineAxisStep {
	font-feature-settings: 'tnum' 1;
	text-align: right;
	font-size: 1.2rem;
	height: 4em;
	padding-right: .5em;
}