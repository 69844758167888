@import '../../../../styles/base';

.CounsellorStatSection {
  .TimeframeSelectorSet {
    margin-bottom: .5em;
    border-radius: .5em;
    padding-left: 0;
    padding-right: 0;
  }
  .TimeframeSelectorSetInner {
    data {
      font-size: 1.6rem;
    }
  }
  .StatSectionSatisfactionSurveys {
    padding: 1em;
    background-color: color(blueGreen, .1);
    .StatSectionTitle {
      color: color(primary);
    }
    .StatSectionSubtitle {
      color: color(foreground);
      font-weight: 400;
    }
    .StatSectionHeaderControls {
      display: none;
    }
  }
}