.CounsellingSessionStarter {
  &:empty {
    display: none;
    + * {
      margin-top: 0 !important;
    }
  }
  .UIBlockHeader + & {
    margin-top: 0 !important;
  }
}