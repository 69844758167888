@import '../../styles/base';

.PaymentMethodItem {
  background-color: color('skyblue', .1);
  border-radius: 3px;
  border: 2px solid color('skyblue', .15);
  display: grid;
  padding-left: 1em;
  grid-gap: .5em;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  border-radius: .5em;
  .nogrid & {
    display: flex;
    gap: 0;
  }
  &.interactable {
    &:hover {
      border-color: color('skyblue');
      &.selected {
        border-color: color('green');
        background-color: color('green', .19);
      }
    }
  }
  &.selected {
    border-color: color('green');
    background-color: color('green', .1);
  }
  .delete-link {
    opacity: .75;
    &:hover {
      opacity: 1;
      color: color('red')
    }
  }
}
.PaymentMethodItemInner {
  padding: .62em .38em;
  .nogrid & {
    padding-left: 1em;
  }
  > * {
    margin: 0;
  }
}
h3.PaymentMethodItemCardHolderName {
  color: inherit;
  font-size: inherit;
  margin: 0;
  + p {
    margin-top: 0;
  }
}
.PaymentMethodItemSelectedMarker {
  background-color: color('green');
  color: contrastColor('green');
  height: 100%;
  padding: 1em;
  display: flex;
  align-items: center;
}