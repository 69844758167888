$unit: 4.8rem;
$sizes: (
  xs: $unit / 6,
  sm: $unit / 4,
  md: (
    default: $unit / 3.5,
    md: $unit / 2.38,
    lg: $unit / 2,
  ),
  ml: (
    default: $unit / 2.38,
    md: $unit / 2,
    lg: $unit / 1.19,
  ),
  lg: (
    default: $unit / 2,
    md: $unit / 1.19,
    lg: $unit / 1,
  ),
  xl: (
    default: $unit / 1.38,
    md: $unit / .75,
    lg: $unit / .618,
  ),
) !default;

@function getSize($value, $multiplier: 1) {
  @if $multiplier == 1 {
    @return var(--#{$namespace}-s-#{$value});
  } @else {
    @return calc(var(--#{$namespace}-s-#{$value}) * #{$multiplier});
  }
}

@function size($value, $multiplier: 1) {
  @return getSize($value, $multiplier);
}

@function spacing($value, $multiplier: 1) {
  @return getSize($value, $multiplier);
}