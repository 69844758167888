@import '../../styles/base';

.SupportGroupsIndex {
  display: flex;
  flex-direction: column;
  &.fullHeight {
    height: 100%;
    flex: 1 0 auto;
  }
  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
  > .CommandList {
    background-color: color('primary', .2);
  }
}