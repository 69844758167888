@import '../../../../../styles/base';

.ExploreThoughtCatcherComposerOverlayPrompt {
  border-radius: var(--CardRadius);
  background-color: color('skyblue');
  color: color('white');
  background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(31, 203, 233, 0.95) 0%, rgba(26, 115, 218, 0) 66.67%);
  text-align: center;
  padding: 2em size(md) 1.5em size(md);
  text-shadow: 0 .1em .2em rgba(0,0,0,0.2);
  transition: background-color .2s, filter .2s;
  &[data-emotion="1"] {
    background-color: #ec6f1c;
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(240, 213, 147, 0.95) 0%, rgba(243, 218, 126, 0) 66.67%);
  }
  &[data-emotion="2"] {
    background-color: color('blueGreen');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(44, 193, 204, 0.95) 0%, rgba(182, 251, 255, 0) 66.67%);
  }
  &[data-emotion="3"] {
    background-color: rgb(20, 121, 238);
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(31, 203, 233, 0.95) 0%, rgba(26, 115, 218, 0) 66.67%);
  }
  &[data-emotion="4"] {
    background-color: color('blue');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(212, 182, 236, 0.95) 0%, rgba(239, 226, 241, 0) 66.67%);
  }
  &[data-emotion="5"] {
    background-color: color('dark');
    background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(112, 154, 189, 0.95) 0%, rgba(40, 244, 255, 0.014) 66.67%);
  }
  &:hover {
    filter: brightness(1.1);
    // cursor: pointer;
  }
  &:active {
    transition: background-color .2s, filter .07s;
    filter: brightness(.95);
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
  p {
    font-weight: 700;
  }
  h3 {
    font-weight: 400;
    font-size: 2rem;
    @media #{$tablet} {
      font-size: 2.4rem;
    }
  }
}