@import '../../styles/base';

.AddressItem {
  border: 2px solid color('primary', .2);
  border-radius: .5em;
  &.primary {
    @include setPrimaryColor('green');
    border: 2px solid color('primary', .8);
  }
  &.landscape {
    display: flex;
  }
  &.selected {
    @include setPrimaryColor('green');
    border-color: color('primary');
    background-color: color('primary', .1);
    .AddressItemContent {
      background-color: transparent;
    }
  }
}
.AddressItemContent {
  flex: 1 1 auto;
  background-color: color('primary', .1);
  padding: .5em;
  @media #{$tablet} {
    padding: .62em 1em;
  }
  > * {
    + * {
      margin-top: .19em;
    }
  }
}
.AddressItemControls {
  display: flex;
  flex: 0 1 20rem;
  > * {
    flex: 1 1 33.33%;
    border-left: 1px solid color('primary', .2);
    .AddressItem.portrait & {
      &:first-child {
        border-left: 0;
      }
    }
  }
}

.AddressItemSelectedMarker {
  background-color: color('green');
  color: contrastColor('green');
  height: 100%;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}