@import '../../styles/base';

.SessionOrGroupTimeDisplay {
  display: flex;
  align-items: center;
  padding: .5em;
  background-color: color('background', .1);
  border-radius: .38em;
  border: 2px solid color('foreground', .075);
  .BaseButton {
		color: inherit;
	}
}

.SessionOrGroupTimeDisplayTime {
  display: block;
  font-size: 3.2rem;
  font-weight: 400;
}

.SessionOrGroupTimeDisplayDate {
  display: block;
  font-weight: 600;
  flex: 1 1 auto;
  padding: 0 .5em;
}

.SessionOrGroupTimeDisplayDateStartingTag {
  span {
    font-size: 1.4rem;
    color: color('orange');
    display: inline-block;
  }
}

.SessionOrGroupTimeDisplayCancelled {
  font-size: 1.4rem;
  color: color('red');
  text-shadow: 0 0.1em 0.3em contrastColor(red, .2);
}