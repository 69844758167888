@import '../../styles/base';

.AppPageHeader {
  // display: flex;
  // align-items: center;
  box-sizing: content-box;
  // background-color: color('background');
  // &:not(.transparent) {
  //   background-color: color('background');
    // [data-enable-blur-effect="true"] & {
    //   backdrop-filter: blur(1em);
    //   background-color: color('background', 0.75);
    // }
  // }
  &.spaceChildren {
    .AppPageHeaderSlot.default {
      > * {
        + * {
          margin-top: .5em;
        }
      }
    }
  }
  .accommodateTitlebarSpace & {
    position: sticky;
    top: var(--TitleBarHeight);
    @media #{$tablet} {
      top: 0;
    }
    @media print {
      top: 0;
    }
  }
  .Overlay & {
    position: sticky;
    top: 0;
    padding-top: env(safe-area-inset-top);
  }
  left: 0;
  z-index: 3;
  @media print {
    position: static;
  }
  // &.transparent {
  //   background-color: transparent;
  //   .AppPageHeaderInner {
  //     background-color: transparent;
  //   }
  // }
}
.AppPageHeaderInner {
  display: grid;
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: .5em size(md);
  .nogrid & {
    display: flex;
  }
  .Overlay & {
    padding: 1em size(md);
  }
  @media #{$tablet} {
    padding-top: size(md);
    padding-bottom: size(md);
    // padding-bottom: .75em;
  }
  grid-gap: .25em .5em;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'content end';
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  @media print {
    padding-left: 0;
    padding-right: 0;
  }
}

.AppPageHeaderInnerContent {
  grid-area: content;
  justify-self: start;
  display: flex;
  align-items: center;
  .nogrid & {
    flex: 1 1 auto;
  }
  > *:not(:empty) {
    + * {
      margin-left: .5em;
    }
  }
  &:only-child {
    grid-area: unset;
    grid-column-end: span 2;
    justify-self: stretch;
  }
}

.AppPageHeaderSlot {
  &.end {
    grid-area: end;
    align-self: center;
    justify-self: end;
    min-width: 0;
  }
  &.default {
    grid-column-end: span 2;
    min-width: 0;
  }
}

.AppPageHeaderSlotTopStartInner {
  flex: 1 1 auto;
}

.AppPageBeforeTitle {
  color: color('primary');
  font-weight: 700;
}

.AppPageTitle {
  margin: 0;
  line-height: 1.19;
  // color: color('primary');
  font-weight: 800;
  font-size: 1.8rem;
  @media #{$desktop} {
    font-size: 2.2rem;
  }
  @media #{$desktop-md} {
    font-size: 2.4rem;
  }
  .Overlay & {
    font-size: 2.2rem;
    @media #{$desktop} {
      font-size: 2.8rem;
    }
  }
  small {
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 62%;
    margin-bottom: .19em;
  }
  span.--block {
    display: block;
  }
}
.AppPageAfterTitle {
  color: color('foreground', .6);
  font-family: font(heading);
  font-weight: 500;
  font-size: 1.4rem;
}