@import '../../styles/base';

@keyframes thoughtCardHighlight {
  0% {
    box-shadow: 0 0 .5em .3em color('yellow', 0);
  }
  30% {
    box-shadow: 0 0 .5em .3em color('yellow');
  }
  100% {
    box-shadow: 0 0 0 0 color('yellow', 0);
  }
}
.ThoughtCard {
  position: relative;
  background-color: color('background');
  background-image: linear-gradient(175deg, color('primary', .15), color('primary', 0) 6.2rem);
  background-size: 100% 10rem;
  background-repeat: no-repeat;
  color: color('foreground');
  border-radius: var(--CardRadius);
  overflow: hidden;
  transform: translate3d(0,0,0);
  @extend %cardShadow;
  &.private {
    background-image: linear-gradient(175deg, color('primary', .38), color('primary', 0) 10rem);
    @include setBackgroundColor('ash');
    @include setForegroundColor('white');
    h4 {
      color: inherit;
      opacity: .38;
    }
  }
  &.hidden {
    border: 1px dashed color('foreground', .5);
    > * {
      opacity: .62;
      transition: opacity .1s;
    }
    &:hover {
      > * {
        opacity: 1;
      }
    }
  }
  &.deleted {
    filter: grayscale(1);
  }
  &.flagged {
    border: 2px solid color('red') !important;
  }
  &.safe {
    border: 2px solid color('green') !important;
  }
  &.unsafe {
    border: 2px solid color('red') !important;
  }
  &.highlight {
    animation: thoughtCardHighlight 2s;
  }
  &.menuOpen {
    box-shadow: 0 0 .2em .1em color('primary');
  }
  .EmotionIconIcon {
    border: 0;
    background-color: color('primary', .1);
    box-shadow: 0 .1em .2em color('primary', .19);
  }
  &[data-length="short"] {
    .ThoughtCardContentBody {
      font-size: 2.2rem;
    }
  }
  &[data-length="medium"] {
    .ThoughtCardContentBody {
      font-size: 1.8rem;
    }
  }
  &[data-length="long"] {
    .ThoughtCardContentBody {
      font-size: 1.6rem;
    }
  }
  h4 {
    color: color('primary');
  }
  &[data-emotion="1"] { @include setPrimaryColor('orange') }
  &[data-emotion="2"] { @include setPrimaryColor('blueGreen') }
  &[data-emotion="3"] { @include setPrimaryColor('skyblue') }
  &[data-emotion="4"] { @include setPrimaryColor('blue') }
  &[data-emotion="5"] { @include setPrimaryColor('steel') }
  .BodyCopyRenderer {
    overflow-wrap: break-word;
  }
  .UserCountryRenderer {
    display: inline;
  }
}
.ThoughtCardInner {
  display: grid;
  min-height: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto minmax(auto, 1fr) auto auto;
  grid-template-areas:
    'header    header'
    'content   content'
    'situation response'
    'footer    footer'
  ;
  grid-gap: .5em;
  .nogrid & {
    display: block;
    gap: 0;
  }
}
.ThoughtCardHeader {
  grid-area: header;
  padding: 1em 1em 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // flex-wrap: wrap;
  .EmotionIconIcon {
    display: inline-block;
    margin-bottom: .19em;
  }
  h3 {
    display: flex;
    align-items: center;
    font-weight: inherit;
    font-size: inherit;
    flex-wrap: wrap;
    flex: 1 1 auto;
    > * {
      margin-right: .38em;
    }
  }
  strong, span, time {
    display: inline;
  }
  .DateRenderer {
    display: inline-block;
    white-space: nowrap;
    margin-top: .19em;
    margin-bottom: .19em;
  }
}

.ThoughtCardEmotionName {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
}
.ThoughtCardDeletedTag {
  font-weight: 700;
  background-color: color('median', .2);
  border-radius: .19em;
  padding-left: .38em;
  padding-right: .38em;
  font-size: 1.2rem;
}
.ThoughtCardContent {
  grid-area: content;
  margin-top: .5em;
  margin-bottom: .5em;
  padding: 0 1em;
}
.ThoughtCardSituation {
  grid-area: situation;
  padding-left: 1em;
}
.ThoughtCardResponse {
  grid-area: response;
  padding-right: 1em;
  .nogrid & {
    padding-left: 1em;
    margin-top: .5em;
  }
}

.ThoughtCardOriginalCopy {
  opacity: .7;
  font-style: italic;
  font-size: 1.6rem;
}

.ThoughtCardFooter {
  grid-area: footer;
  padding: 1em;
  position: relative;
  position: sticky; 
  bottom: -1px;
  left: 0;
  transform: translate3d(0,0,0);
}
.ThoughtCardFooterInner {
  position: relative;
  display: flex;
  > * {
    flex: 1 1 auto;
  }
}
