@import '../../../../styles/base';

.ClientSupportGroupFinder {
  background-color: color('blueGreen', .1);
  background-image: linear-gradient(to bottom, color('blueGreen', .19), color('blueGreen', 0));
  .AppPageHeader {
    background-color: color('blueGreen');
    background-image: radial-gradient(100.09% 300% at 97% 130%, #8BDF7D 0%, #10A78C 62%);
    color: color('white');
    .AppPageTitle {
      @media #{$tablet} {
        font-size: 2.8rem;
        font-weight: 700;
      }
    }
    .AppPageAfterTitle {
      color: inherit;
      margin-top: .19em;
    }
  }
  &.blendIntoContext {
    background-color: transparent;
    background-image: none;
    height: auto;
    overflow: visible;
    .AppPageHeader {
      background-color: transparent;
      background-image: none;
      color: color('blueGreen');
      padding-left: 0;
    }
    .AppPageHeaderInner {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
    .ClientSupportGroupFinderFilterWrapper,
    .ClientSupportGroupFinderIntro {
      padding-left: 0;
      padding-right: 0;
    }
    .AppPageContent {
      height: auto;
      overflow: visible;
    }
  }
}
.ClientSupportGroupFinderIntro {
  padding: .5em size(md) 0 size(md);
}
.ClientSupportGroupFinderFilterWrapper {
  position: sticky;
  top: 0;
  padding: 1em size(md) .5em size(md);
  z-index: 1;
  .BaseSelector {
    border: 2px solid color('blueGreen', .2);
  }
  .BaseSelectorInlineOption {
    color: color('blueGreen');
    background-color: color('white', .9);
    border-color: color('blueGreen', .2);
  }
}