@import '../../../../styles/base';

.CounsellingApplicationActionsPanel {
  background-color: color('green', .19);
  border: 2px solid color('green', .2);
  border-radius: .5em;
  padding: 1em;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.CounsellingInvitationPendingNotice {
  .ClickToCopy {
    background-color: color('background', .5);
    border-radius: .5em;
    padding: 1em;
  }
}