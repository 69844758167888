@import '../../styles/base';

@keyframes GoalSheetWizardContentEnter {
	from {
		opacity: 0;
		transform: translateY(6em);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
.GoalSheetWizard {
	display: flex;
	align-items: stretch;
	height: 100%;
	color: white;
	position: relative;
	background-image: radial-gradient(864.78% 100% at 114.93% 119%, #B6B6FA 0%, #4E4BE0 100%);
	@include setPrimaryColor('yellow');
	.SwiperContainer {
		padding-bottom: 5em;
	}
	.SwiperSlide {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		&:first-of-type {
			.GoalSheetWizardStep {
				opacity: 0;
				animation: GoalSheetWizardContentEnter 1s .5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
			}
		}
	}
	.SwiperPagination {
		position: absolute;
		bottom: 0;
		left: 50%;
		padding: 1em 0 2em;
		margin-bottom: env(safe-area-inset-bottom);
		transform: translateX(-50%);
	}	
}

.GoalSheetWizardStep {
	text-align: center;
	padding: size(lg);
	max-width: 48em;
	margin-left: auto;
	margin-right: auto;
}
.GoalSheetWizardStepInner {
	> * {
		+ * {
			margin-top: 1.4rem;
		}
	}
	h1, h2 {
		font-weight: 500;
		font-size: 2.6rem;
		@media #{$tablet} {
			font-size: 3.2rem;
		}
	}
	h3 {
		font-size: 1.8rem;
		font-weight: 500;
		font-style: italic;
	}
	.BaseLabel {
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: .06em;
	}
	.BaseInputTextarea {
		&:hover {
			background-color: color('white', .1);
		}
		&:focus {
			background-color: color('white', .1);
			box-shadow: 0 0 .2em .1em color('yellow', .6);
		}
	}
}
.GoalSheetWizardScrollHint {
	text-transform: uppercase;
	letter-spacing: .06em;
	font-size: 85%;
	font-weight: 700;
	margin-top: 2em;
}

@keyframes GoalSheetWizardSuccessScreenEnter {
  from { opacity: 0; transform: scale(1.25) }
  to { opacity: 1; transform: scale(1) }
}

.GoalSheetWizardSuccess {
	@extend .GoalSheetWizardStep;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: radial-gradient(864.78% 100% at 114.93% 119%, #B6B6FA 0%, #4E4BE0 100%);
	z-index: 1;
	padding-bottom: 6em;
  animation: GoalSheetWizardSuccessScreenEnter .38s;
}

.GoalSheetWizardTryAgain {
	padding: 1em;
	font-weight: 700;
}