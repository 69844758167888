@import '../../styles/base';

@keyframes SplashScreenEnter {
	from {
		transform: scale(1.1);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes SplashScreenExit {
	from {
		transform: scale(1);
		opacity: 1;
	}
	to {
		transform: scale(1.1);
		opacity: 0;
	}
}
@keyframes SplashScreenExitLowPerf {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
.SplashScreen {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: color('background');
	[data-perf="high"] & {
		&.isExiting {
			animation: SplashScreenExit 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	[data-perf="low"] & {
		&.isExiting {
			animation: SplashScreenExitLowPerf .2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
	.--cypress & {
		animation-duration: 0s !important;
	}
}
.SplashScreenInner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: color('primary');
	color: white;
	.isEntering & {
		[data-perf="high"] & {
			animation: SplashScreenEnter 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		}
	}
}