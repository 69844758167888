@import '../../../../../styles/base';

.CounsellingApplicationStepTimeSelection {
  .ShadedBlockInner {
    background-color: color('white');
  }
  p {
    a {
      cursor: pointer;
      color: color('primary');
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.CounsellingApplicationStepTimeSelectionDisplay {
  background-color: color('white');
  border: color('primary, .19');
  padding: .62em 1em;
  border-radius: .38em;
  > * {
    + * {
      margin-top: .5rem;
    }
  }
  time {
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    color: color('primary');
  }
  p {
    font-weight: 700;
    opacity: .62;
    font-size: 85%;
  }
}
