@import '../../styles/base';

[data-name="OverlayAgeVerification"] {
  .Overlay,
  .OverlayInner {
    overflow: visible !important;
  }
}
.OverlayAgeVerification {
  overflow: visible !important;
  background-color: color('primary');
  color: contrastColor('primary');
  .AppPageHeader {
    text-align: center;
  }
  h3 {
    font-weight: 400;
    font-size: 1.8rem;
    color: color('primary');
    margin: 0;
  }
}