@import '../../styles/base';

.OverlaySurveySatisfaction {
  .Turn2MeLogo {
    color: color('primary');
  }
}
.OverlaySurveySatisfactionTitle {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  @media #{$tablet} {
    font-size: 2.8rem;
  }
}
.SurveyEditorSatisfaction {
  counter-reset: surveyQuestionList;
}
.SurveyEditorSatisfactionQuestion {
  padding: size(md);
  background-color: color('neonGreen', .1);
  background-image: linear-gradient(162deg, color('blueGreen', .19), color('blueGreen', 0));
  border-radius: .5em;
  counter-increment: surveyQuestionList;
  .BaseIcon {
    width: 1.8em;
    height: 1.8em;
    margin-bottom: .5em;
    display: none;
  }
  &:before {
    content: counter(surveyQuestionList);
    font-size: 1.38em;
    font-weight: 900;
    opacity: .38;
  }
  > p {
    font-weight: 500;
    line-height: 1.19;
    font-size: 1.1em;
    + * {
      margin-top: .5em;
    }
  }
  + div {
    margin-top: 1em;
  }
}
.SurveyEditorSatisfaction {
  .BaseSelectorInlineInner {
    background-color: color('background');
    text-transform: uppercase;
    font-size: 85%;
    border-radius: .5em;
    div {
      &:first-child {
        border-top-left-radius: .5em;
        border-bottom-left-radius: .5em;
        &.selected {
          background-color: color('red');
        }
      }
      &:nth-child(2) {
        &.selected {
          background-color: color('orange');
        }
      }
      &:nth-child(3) {
        &.selected {
          background-color: color('skyblue');
        }
      }
      &:nth-child(4) {
        border-top-right-radius: .5em;
        border-bottom-right-radius: .5em;
        &.selected {
          background-color: color('green');
        }
      }
      transition: .1s;
      &:active {
        transform: scale(.95);
      }
    }
    span {
      font-weight: 800;
      line-height: 1;
      padding-top: .75em;
      padding-bottom: .75em;
    }
  }
}
