@import '../../styles/base';

.SupportGroupIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  flex: 0 0 auto;
  background-color: color('primary');
  color: contrastColor('primary');
  border-radius: 50%;
	overflow: hidden;
  > * {
    display: block;
    &:not(.BaseIcon) {
      width: 100%;
      height: 100%;
    }
  }
  svg {
    display: block;
  }
}