@import '../../styles/base';

.CommentComposer {
  display: flex;
  background-color: color('primary', .1);
  background-image: linear-gradient(162deg, color('primary', .25), color('primary', 0));
  align-items: stretch;
  textarea.BaseInputTextarea,
  > .BaseButton--default.md {
    border-radius: 0;
  }
  .BaseInput {
    display: block;
    flex: 1 1 auto;
    textarea {
      border-bottom-left-radius: var(--CardRadius);
      padding-left: 1em;
      min-height: 5.3rem;
    }
  }
  .BaseButtonStaticLabel {
    position: relative;
    top: -.2em;
  }
}