* {
  box-sizing: border-box;
}

:root {
  @media screen {
    position: fixed;
    overflow: hidden;
  }
  width: 100vw;
  display: flex;
  justify-content: center;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  background-color: color('background');
  color: color('foreground');
  font-family: font(ui);
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --safeMarginLeft: #{size(md)};
  --safeMarginRight: #{size(md)};
  transition: background-color 1s, color 1s;
  min-height: var(--AppHeight);
  --TitleBarHeight: 5rem;
  --BannerRadius: .5em;
  --CardRadius: .75em;
  @media #{$tablet} {
    --CardRadius: 1em;
    --BannerRadius: .62em;
  }
  @media #{$desktop} {
    --BannerRadius: .75em;
    --CardRadius: 1.19em;
  }
  &.--cypress {
    transition: 0s;
  }
  .ModuleClient {
    @media #{$tablet} {
      --TitleBarHeight: 6.9rem;
    }
  }
  .ModuleAdmin {
    @media #{$tablet} {
      --TitleBarHeight: 0px;
    }
  }
  &[data-orientation="landscape"] {
    --safeMarginLeft: calc(.5em + env(safe-area-inset-left));
    --safeMarginRight: calc(.5em + env(safe-area-inset-right));
  }
  &.no-scroll {
    overflow: hidden;
    width: var(--AppWidth);
    height: var(--AppHeight);
  }
  &.no-select {
    user-select: none;
  }
}

body {
  font-size: 1.6rem;
  line-height: 1.19;
  color: color('foreground');
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

code {
  font-family: font(monospace);
  font-feature-settings: 'zero' 1;
  font-size: 90%;
  font-weight: 500;
  word-wrap: break-word;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: font(heading);
  margin: 0;
  font-weight: 700;
}
p {
  margin: 0;
}

::selection {
  background-color: var(--SelectionColor);
}

:focus {
  outline: none;
}

/* Styling React Error Screen */
body {
  > iframe:last-child {
    background-color: rgb(53,53,53);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.EmbeddableChunkLoadErrorNotice {
  margin: 4.8rem size(lg);
  strong {
    font-size: 1.8rem;
  }
}

.link {
  @extend %underlinedLinks;
}

.ion-color-success {
  background-color: color('positive');
  color: contrastColor('positive');
}
.ion-color-danger {
  background-color: color('alert');
  color: contrastColor('alert');
}

@keyframes contentEnter {
  from {
    transform: translateY(10rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.vbgm-privacy-settings-panel {
  --vbgm-size-md: 1em;
  @media #{$tablet} {
    --vbgm-size-md: 1.38em;
  }
  --vbgm-size-lg: 1em;
  @media #{$tablet} {
    --vbgm-size-md: 1.62em;
  }
}

.vbgm-privacy-settings-panel-section {
  @media #{$only-phone} {
    padding: 0;
    .popup {
      border-radius: 0;
    }
  }
}

.vbgm-privacy-settings-panel-footer-after-save-button-content {
  h3 {
    font-size: inherit;
    margin-top: 1em;
    margin-bottom: .5em;
    text-transform: uppercase;
    font-weight: 800;
  }
}

.__AppChildInstanceContainer {
  position: fixed;
  background-color: color('deepPurple');
  color: color('white');
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 .5em 4em rgba(0,0,0,0.38);
  header {
    padding: .38em .5em;
    user-select: none;
    display: flex;
    align-items: center;
    &:active {
      + iframe {
        pointer-events: none;
      }
    }
  }
  h2 {
    font-size: 1.4rem;
    flex: 1 1 auto;
  }
  iframe {
    display: block;
  }
}
.__AppChildInstanceCloseButton {
  display: block;
  border-radius: 50%;
  background-color: color('red');
  border: none;
  padding: 0;
  width: 12px;
  height: 12px;
  margin-right: .5em;
  &:hover {
    filter: brightness(1.38);
  }
  &:active {
    filter: brightness(.9);
  }
}

.grecaptcha-badge { visibility: hidden; }

.GeneralSurveyIndexTable {
  td { // assuming that cells are represented by the 'td' tag
    text-transform: capitalize;
  }
}