@import '../../styles/base';

.BlogPostCard {
  transform: translate3d(0,0,0);
  .UICardSideA {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate3d(0,0,0);
    min-height: 15em;
    @media #{$tablet} {
      min-height: 20em;
    }
  }
  .UICardSideAInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: inherit;
    transform: translate3d(0,0,0);
  }
}

.BlogPostCardTopTags {
  display: flex;
}

.BlogPostCardTag {
  display: flex;
  align-items: center;
  color: color('white');
  font-weight: 700;
  padding: .5em .75em;
  background-color: color('skyblue', .9);
  &[data-type="Podcast"] {
    background-color: color('magenta', .9);
  }
  [data-enable-blur-effect="true"] & {
    background-color: color('skyblue');
    &[data-type="Podcast"] {
      background-color: color('magenta');
      background-image: linear-gradient(133deg, color('magenta'), color('magenta', 0.19));
    }
  }
  border-bottom-right-radius: .62em;
  > * {
    + * {
      margin-left: .38em;
    }
  }
}

.BlogPostCardHeader {
  flex: 0 0 auto;
  color: color('white');
  font-weight: 700;
  padding: 1em;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: -2em;
    background-image: linear-gradient(to top, color('black') 0%, color('black', .4) 60%, color('black', 0) 100%);
  }
  @media #{$tablet} {
    padding: 1.5em;
  }
  text-shadow: 0 .1em .3em color('black',.622);
}

.BlogPostCardHeaderInner {
  position: relative;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.BlogPostCardTagsList {
  text-transform: uppercase;
  font-size: 85%;
}

.BlogPostCardTitle {
  max-width: 16.2em;
  @media #{$tablet} {
    font-size: 2.6rem;
  }
}

.BlogPostCardAfterTitle {
  font-size: 85%;
  opacity: .75;
}
