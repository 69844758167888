@import '../../styles/base';

.CommandList {
  padding: 0;
  user-select: none;
  &.CommandListColumn {
    .CommandListInner {
      .CommandListItem {
        + .CommandListItem {
          border-top: 1px solid color('primary', .19);
        }
      }
    }
    .BaseIcon {
      color: color('primary');
    }
    .CommandListItem {
      padding: .75em;
    }
    &.fullWidth {
      .CommandListItem {
        padding: 1.5em size(md);
      }
    }
    &.simpleList {
      .CommandListInner {
        display: inline-block;
        background-color: color('primary', .1);
        padding-top: .19em;
        padding-bottom: .19em;
        border-radius: .5em;
      }
      .CommandListItem {
        padding: .5em 1em;
      }
    }
  }
  &.CommandListRow {
    .CommandListInner {
      display: flex;
      justify-content: space-between;
      background-color: color('primary', .1);
      padding: .19em;
      border-radius: .5em;
      > * {
        display: flex;
        align-items: center;
      }
    }
    .CommandListItem {
      padding: .5em;
      font-weight: 600;
      color: color('primary');
    }
    .BaseLabel {
      margin-bottom: 0;
    }
  }
  .BaseSelectorInlineInner {
    font-weight: 600;
  }
  &.withBackground {
    background-color: color('primary', .1);
    border-radius: .38em;
    + * {
      margin-top: .25em;
    }
  }
}

.CommandListStartSlot {
  justify-content: flex-start;
}
.CommandListEndSlot {
  justify-content: flex-end;
}

.CommandListEmptyNotice {
  font-style: italic;
  opacity: .75;
}