@import '../../styles/base';

.SupportGroupCalendarEventRenderer {
  h3 {
    font-weight: 600;
  }
  &.inThePast {
    opacity: .62;
    &:hover {
      opacity: 1;
    }
  }
  &.joined {
    border-left: 2px solid color('green');
  }
  &.isLive {
    opacity: 1;
  }
  &.archived {
    background-color: color('median', .2);
  }
  .SupportGroupJoinedCountDisplay {
    font-size: 85%;
    opacity: .75;
    text-transform: uppercase;
    font-weight: 700;
    &.full {
      color: color('attention');
    }
  }
  .UsernameRendererLabel {
    opacity: .75;
  }
  .ColorTagCompany {
    font-size: 80%;
  }
}
.SupportGroupCalendarEventRendererEndedAt {
  display: block;
  font-size: 85%;
  opacity: .75;
  text-transform: uppercase;
  font-weight: 700;
  color: color('positive');
}

.SupportGroupCalendarEventRendererKeyWorker {
  display: block;
  margin-top: .5em;
  font-size: 1.2rem;
  color: color('orange', 1, -.1);
}