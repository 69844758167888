%label {
  font-weight: 600;
  display: block;
  font-family: font(heading);
  font-size: 1.4rem;
  margin-bottom: .38em;
  color: inherit;
}

@mixin colorCodedStates {
  &.state-neutral { @include setPrimaryColor('neutral') }
  &.state-success, &.state-positive { @include setPrimaryColor('positive') }
  &.state-success { @include setPrimaryColor('positive') }
  &.state-attention, &.state-warning { @include setPrimaryColor('attention') }
  &.state-alert, &.state-error { @include setPrimaryColor('alert') }
  &.state-disabled {
    filter: grayscale(.7);
    opacity: 0.6;
    pointer-events: none;
  }
  &[class*=state-] {
    --InputBorderColor: #{color('primary')};
  }
}
%colorCodedStates {
  @include colorCodedStates();
}

%defaultInput {
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  padding: .38em;
  height: 2.5em;
  color: inherit;
  font-weight: inherit;
  background-color: color('primary',.12);
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: .3em;
  text-align: inherit;
  @media #{$only-phone} {
    font-size: 16px;
  }
  &[type="date"] {
    text-align: left;
  }
  .inverted & {
    border-color: color('white', .1);
    background-color: color('white', .1);
    &:hover {
      background-color: color('white', .19);
      border-color: color('white', .15);
    }
    &:focus {
      background-color: color('white', .19);
      border-color: color('white', .38);
    }
  }
  .inverted[class*="state-"] & {
    border-color: color('primary', .75);
    background-color: color('primary', .19);
    &:hover {
      background-color: color('primary', .38);
    }
    &:focus {
      background-color: color('primary', .62);
    }
  }
  @media #{$desktop} {
    height: 2.5em;
  }
  .mouse & {
    &:hover {
      background-color: color('primary', .175);
    }
    &.disabled,
    &[disabled] {
      &:hover {
        background-color: color('primary',.1);
      }
    }
  }
  &.disabled,
  &[disabled] {
    color: color('foreground', .38);
    filter: grayscale(.9);
    opacity: .8;
    // &.inverted {
      
    // }
  }
  &:focus {
    outline: none;
    border-color: color('primary', .5);
    background-color: color('primary', .25);
  }
  .invalid &,
  .inverted.invalid & {
    // color: color('alert');
    border-color: color('alert');
    input {
      background-color: color('alert',.15);
    }
  }
  .disabled &,
  [disabled] &,
  .invalid[disabled] & {
    background-color: color('foreground',.05);
    color: color('foreground',.6);
    cursor: not-allowed;
  }
  .inverted.disabled &,
  .inverted[disabled] &,
  .inverted.invalid[disabled] & {
    background-color: color('white',.05);
    color: color('white',.6);
    backdrop-filter: none;
  }
  &::placeholder {
    font-style: italic;
    color: inherit;
    opacity: .5;
  }
}


%paddable {
  &.padded {
    padding-left: size(md);
    padding-right: size(md);
  }
  &.padded-all {
    padding-top: size(md);
    padding-bottom: size(md);
    + &.padded-all {
      padding-top: 0;
    }
  }
}

%underlinedLinks {
  text-decoration: none;
  background-image: linear-gradient(to top, currentColor, currentColor);
  background-size: 100% .62px;
  background-position: left 0 bottom .09em;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-size: 100% 1px;
  }
}

%asDarkTheme {
  @include setBackgroundColor('dark');
  @include setForegroundColor('white');
}
%asPrimaryTheme {
  @include setBackgroundColor('primary');
  @include setForegroundColor('white');
}

%hideScrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  // IE 10+
  ::-webkit-scrollbar,
  &::-webkit-scrollbar { 
    display: none;
    background: transparent;
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
  }
  ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    display: none;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb { 
    display: none;
    background: transparent;
  }
}


%cardShadow {
  @media #{$tablet} {
    box-shadow: 0 .3em .6em rgba(0,0,0,0.052);
    .--cypress & {
      box-shadow: none;
    }
  }
}