@import '../../../../styles/base';

.PageInvitationMessageWrapper {
  background-color: color('blueGreen', .1);
  background-image: linear-gradient(133deg, color('blueGreen', .1), color('blueGreen', 0));
  margin-top: 1em;
  border-radius: 1em;
  padding: size(md);
  blockquote {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: color('blueGreen');
    + * {
      margin-top: 1em;
    }
  }
  .UIBlock {
    background-color: color('blueGreen', .1);
  }
  .ClickToCopy {
    background-color: color('blueGreen', .1);
    padding: 1em;
    border-radius: .5em;
    @include setPrimaryColor('blueGreen');
  }
}
.PageInvitationError {
  margin-top: 1em;
  h3 {
    margin-bottom: .5em;
  }
}