@import '../../styles/base';

.VoipLiveCallToast {
  display: block;
  text-decoration: none;
  position: fixed;
  @media #{$only-phone} {
    top: 0;
    left: 0;
    right: 0;
    background-color: color('green');
    font-size: 1.2rem;
    .VoipCallEntry {
      display: block;
      color: color('white');
      min-height: unset;
      padding-top: calc(.38em + env(safe-area-inset-top));
      padding-bottom: .38em;
    }
    .VoipCallEntryHeader {
      > * {
        display: inline-block;
        + * {
          margin-left: .5em;
        }
      }
    }
    .VoipCallEntryControls {
      display: none;
    }
    .VoipCallEntryMeta {
      float: right;
    }
  }
  @media #{$tablet} {
    top: 1.5em;
    right: 1.5em;
    background-color: color('background');
    cursor: pointer;
    transition: .1s;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(1);
    }
    [data-enable-blur-effect="true"] & {
      background-color: transparent;
      background-image: linear-gradient(162deg, color('background', 1), color('background', 0) 16.2em);
      backdrop-filter: blur(2em);
    }
    border-radius: .5em;
    min-width: 30rem;
    .VoipCallEntry {
      box-shadow: 0 .5em 2em rgba(0,0,0,0.1);
      border-radius: .5em;
      border: 2px solid color('primary', .25);
      padding: .75em;
    }
  }
}