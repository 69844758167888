@import '../../styles/base';

.BaseCalendarWeekHeader {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  position: sticky;
  top: 0;
  th,
  .BaseCalendarWeekHeaderCell {
    @media #{$only-phone} {
      padding-bottom: 0;
      font-size: 87.5%;
      span {
        opacity: .75;
      }
    }
    @media #{$tablet} {
      border-bottom: var(--BorderMinor);
    }
    &[data-day-of-week="0"],
    &[data-day-of-week="6"] {
      color: color('blueGreen');
    }
  }
}
.BaseCalendarWeekHeaderInner {
  .shouldDisplayAsTimeline & {
    display: flex;
    > * {
      flex: 0 0 #{(100 / 7) * 1%};
      + * {
        border-left: var(--BorderMinor);
      }
    }
  }
}