@mixin colorCodedStateColors {
  &.state-neutral { @include setPrimaryColor('neutral') }
  &.state-positive, &.state-success { @include setPrimaryColor('positive') }
  &.state-attention { @include setPrimaryColor('attention') }
  &.state-alert, &.state-error { @include setPrimaryColor('alert') }
  &.state-disabled {
    filter: grayscale(.7);
    opacity: 0.6;
    pointer-events: none;
  }
}

@mixin clampLines($numberOfLines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clientSubmoduleBackground {
  background-color: #e3f8fd;
  @media print {
    background-color: color('background');
  }
}