@import '../../styles/base';


.AppPageContent {
  display: block;
  height: calc(var(--AppPageHeight) - var(--AppPageHeaderHeight) - var(--TitleBarHeight));
  .OverlayView[data-appearance="card"] & {
    max-height: calc(var(--OverlayHeight) - var(--AppPageHeaderHeight));
  }
  .OverlayView[data-appearance="sheet"] & {
    height: calc(var(--OverlayHeight) - var(--AppPageHeaderHeight));
  }
  @media #{$tablet} {
    height: calc(var(--AppPageHeight) - var(--AppPageHeaderHeight));
  }
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: initial;
  }
  .TwoColumnView & {
    @media #{$only-phone} {
      padding-bottom: var(--AppNavigationHeight);
    }
  }

  font-size: 1.6rem;
  flex: 1 1 auto;
  @media print {
    overflow: auto;
    height: auto !important;
  }
  h1 {
    line-height: 1.09;
    font-weight: 300;
  }
  h2 {
    font-size: 138%;
    color: color('primary');
  }
  &.padSections {
    .UIBlock {
      padding: .5em size(md);
    }
    .UIBlock,
    .AppPageContentFooter {
      + * {
        margin-top: -1px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .AppPageContentFooter {
      border-top: none;
      padding: 1em size(md);
    }
  }
  .UIBlock {
    &.sticky {
      background-color: color('background');
    }
    &:last-child {
      .admin-panel.messenger-panel-open & {
        padding-bottom: 3em;
      }
    }
  }
  .UIBlock, .AppPageContentFooter {
    &:last-child {
      @media #{$only-phone} {
        padding-bottom: calc(env(safe-area-inset-bottom) + #{size(md)});
      }
    }
  }
}