@import '../../../../styles/base';

.AuthForm {
  position: relative;
  color: color('foreground');
  padding: 1.62em;
  padding-bottom: 3em;
  margin-bottom: 2em;
  border-radius: 1.5em 0 0 1.5em;
  background-color: color('white');
  box-sizing: border-box;
  @media #{$only-phone} {
    margin-right: size(lg, -1);
  }
  @media #{$tablet-md} {
    border-radius: 1.5em;
  }
  [data-perf="high"] & {
    background-color: color('white', .1);
    background-image: linear-gradient(133deg, color('white', .95), color('white', .6));
    box-shadow: 0 .5em 1em rgba(0,0,0,0.1);
  }
  text-shadow: none;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.BaseButton.AuthFormSubmit {
  position: absolute;
  right: size(lg);
  bottom: 0;
  transform: translateY(50%);
  box-shadow: 0 1em 2em rgba(0,0,0,0.1);
  transition: .1s;
  &.isLoading,
  &.disabled {
    opacity: 1;
  }
  &:active {
    transform: scale(.97) translateY(51%);
  }
  &.disabled:not(.isLoading) {
    filter: grayscale(.3);
    color: contrastColor('primary', .5);
  }
}