@import '../../styles/base';

.VoipCallEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  background-color: color('primary', .2);
  padding: .5em;
  min-height: 3em;
  @include setPrimaryColor('orange');
  &[data-status="open"] {
    @include setPrimaryColor('green');
  }
  &[data-status="closed"] {
    @include setPrimaryColor('median');
  }
  h3 {
    font-size: inherit;
  }
}
.VoipCallEntryControls {
  padding-left: .5em;
  display: flex;
  > * {
    flex: 0 0 auto;
    + * {
      margin-left: .5em;
    }
  }
  .BaseIcon {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.VoipCallEntryPhoneButton {
  background-color: color('primary');
  border-radius: 50%;
  max-width: unset;
  color: contrastColor('primary');
  width: 3em;
  height: 3em;
  .BaseIcon {
    font-size: 1.6rem;
  }
  &:hover {
    background-color: color('primary');
    color: contrastColor('primary');
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.9);
  }
}
.VoipCallEntryDeleteButton {
  background-color: color('red', .1);
  border-radius: 50%;
  max-width: unset;
  color: color('red');
  width: 3em;
  height: 3em;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.9);
  }
}