@import '../../styles/base';

.ProfileCard {
  background-color: color('background');
  border-radius: .5em;
  overflow: hidden;
  cursor: pointer;
  .ProfileCardInner {
    &:hover {
      background-color: color('primary', .05);
      box-shadow: 0 0 1px 2px color('primary', .5);
    }
    &:active {
      background-color: color('primary', .1);
    }
  }
}

.ProfileCardInner {
  .--narrow & {
    padding: .5em;
  }
  .--spacious & {
    display: flex;
    align-items: flex-start; 
    padding: 1em;
  }
  .UserIcon {
    flex: 0 0 auto;
    + .ProfileCardLabel {
      margin-left: 1em;
      overflow: hidden;
      max-width: 100%;
    }
  }
  .UserComputedRoleList {
    margin-top: .5em;
    display: inline;
  }
}

.ProfileCardUsername {
  font-size: 1.6rem;
  font-weight: 800;
  .--narrow & {
    font-size: 1.4rem;
  }
}

.ProfileCardFullname {
  font-weight: 500;
  .--narrow & {
    font-size: 1.4rem;
  }
  @include clampLines(1);
}

.ProfileCardNonAdultAccountTypeTag,
.ProfileCardMemberSince {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: .038em;
  margin-top: .38rem;
  margin-bottom: .38rem;
  .ColorTag {
    text-transform: none;
    letter-spacing: 0;
  }
}

.ProfileCardMemberSince {
  opacity: .5;
}

.ProfileCardRoleDisplay {
  margin-top: .5em;
}
