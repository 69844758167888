@import '../../styles/base';

.ShadedBlock {
  background-color: color('background', .7);
  border-radius: var(--BannerRadius);
  &.spaceChildren {
    .ShadedBlockInner {
      > * {
        + * {
          margin-top: .5em;
        }
      }
    }
  }
  h1, h2, h3, h4 {
    color: color('primary');
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.6rem;
  }
  .BaseHeader {
    .BaseHeading {
      font-size: 1.8rem;
    }
  }
  &.inverted {
    background-color: color('white', .1);
    color: color('white');
    h1, h2, h3, h4 {
      color: inherit;
    }
  }
  h1, h2, h3, h4, h5, h6, p {
    a {
      font-weight: 700;
      @extend %underlinedLinks;
    }
  }
  code {
    word-break: break-word;
    background-color: transparent;
    padding: 0;
    color: inherit;
  }
  &.intenseColor {
    border: 2px solid color('primary');
    background-color: color('primary', .9);
    color: contrastColor('primary');
    h1, h2, h3, h4 {
      color: inherit;
    }
  }
  &.interactable {
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(.95);
    }
  }
}

.ShadedBlockInner {
  background-color: color('primary', .1);
  padding: 1em;
  border: 2px solid color('primary', .2);
  border-radius: inherit;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
}