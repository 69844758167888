@import '../../styles/base';

.EmailCounsellingSession {
  + .EmailCounsellingSession {
    margin-top: .25em;
    .EmailCounsellingSessionHeader {
      border-top-color: color('primary', .19);
    }
  }
  .CounsellingSessionPriceSetterForm {
    .ShadedBlock {
      background-color: transparent;
    }
    .ShadedBlockInner {
      background-color: transparent;
      padding: 0;
    }
  }
}

.EmailCounsellingSessionHeader {
  display: flex;
  align-items: center;
  border-top: 2px solid transparent;
  position: sticky; 
  top: 0;
  background-color: color('background');
  background-image: linear-gradient(to bottom, color('primary', .2), color('primary', .2));
  cursor: pointer;
  padding: .75em;
  z-index: 2;
  &:hover {
    background-image: linear-gradient(to bottom, color('primary', .25), color('primary', .25));
  }
  .open & {
    border-bottom: 1px solid color('primary',.19);
  }
}

.EmailCounsellingSessionHeaderInner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  h3 {
    font-size: inherit;
    color: color('primary');
  }
}

.EmailCounsellingSessionHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5em;
  background-color: color('primary',.19);
  color: color('primary');
  @media print {
    display: none;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
  + * {
    margin-left: .5em;
  }
  .BaseIcon {
    display: block;
    font-size: 1.2rem;
  }
}

.EmailCounsellingSessionContent {
  background-color: color('primary', .1);
  padding: 1em;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}
.CounsellingEmailList {
  .CounsellingEmail {
    + * {
      margin-top: .5em;
    }
  }
}
.CounsellingEmailListEmptyNotice {
  opacity: .62;
  padding-top: .5em;
  padding-bottom: .5em;
}

.EmailCounsellingManagerCreateSessionButton {
  margin-top: .5em;
  display: flex;
  width: 100%;
}

.EmailCounsellingSessionDetails {
  &:empty {
    display: none;
  }
}
.EmailCounsellingSessionHeading {
  color: color('primary');
  font-size: 1.4rem;
  margin-bottom: .5em;
}


.EmailCounsellingSessionComposerSection {
  // background-color: color('primary', .1);
  padding-top: 1em;
  border-top: 1px dashed color('primary', .38);
}

.EmailCounsellingSessionComposerHeading {
  color: color('primary');
  margin-bottom: .5em;
  font-size: 1.4rem;
}