@import '../../../../styles/base';

.InvoiceManager {
  .BaseHeading {
    color: color('primary');
  }
  .BaseSeparator {
    margin-top: size(md);
    margin-bottom: size(md);
    border-top: 2px solid color('primary', .1);
  }
}

.InvoiceManagerHeader {
  @media #{$desktop-md} {
    display: flex;
  }
  background-color: color('primary', .1);
  border-radius: var(--BannerRadius);
  padding: 1em;
  [data-status="Rejected"] & {
    background-color: color('red', .1);
  }
  .InfoDisplayList {
    padding-right: 1em;
    flex: 1 1 auto;
    @media #{$upto-desktop-md} {
      + * {
        margin-top: .5em;
      }
    }
  }
}

.InvoiceItemTableEntryTitle {
  cursor: pointer;
  &:hover {
    background-color: color('primary', .1);
  }
}
.InvoiceItemTableEntryAmountEditor {
  display: flex;
  align-items: baseline;
  padding-left: .62em;
}