@import '../../styles/base';

.BrowserSeverelyOutdatedNotice {
  appearance: none;
  background-color: color('red');
  color: color('white');
  position: fixed;
  padding: .38em 1em;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
}