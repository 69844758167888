:root {

  --SelectionColor: #{color('secondary', .5)};
  --BorderColor: #{color('primary',.1)};
  --BorderRadiusSmall: 0;
  --BorderRadiusMedium: 0;
  --BorderRadiusLarge: 1.62em;

  --BorderMajorWidth: 2px;
  --BorderMajor: var(--BorderMajorWidth) solid var(--BorderColor);
  --BorderMinorWidth: 1px;
  --BorderMinor: var(--BorderMinorWidth) solid var(--BorderColor);

  --InputBorderColor: #{color('primary',.19)};
  --InputBorderWidth: var(--BorderMinorWidth);
  --InputBorderRadius: 0;

  --ButtonBorderDefault: 2px solid #{color('primary',.9, -.1)};
  --ButtonBorderRadius: 0;
  --ButtonTextTransform: none;

}