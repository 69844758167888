@import '../../styles/base';

.TabGroup {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-bottom: 1px solid color('primary', .38);
  .--cypress & {
    scroll-behavior: unset;
  }
  > * {
    vertical-align: bottom;
  }
}