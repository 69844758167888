@import '../../styles/base';

.BaseSelector {
  @extend %colorCodedStates;
  &.secondary {
    @include setPrimaryColor('secondary');
  }
  &.disabled,
  &.readonly {
    pointer-events: none;
  }
}

.BaseSelectorInline {
  display: block;
  overflow: hidden;
  border-radius: .3em;
}
.BaseSelectorInlineInner {
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: .3em;
  user-select: none;
  border: none;
  overflow: auto;
  white-space: nowrap;
  background-color: color('primary',.19);
  margin: -1px;
  @extend %hideScrollbars;
  .BaseSelector.disabled & {
    background-color: color('foreground',.1);
  }
  @media #{$desktop} {
    height: 2.5em;
  }
  div {
    flex: 1 1 auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    cursor: pointer;
    line-height: 1.19;
    .BaseIcon {
      + * {
        margin-left: .38em;
      }
    }
    border: .5px solid color('background', .25);
    &.selected {
      background-color: color('primary');
      border-color: color('background',.5);
      color: contrastColor('primary');
    }
    .BaseSelector.disabled & {
      color: color('foreground', .62);
      &.selected {
        background-color: color('primary');
        color: contrastColor('primary', .62);
      }
    }
  }
}

.BaseSelectorSystem {
  .BaseSelectorInner {
    user-select: none;
    position: relative;
    select {
      display: block;
      padding-right: 2em;
    }
  }
  .BaseIcon {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    pointer-events: none;
    opacity: .75;
  }
}
.BaseSelector.disabled {
  .BaseIcon {
    opacity: .38;
  }
}
.BaseSelectorSelect {
  @extend %defaultInput;
  appearance: none;
  width: 100%;
  .inverted & {
    border-color: color('white', .1);
    background-color: color('white', .1);
    &:hover {
      background-color: color('white', .15);
    }
  }
  .inverted[class*="state-"] & {
    border-color: color('primary', .75);
    background-color: color('primary', .19);
    &:hover {
      background-color: color('primary', .38);
    }
  }
}

.BaseSelectorCheckboxGroup {
  &.checkboxGroupDirectionRow {
    margin-left: -.38em;
    margin-right: -.38em;
    .BaseSelectorInner {
      display: flex;
      flex-wrap: wrap;
    }
    .BaseCheckbox {
      margin-right: .5em;
    }
    .BaseToggleInner {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.BaseSelectInfoAfterInputField {
  margin-top: .5em;
  color: color('foreground', .62);
  font-style: italic;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
}