@import '../../styles/base';

strong.ColorTag {
  display: inline-block;
  padding: .19em .38em;
  border-radius: .38em;
  background-color: color('primary');
  color: contrastColor('primary');
  font-weight:800;
  font-size: 1.2rem;
  // text-transform: uppercase;
  margin: 1px;
  -webkit-print-color-adjust: exact;
  text-align: center;
  user-select: none;
  p & {
    background-color: color('primary');
    color: contrastColor('primary');
  }
  &[data-appearance="translucent"] {
    background-color: color('primary', .2);
    color: color('primary');
    font-weight: 800;
    p & {
      background-color: color('primary', .2);
      color: color('primary');
    }
  }
  &.nowrap {
    white-space: nowrap;
  }
  &.breakWord {
    word-break: break-word;
  }
  &.capitalize {
    text-transform: capitalize;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}