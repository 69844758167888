@import '../../styles/base';

.EmotionSelector {
  display: flex;
  justify-content: space-between;
  border-radius: 10rem;
  transition: background-color .1s;
  padding: .5em;
  &.showTextLabel {
    .EmotionSelectorInner {
      > * {
        + * {
          @media #{$only-phone} {
            margin-left: .75em;
          }
          @media #{$tablet} {
            margin-left: 1.38em;
          }
        }
      }
    }
  }
  &.interactable {
    .EmotionSelectorInner {
      > * {
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
      // &:not(:hover) {
      //   animation: promptEmotionIcons #{5 * .62 + 5 * 1.5 + 2}s ease-in 2s infinite;
      //   > * {
      //     @for $i from 1 through 5 {
      //       &:nth-child(#{$i}) {
      //         animation-name: promptEmotionIcons;
      //         animation-delay: #{$i * .62 + $i * 1.5 + 2}s;
      //       }
      //     }
      //   }
      // }
    }
  }
}

.EmotionSelectorInner {
  margin-left: auto;
  margin-right: auto;
  > * {
    + * {
      flex: 0 0 auto;
      margin-left: .5em;
    }
  }
}

// @keyframes promptEmotionIcons {
//   0% {
//   }
//   80% {
//     transform: scale(1.15);
//   }
//   100% {
//   }
// }