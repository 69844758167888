@import './styles/base';

.AccessScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  .BaseInput {
    width: 100%;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
  .Turn2MeLogo {
    color: color('primary');
  }
}

.AccessScreenInner {
  padding: 3em 1.5em;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}