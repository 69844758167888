@import '../../styles/base';

.RouterStack {
  position: relative;
  @media screen {
    width: var(--AppWidth);
    height: var(--AppHeight);
    @media #{$tablet} {
      width: 100%;
    }
    max-width: 100%;
    min-width: 100%;
    &.fillHeight {
      height: 100%;
      > * {
        height: 100%;
        > * {
          height: 100%;
        }
      }
    }
  }
}
.RouterStackLayerContainer {
  @media screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: color('background');
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
  .--cypress & {
    scroll-behavior: unset;
  }
  &.shouldRender {
    z-index: 1;
  }
  &.hidden {
    display: none;
  }
}

.RouterStackLayer {
  min-height: 100%;
}