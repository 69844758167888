@import '../../styles/base';

.OverlayTimeframeQuickPicker {
	transform: translateZ(0);
	text-align: center;
}

[data-name="OverlayTimeframeQuickPicker"] {
	.Overlay,
	.OverlayInner {
		overflow: visible;
	}
}

.OverlayTimeframeQuickPickerHeader {
	padding: size(md) size(md) 0 size(md);
}

.OverlayTimeframeQuickPickerTitle {
	font-size: 1.8rem;
	font-weight: 800;
	@media #{$tablet} {
		font-size: 2rem;
	}
	@media #{$desktop} {
		font-size: 2.4rem;
	}
}

.OverlayTimeframeQuickPickerInner {
	padding: size(md);
	.DateRangePicker {
		display: block;
		text-align: center;
		color: inherit;
		.DateRangePickerInput {
			display: block;
		}
		.DateInput {
			background-color: transparent;
		}
		.DateInput_input {
			text-align: center;
			background-color: transparent;
		}
		.DateInput_input__focused {
			border-bottom-color: color('primary');
		}
		.CalendarDay {
			border-width: 0;
			border-color: transparent;
		}
		.CalendarDay__selected {
			background-color: color('primary', .2);
				color: inherit;
			&:hover {
				background-color: color('primary', .2);
				color: inherit;
				filter: brightness(1.1);
			}
			&:active {
				background-color: color('primary', .2);
				color: inherit;
				filter: brightness(.9);
			}	
		}
		.CalendarDay__today {
			position: relative;
			&:before {
				content: '';
				width: 2em;
				height: 2em;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: color('red', .2);
				border-radius: 50%;
				pointer-events: none;
			}
		}
		.CalendarDay__selected_span {
			background-color: color('primary', .2);
			color: inherit;
			&:hover {
				background-color: color('primary', .2);
				color: inherit;
				filter: brightness(1.1);
			}
			&:active {
				background-color: color('primary', .2);
				color: inherit;
				filter: brightness(.9);
			}
		}
		.DayPickerKeyboardShortcuts_buttonReset {
			display: none;
		}
		.DayPickerNavigation_button {
			border-color: transparent;
			background-color: color('primary', .1);
			svg {
				fill: color('primary');
			}
			&:hover {
				background-color: color('primary', .15);
			}
			&:active {
				background-color: color('primary', .2);
			}
		}
		.CalendarMonth_caption {
			color: color('primary');
		}
	}
}

.OverlayTimeframeQuickPickerButtonSet {
	margin-top: 1em;
	h3 {
		color: color('primary');
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: 700;
		margin-bottom: .5em;
	}
}

.OverlayTimeframeQuickPickerButtonSetInner {
	margin-left: -.19em;
	margin-right: -.19em;
	> * {
		margin: .19em;
	}
}

.OverlayTimeframeQuickPickerFooter {
	display: flex;
	overflow: hidden;
	transform: translateZ(0);
	border-bottom-left-radius: .75em;
	border-bottom-right-radius: .75em;
	.BaseButton {
		flex: 0 0 50%;
		border-radius: 0;
		@media #{$only-phone} {
			padding-top: .5em;
			padding-bottom: .5em;
		}
		@media #{$tablet} {
			font-size: 1.6rem;
			padding: 1em;
		}
	}
}