@import '../../styles/base/index';

.PaymentMethodIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120 / 3.8 * .1rem;
  height: 75 / 3.8 * .1rem;
  border-radius: 2px;
  overflow: hidden;
  background-color: color('foreground', .2);
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .BaseIcon {
    font-size: 2.4rem;
  }
}