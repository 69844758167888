@import '../../styles/base';

.LiveSiteWarningFrame {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  position: fixed;
  top: 0;
  right: 0; 
  bottom: 0;
  left: 0;
  pointer-events: none;
  border: 2px solid color(alert)
}
@keyframes warningBlink {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.LiveSiteWarningTag {
  position: fixed;
  background-color: color(alert);
  color: contrastColor(alert);
  padding: .2em .5em;
  display: flex;
  align-items: center;
  &:nth-child(1) {
    top: 0;
    left: 0;
  }
  &:nth-child(2) {
    top: 0;
    right: 0;
  }
  &:nth-child(3) {
    bottom: 0;
    right: 0;
  }
  &:nth-child(4) {
    bottom: 0;
    left: 0;
  }
  &:nth-child(5) {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .38;
    font-size: 1.6rem;
    text-transform: none;
    letter-spacing: 0;
    animation: warningBlink steps(2) 2s infinite;
  }
}