@import '../../styles/base';

.BaseMenu {
  background-color: color('background');
  border-radius: .5em;
  @media #{$tablet} {
    font-size: 1.4rem;
  }
  @media #{$desktop} {
    border-radius: .38em;
  }
  box-shadow: 0 .62em 1.62em color('deepPurple', 0.06);
  user-select: none;
  overflow: hidden;
}