@import '../../../../styles/base';

.AllCaughtUpBlock {
  text-align: center;
  padding: 1em;
  color: color('blueGreen');
  p {
    font-size: 1.8rem;
  }
  > * {
    + * {
      margin-top: .75em;
    }
  }
}