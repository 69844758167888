@import '../../../../styles/base';

.UserFeesManager {
  position: relative;
  > * {
    + * {
      margin-top: .5em;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    th {
      text-align: left;
      padding-right: .5em;
    }
  }
  tbody {
    th, td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    th {
      width: auto;
      text-align: left;
      padding-left: .5em;
      em {
        font-weight: 400;
      }
    }
    td {
      max-width: 7em;
      padding-right: .5em;
      div {
        display: flex;
        align-items: baseline;
      }
      &.u-align-right {
        > div {
          justify-content: flex-end;
        }
      }
    }
    tr {
      &:nth-child(even) {
        th, td {
          background-color: color('primary', .06);
        }
      }
    }
    input {
      height: 2em;
      text-align: right;
      max-width: 5em;
    }
  }
  thead, tbody {
    th, th {
      &:first-child {
        width: 4.5em;
      }
    }
  }
}

.FeeOverrideClearerButton {
  padding-left: .5em;
  padding-right: .5em;
  &:hover {
    color: color('red');
  }
}