@keyframes IFrameViewEnter {
  from { opacity: 0; }
  to { opacity: 1; }
}
.IFrameView {
  width: 100%;
  height: 100%;
  background-color: #fff;
  iframe {
    width: 100%;
    height: 100%;
    display: block;
    border: none;
    background-color: #fff;
    animation: IFrameViewEnter .3s;
  }
}