@import '../../styles/base';

.BaseTable {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: unset;
  }
  font-size: 1.4rem;
  position: relative;
  &.inline {
    display: inline-block;
    .BaseTableTable {
      width: auto;
    }
  }
  th, td {
    padding-top: .38em;
    padding-bottom: .38em;
    padding-left: .25em;
    padding-right: .25em;
    border-bottom: var(--BorderMinor);
    transition: border-color .1s;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  a {
    color: color('primary');
    text-decoration: underline;
    &:hover {
      filter: brightness(1.1);
    }
  }
  [data-type="email"],
  [data-type="tel"],
  [data-type="url"] {
    overflow-wrap: break-word;
  }
  [data-type="number"],
  [data-type="currency"] {
    text-align: right;
    padding-right: .5em;
    font-feature-settings: 'tnum' 1;
  }
  .ColorTag {
    white-space: nowrap;
    font-size: 10px;
  }
}

.BaseTableTable {
  border-collapse: collapse;
  width: 100%;
  border: 0;
}

.BaseTableBody {
  tr {
    &:hover {
      th, td {
        border-color: color('primary',.5);
      }
    }
    &.BaseTableBodyFillerRow {
      pointer-events: none;
    }
  }
}

.BaseTableBodyFillerRow {
  user-select: none;
  @media print {
    display: none;
  }
  td {
    border-color: transparent;
  }
}

.BaseTableControls {
  display: flex;
  justify-content: flex-end;
}