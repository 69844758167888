@import '../../styles/base';


.CommandListItem {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  &.awaitingResponse {
    opacity: .5;
    pointer-events: none;
  }
  .CommandListItem__inner {
    display: flex;
    align-items: center;
    > * {
      + * {
        margin-left: .5em;
      }
    }
  }
  .BaseIcon {
    margin-right: .5em;
  }
  &.alert {
    @include setPrimaryColor('red');
    color: color('primary');
    .BaseIcon {
      color: inherit;
    }
  }
  &.interactable {
    .mouse & {
      transition: .1s;
      cursor: pointer;
      &:hover {
        background-color: color('primary', .05);
      }
    }
    &:active {
      background-color: color('primary', .15);
      filter: brightness(.9)
    }
  }
  &.noPointer {
    cursor: none;
    pointer-events: none;
  }
  &.disabled {
    filter: grayscale(1);
    pointer-events: none;
  }
}