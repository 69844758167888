@import '../../styles/base';

.BackButton {
  appearance: none;
  display: inline-flex;
  border-radius: 50%;
  border: 2px solid transparent;
  background-color: color('primary', .1);
  min-width: unset;
  padding: 0;
  color: currentColor;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  @media print {
    display: none;
  }
  .BaseIcon {
    font-size: 1.2rem;
    display: block;
  }
  &:hover {
    border-color: color('primary', .1);
  }
  &:active {
    background-color: color('primary', .15);
    border-color: color('primary', .15);
  }
}