@import '../../styles/base';

.AppContainer {
  @media print {
    width: 100vw;
  }
  @media screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.hasPortalComponents {
      overflow: hidden;
    }
    .no-scroll & {
      overflow: hidden;
    }
  }
}
@keyframes AppPortalBackgroundEnter {
  from { background-color: color('foreground', 0) }
  to { background-color: color('foreground', .5) }
}
.AppPortal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: color('foreground', 0);
  transition: background-color 1s;
  perspective: 1382px;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
  &:empty {
    display: none;
  }
}

.NonPhoneMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: color('white', .9);
  [data-enable-blur-effect="true"] & {
    background-color: color('white', .4);
    backdrop-filter: blur(2em);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em;
  h3 {
    font-size: 1.8rem;
  }
}