@import '../../styles/base';

.MessengerPanelVoipCallList {
  > header {
    user-select: none;
    background-color: color('orange', .2);
    padding: .5em .5em .5em 1em;
    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      color: color('orange');
      flex: 1 1 auto;
      [data-mode="normal"] & {
        padding-left: size(md);
      }
    }
    p {
      font-size: 1.4rem;
      margin-top: .19em;
    }
  }
  input {
    padding-left: 1em;
    border-radius: 0;
    [data-mode="normal"] & {
      padding-left: size(md);
    }
    @media #{$tablet} {
      padding-top: .62em;
      padding-bottom: .62em;
    }
  }
  .VoipCallEntry {
    padding-left: 1em;
    font-size: 1.4rem;
    + * {
      border-top: 1px solid color('primary', .2);
    }
  }
}

.MessengerPanelVoipErrorMessage {
  color: color('red');
  background-color: color('red', .1);
  padding: 0 .19em;
  border-radius: 3px;
}