@import '../../../../styles/base';

.CounsellingClientProgressGraph {
  background-color: color('primary', .072);
  padding: .5em 0 1em .5em;
  border-radius: .62em;
}
.CounsellingClientProgressGraphContainer {
  height: 300px;
  // text {
  //   font-size: 12px;
  // }
}