@import '../../styles/base';

.BaseMenuItem {
  appearance: none;
  display: flex;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  align-items: center;
  padding: .75em 1em;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background-color: transparent;
  color: inherit;
  overflow: hidden;
  @media #{$desktop} {
    padding: .62em;
  }
  + .BaseMenuItem {
    border-top: 1px solid color('foreground', .1);
  }
  &.disabled {
    cursor: not-allowed;
    > * {
      opacity: .5;
    }
  }
  &:not(.disabled) {
    &:active {
      background-color: color('foreground', .06);
      filter: brightness(.98);
    }
  }
  @extend %colorCodedStates;
  &[class*="state-"] {
    color: color('primary');
  }
  .BaseIcon {
    flex: 0 0 auto;
    margin-right: .5em;
  }
}
.BaseMenuItemLabel {
  font-weight: 600;
}