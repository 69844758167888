@import '../../../../../../styles/base';

.CounsellingSessionTimePicker {
  .TimeframePicker {
    margin-left: -.62em;
    margin-right: -.62em;
    .BaseButton {
      background-color: transparent;
    }
    .BaseIcon {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.CounsellingSessionTimePickerDateList {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  > div {
    flex: 1 1 100 / 7 * 1%;
    padding: .38em;
    text-align: center;
  }
}
.CounsellingSessionTimePickerDateListItemWeekday {
  display: block;
  font-size: 75%;
  font-weight: 900;
  opacity: .62;
  margin-bottom: .38em;
}
.CounsellingSessionTimePickerDateListItemDateButton {
  appearance: none;
  position: relative;
  width: 100%;
  max-width: 3.8em;
  background-color: color('white');
  font-weight: 500;
  border-radius: 50%;
  padding: 0;
  color: color('primary');
  border: 1px solid color('primary', .19);
  @media #{$tablet} {
    border-width: 2px;
  }
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  &.selected {
    background-color: color('primary');
    color: contrastColor('primary');
    font-weight: 700;
  }
  &:hover {
    border-color: color('primary');
  }
  &.inThePast {
    background-color: color('white',.3);
    color: color('primary', .5);
    cursor: not-allowed;
    border-color: transparent;
  }
  .BaseIcon {
    position: absolute;
    padding: 0.04em;
    bottom: 0;
    right: 0;
    font-size: 1rem;
    background-color: color('brightPurple', 0.8);
    color: color('white');
    border-radius: 40%;
    @media #{$tablet} {
      font-size: 1.2rem;
    }
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  strong {
    background-color: color('red');
    color: contrastColor('red');
    border-radius: 1em;
    padding: .19em .38em;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.2rem;
    font-weight: 800;
    @media #{$tablet} {
      font-size: 1.4rem;
    }
  }
}

.CounsellingSessionTimePickerTimeListContainer {
  position: relative;
  padding: .5em;
  margin-top: .62em;
}

.CounsellingSessionTimePickerTimeListContainerBackground {
  background-color: color('primary');
  border-radius: .38em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .2;
}

.CounsellingSessionTimePickerTimeListContainerTrianglePointer {
  display: block;
  background-color: color('primary');
  position: absolute;
  bottom: 100%;
  width: 1em;
  height: 1em;
  transition: .19s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: rotate(45deg) translateY(66.67%);
}

.CounsellingSessionTimePickerTimeList {
  position: relative;
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(6em, auto));
  grid-auto-rows: 1fr;
  grid-gap: .5em;
  .nogrid & {
    display: flex;
  }
  button {
    appearance: none;
    background-color: color('white', .75);
    border: 1px solid transparent;
    border-radius: .19em;
    padding-top: .5em;
    padding-bottom: .5em;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.2em;
    }
    .nogrid & {
      padding-left: 1em;
      padding-right: 1em;
    }
    font-weight: 500;
    color: inherit;
    &.inThePast,
    &.booked {
      opacity: .5;
      cursor: not-allowed;
    }
    &:hover {
      border-color: color('primary');
    }
    &.selected {
      background-color: color('primary');
      color: contrastColor('primary');
    }
  }
}

.CounsellingSessionTimePickerTimeListEmptyNotice {
  text-align: center;
  padding: 1em;
}