:root {
  /* HSL colors */
  @each $color, $value in $colors {
    --#{$namespace}-c-#{'' + $color}-h: #{nth(map-get($value, hsl),1)};
    --#{$namespace}-c-#{'' + $color}-s: #{nth(map-get($value, hsl),2)};
    --#{$namespace}-c-#{'' + $color}-l: #{nth(map-get($value, hsl),3)};
    --#{$namespace}-c-#{'' + $color}-hsl: #{nth(map-get($value, hsl),1),nth(map-get($value, hsl),2),nth(map-get($value, hsl),3)};
    --#{$namespace}-c-#{'' + $color}_h: #{nth(map-get($value, contrast),1)};
    --#{$namespace}-c-#{'' + $color}_s: #{nth(map-get($value, contrast),2)};
    --#{$namespace}-c-#{'' + $color}_l: #{nth(map-get($value, contrast),3)};
    --#{$namespace}-c-#{'' + $color}_hsl: #{nth(map-get($value, contrast),1),nth(map-get($value, contrast),2),nth(map-get($value, contrast),3)};
  }
  // set context colors in apps.
  @each $contextColorName, $colorName in $contextColors {
    @include setContextColor($contextColorName, $colorName)
  }
}

:root {
  /* fonts */
  @each $name, $value in $fonts {
    @if type-of($value) == 'map' {
      --#{$namespace}-f-#{$name}: #{map-get($value, default)};
      @supports (font-variation-settings: normal) {
        --#{$namespace}-f-#{$name}: #{map-get($value, variable)};
      }
    } @else {
      --#{$namespace}-f-#{$name}: #{$value};
    }
  }
}

:root {
  /* units and sizes */
  --#{$namespace}-s-unit: 4.8rem;
  @each $name, $value in $sizes {
    @if (type-of($value) == 'map') {
      @each $breakpoint, $breakpointValue in $value {
        @if ($breakpoint == 'default') {
          --#{$namespace}-s-#{$name}: #{$breakpointValue};
        } @else {
          @media #{getMediaQuery('from', $breakpoint)} {
            --#{$namespace}-s-#{$name}: #{$breakpointValue};
          }
        }
      }
    } @else {
      --#{$namespace}-s-#{$name}: #{$value};
    }
  }
}

@each $color, $value in $colors {
  [data-primary-color="#{$color}"] {
    @include setPrimaryColor($color);
  }
  [data-text-color="#{$color}"] {
    color: color($color);
  }
}

:root {
  &.dark-theme {
    background-color: color('dark', 1, -.2);
    color: color('foreground');
    @include setBackgroundColor('dark');
    @include setForegroundColor('white');
  }
}