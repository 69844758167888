@import '../../styles/base';

@keyframes ChatWindowBodyEnter {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ChatWindowBody {
  background-color: color('background');
  grid-template-rows: minmax(0, 1fr) auto;
  display: none;
  .bodyVisible & {
    display: grid;
    .nogrid & {
      display: block;
    }
  }
  [data-mode='docked'] & {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    max-height: 80vh;
    box-shadow: 0 .5em 1em rgba(0,0,0,0.1);
    border-radius: .5em .5em 0 0;
    animation: .2s ChatWindowBodyEnter;
    border-width: 1px 1px 0 1px;
    border-color: color('primary', .38);
    border-style: solid;
    overflow: hidden;
    [data-enable-blur-effect="true"] & {
      background-color: transparent;
      background-image: linear-gradient(133deg, color('background', .7), color('background', .4));
      backdrop-filter: blur(2em);
    }
    .ChatWindowParticipantList {
      border-top-left-radius: .5em;
    }
  }
  @media print {
    max-height: unset !important;
    border: none !important;
  }
}

.ChatWindowBodyInner {
  display: grid;
  .nogrid & {
    display: flex;
    align-items: stretch;
    flex: 1 1 100%;
  }
  grid-template-columns: minmax(20rem, 1fr);
  [data-participant-list-visible=true] & {
    grid-template-columns: auto minmax(20rem, 2fr);
  }
  grid-template-rows: repeat(1, minmax(0, 1fr));
  max-height: 100%;
  min-height: 0;
  @media print {
    grid-template-columns: minmax(auto, 1fr);
    [data-participant-list-visible=true] & {
      grid-template-columns: auto minmax(auto, 2fr);
    }
    padding-right: 1em;
    border: none !important;
  }
}

.ChatWindowBodyNotice {
  padding: .5em 1em;
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  .BaseIcon {
    margin-right: .33em;
    flex: 0 0 auto;
  }
}
.ChatWindowBodyOfflineNotice {
  background-color: color('red', .2);
  color: color('red');
}

.ChatWindowBodyChatOffNotice {
  background-color: color('blueGreen', .2);
  color: color('blueGreen');
}

.ChatWindowBodyChatMutedNotice,
.ChatWindowBodyChatNotParticipantNotice {
  background-color: color('orange', .2);
  color: color('orange');
}

.ChatWindowBodyFooter {
  align-items: stretch;
  border-top: 1px solid color('primary', .25);
  background-color: color('background', .62);
  .ChatMessageComposer {
    flex: 1 1 100%;
    min-height: 100%;
  }
  [data-browser="chrome"] & {
    @media #{$only-phone} {
      min-height: 68px;
    }
  }
  @media print {
    border-top: none;
  }
}