@import '../../styles/base';

.BaseTableHeadCell {
  height: 1px;
  &[data-type="number"],
  &[data-type="currency"] {
    .BaseTableHeadCellInner {
      grid-template-columns: 1fr auto;
      grid-template-areas: 'content sortToggle';
    }
  }
  &.sortable {
    cursor: pointer;
    padding-right: .5em;
    &:hover {
      background-color: color('primary', .1);
    }
    &:active {
      background-color: color('primary', .19);
    }
  }
  &[data-sort-direction="asc"],
  &[data-sort-direction="desc"] {
    color: color('positive');
  }
  &[data-sortable="true"] {
    .BaseTableHeadCellInner {
      display: grid;
      .nogrid & {
        display: flex;
      }
    }
  }
}
.BaseTableHeadCellInner {
  grid-gap: .25em;
  .nogrid & {
    gap: 0;
  }
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'sortToggle content';
  .SortToggle {
    grid-area: sortToggle;
    .nogrid & {
      margin-right: .25em;
    }
  }
}
.BaseTableHeadCellInnerContent {
  grid-area: content;
}