@import '../../../../styles/base';

.CounsellingApplicationUI {
  overflow: hidden;
  background-color: color('primary');
  color: contrastColor('primary');
  height: var(--AppHeight);
  height: var(--UICardOpenedHeight, var(--ApHeight));
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  min-height: 100%;
  transform: translate3d(0,0,0);
  .nogrid & {
    display: block;
  }
  .OverlayCloseButton {
    position: absolute;
    top: size(md);
    right: size(md);
  }
  .Overlay & {
    height: 100%;
  }
}

.CounsellingApplicationUIHeader {
  padding: size(ml);
  padding-top: calc(env(safe-area-inset-top) + #{size(ml)});
  @media #{$tablet} {
    padding-top: 1.62em;
    padding-bottom: 1.62em;
  }
  h1 {
    font-size: 2.6rem;
    font-weight: 500;
  }
}

.CounsellingApplicationUIAfterTitle {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .062em;
  margin-top: .5em;
}

.CounsellingApplicationUILoadingScreen {
  display: flex;
  min-height: 61vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: size(lg, 2);
}

@keyframes CounsellingApplicationUIBodyEnter {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

.CounsellingApplicationUIBody {
  position: relative;
  color: color('foreground');
  white-space: nowrap;
  min-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: unset;
  }
  scroll-snap-type: x mandatory;
  animation: CounsellingApplicationUIBodyEnter 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:before {
    content: '';
    position: absolute;
    left: size(ml);
    width: var(--CounsellingApplicationUIScrollWidth);
    top: 0;
    bottom: 0;
    border-top-left-radius: 1em;
    @media #{$only-phone} {
      background-image: linear-gradient(to bottom, color('white', .9) 0%, color('white', 0.62) 100%);
    }
    @media #{$tablet} {
      background-image: linear-gradient(to bottom, color('white') 0%, color('white', 0.9) 100%);
    }
  }
}

.CounsellingApplicationUIStep {
  position: relative;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  scroll-snap-align: start;
  height: 100%;
  overflow: auto;
  padding: size(ml);
  box-sizing: border-box;
  width: var(--CounsellingApplicationUIContainerWidth);
  flex: 0 0 var(--CounsellingApplicationUIContainerWidth);
  &:first-child {
    padding-left: size(ml, 2);
  }
  &.disabled {
    overflow: hidden;
    > * {
      max-height: 100%;
      overflow: hidden;
    }
  }
  blockquote {
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
}

.CounsellingApplicationUIStepDisabledScreen {
  background-color: color('white', .9);
  [data-enable-blur-effect="true"] & {
    background-color: color('white', .3);
    backdrop-filter: blur(1em);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  em {
    display: block;
    margin-top: .5em;
    margin-bottom: 3em;
  }
}

.CounsellingApplicationUIFooter {
  position: fixed;
  bottom: 0;
  height: 0;
  left: 0;
  right: 0;
  > .BaseButton {
    position: absolute;
    bottom: size(ml);
    backdrop-filter: blur(1em);
    z-index: 1;
    .BaseIcon {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.CounsellingApplicationUIPrevStepButton {
  left: size(ml);
  [data-primary-color="yellow"] & {
    color: contrastColor('yellow', .62);
  }
}

.CounsellingApplicationUINextStepButton {
  right: size(ml);
  // background-image: radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(0,159,227, 0.71) 0%, #009FE3 100%);
  // background-image: radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(150, 150, 252, 0.71) 0%, #4E4BE0 100%);
  // [data-primary-color="firebrick"] & {
  //   background-image: radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(237, 122, 106, 0.71) 0%, rgb(193, 91, 77) 100%);
  // }
  // [data-primary-color="yellow"] & {
  //   background-image: radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(255,191,25, 0.71) 0%, #FF9519 100%);
  // }
  // &.submit {
    background-image: radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(0, 255, 42, 0.71) 0%, #00a151 100%);
  // }
  color: white;
  .BaseIcon {
    margin-right: 0;
  }
  .BaseButtonLabel {
    @media #{$only-phone} {
      font-size: 0;
      transition: .19s;
      margin: 0;
      opacity: 0;
      .canSubmit & {
        font-size: 1.4rem;
        margin-left: .38em;
        opacity: 1;
      }
    }
  }
}