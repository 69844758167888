@import '../../styles/base';

.BaseCalendarDay {
  vertical-align: top;
  padding: 0;
  .fitInnerToAvailableHeight & {
    display: flex;
    flex-direction: column;
    flex: 0 0 100% / 7;
    max-width: 100% / 7;
    overflow: auto;
    .BaseCalendarDayInner {
      flex: 0 0 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }
    .shouldDisplayAsTimeline & {
      &:after {
        content: '';
        background-image: linear-gradient(to top, color('background', .5) 0, color('background', 0) 100%);
        display: block;
        height: 1em;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
      }
    }
  }
  &.outsideOfTimeframe {
    .BaseCalendarDayInner {
      opacity: .62;
      &:hover {
        opacity: 1;
      }
    }
  }
  &[data-day-of-week="0"], 
  &[data-day-of-week="6"] {
    .BaseCalendarDayWeekday, 
    .BaseCalendarDayTitle {
      color: color('blueGreen');
    }
  }
  &.today {
    .BaseCalendarDayTitle {
      background-color: color('alert');
      color: contrastColor('alert');
      min-width: 1.618em;
      text-align: center;
    }
  }
}
.BaseCalendarDayHeader {
  padding: .25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media #{$tablet} {
    align-items: flex-end;
  }
  .shouldDisplayAsTimeline & {
    position: sticky; 
    top: 0;
    background-color: color('background');
    [data-enable-blur-effect="true"] & {
      background-color: color('background', .6);
      backdrop-filter: blur(1em);
    }
    z-index: 1;
  }
}
.BaseCalendarDayWeekday {
  margin-top: .5em;
  font-size: 87.5%;
  opacity: .6;
  font-weight: 700;
}
.BaseCalendarDayTitle {
  padding: .25em;
  font-weight: 400;
  font-size: inherit;
  display: block;
  border-radius: 3em;
  font-weight: 700;
  @media #{$tablet} {
    font-size: 1.6rem;
  }
}

.BaseButton.BaseCalendarDayAddButton {
  display: flex;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  font-size: 80%;
  background-color: color('background',.9);
  margin-bottom: 1px;
  padding: .38em 0;
  opacity: .38;
  &:hover {
    background-color: color('primary', .1);
    opacity: 1;
  }
  [data-view-mode="week"].fitInnerToAvailableHeight & {
    padding-top: .5em;
    padding-bottom: .5em;
  }
  .BaseButtonInner {
    justify-content: flex-end;
  }
}

.BaseCalendarDayBackgroundAddButton {
  flex: 100 100 auto;
  cursor: pointer;
}