.SwiperContainer {
  opacity: 0;
  transition: opacity .2s;
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0,.75,.39,1) !important;
  }
  &.swiper-container-initialized {
    opacity: 1;
  }
  &.unclip {
    overflow: visible;
  }
  &.flex {
    .swiper-wrapper {
      display: flex;
    }
  }
}
