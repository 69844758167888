@import '../../styles/base';

.ChatWindowParticipantList {
  background-color: color('background');
  [data-mode="standalone"] & {
    background-color: color('primary', .1);
  }
  height: 100%;
  .nogrid & {
    height: auto;
  }
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-right: 1px solid color('primary', .25);
  .--cypress & {
    scroll-behavior: unset;
  }
  min-width: 10em;
  transition: min-width .1s;
  @media print {
    background: none;
  }
  .AutosuggestSearchBar {
    background-color: color('background');
    .SearchBar {
      position: sticky;
      top: 0;
      left: 0;
      background-color: color('background');
      border-bottom: 1px solid color('primary', .25);
    }
    input {
      border: none;
      background-color: color('background');
      [data-mode="standalone"] & {
        background-color: color('primary', .1);
      }
    }
    @media print {
      display: none;
    }
  }
  &.hasQuery {
    min-width: 16em;
    z-index: 1;
  }
  .AutosuggestSearchBarResultItem {
    padding-left: 1em;
  }
}

.ChatWindowParticipantListHeader {
  border-bottom: 1px solid color('primary', .25);
  padding: .38em 1em;
  min-height: 2.62em;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: .5em;
  }
  h3 {
    font-size: inherit;
    color: color('primary', 1, -.3);
    white-space: nowrap;
  }
  .BaseButton {
    margin-left: -.5em;
  }
}

.ChatWindowParticipantListInner {
  .AutosuggestSearchBar {
    input.BaseInputInput[type="search"] {
      border-radius: 0;
    }
    .AutosuggestSearchBarResultList,
    .AutosuggestSearchBarStateDisplay {
      border-bottom: 1px solid color('primary', .25);
    }
  }
  table {
    margin-top: .5em;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    text-align: left;
  }
  thead {
    font-size: 1rem;
    text-transform: uppercase;
    color: color('primary');
    @media print {
      display: none;
    }
    th {
      font-weight: 800;
      &:first-child {
        padding-left: 1.4em;
      }
      &:last-child {
        text-align: right;
        padding-right: 1em;
      }
    }
  }
  tbody {
    tr {
      th, td {
        border-top: 1px solid color('primary', .1);
      }
    }
  }
  .UserNameTag {
    @media screen {
      @include clampLines();
      text-overflow: initial;
    }
    @media print {
      display: block;
    }
  }
}

.ChatWindowParticipantSuggestionEntry {
  &[data-already-in-chat="true"], &[data-disabled="true"] {
    opacity: .5;
    pointer-events: none;
  }
}