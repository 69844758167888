@import '../../styles/base';

.TimeframePicker {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'back title forward';
  .nogrid & {
    display: flex;
  }
}

h2.TimeframePickerTitle {
  grid-area: title;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  .nogrid & {
    flex: 1 1 auto;
  }
  @media #{$tablet} {
    font-size: 1.8rem;
  }
  strong {
    font-weight: 700;
    color: color('primary');
  }
}

.BaseButton.TimeframePickerNavButton {
  width: 3em;
  min-width: unset;
  border: none;
  border-radius: 0;
  margin: 0 !important;
  @media #{$only-phone} {
    background-color: transparent;
  }
  @media #{$tablet} {
    width: 3em;
  }
  .BaseButtonInner {
    padding: 100% 0 0 0;
  }
  .BaseIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.8rem;
    height: 1.8rem;
    transform: translate(-50%, -50%);
  }
  &.--back {
    grid-area: back;
  }
  &.--forward {
    grid-area: forward;
  }
}