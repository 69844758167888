@import '../../styles/base';

.CounsellingJourney {
  overflow: hidden;
  border-top: 2px solid color('primary');
  .CounsellingJourneyTimeline {
    padding-top: 1em;
  }
  background-image: linear-gradient(to bottom, #FFFFFF 19%, color('primary', .19) 50%);
  .AppPageTitle {
    margin-top: .19em;
    margin-bottom: .19em;
  }
}

.CounsellingJourneyCardNoticeSection {
  padding: 0 size(md);
  + * {
    padding-top: size(md);
  }
}

.CounsellingJourneyCardContentSwiperSection {
  @extend .CounsellingJourneyCardNoticeSection;
  overflow: hidden;
  .SwiperContainer {
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: visible;
  }
}

.CounsellingJourneyCardContentMenuSection {
  padding-left: size(md);
  padding-right: size(md);
  padding-bottom: calc(#{size(ml)} + env(safe-area-inset-bottom));
  color: color('darkPurple');
  @include setPrimaryColor('darkPurple');
}

.CounsellingJourneyCardContentHeading {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 800;
  + * {
    margin-top: 1em;
  }
}