@import '../../styles/base';

.IndexDirectorySearchBar {
  width: 100%;
  position: sticky;
  left: 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  .SearchBar {
    flex: 1 1 auto;
  }
  .BaseSelectorSelect {
    font-weight: 700;
    color: color('primary');
  }
  > * {
    input[type="search"], select {
      border: 0;
      border-radius: 0;
    }
    &:first-child {
      input, select {
        border-top-left-radius: .38em;
        border-bottom-left-radius: .38em;
      }
      select {
        padding-left: .75em;
      }
    }
    &:last-child {
      input, select {
        border-top-right-radius: .38em;
        border-bottom-right-radius: .38em;
      }
    }
    + * {
      margin-left: -1px;
    }
  }
}
