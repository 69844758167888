@import '../../styles/base';

.UserIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: color('skyblue');
  color: contrastColor('skyblue');
  font-weight: 800;
  text-transform: uppercase;
  font-feature-settings: 'smcp' 1;
  font-size: 85%;
  flex-basis: auto;
  flex-shrink: 0;
  -webkit-print-color-adjust: exact;
  &.light {
    background-image: linear-gradient(256deg, color('white', .19), color('white', 0));
  }
  &.dark {
    background-image: linear-gradient(133deg, color('deepPurple', .1), color('white', 0));
  }
  span {
    text-decoration: none;
  }
}