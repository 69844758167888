@import '../../styles/base';

.OverlayDonate {
  background-image: linear-gradient(to bottom, color('primary', .19), color('primary', 0) 10em);
  .MakePaymentForm {
    > h3 {
      margin-bottom: .5em;
    }
  }

  .HeartIconWrapper {
    display: inline-flex;
    vertical-align: middle;
    transition: all .36s ease-in-out;
  }

  .HeartIcon {
    &.oneTime {
      animation: HeartPump .6s ease-in-out 0s 1 normal none;
    }
    &.weekly {
      animation: HeartPumpLarge .6s ease-in-out 0s 3 normal none;
    }
    &.monthly {
      animation: HeartPumpSpin 1.2s ease-in-out 0s 1 normal none;
    }
  }
}

@keyframes HeartPump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes HeartPumpLarge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes HeartPumpSpin {
  0% {
    transform: scale(1);
  }
  16% {
    transform: scale(1.6);
  }
  33% {
    transform: rotate(0deg) scale(1);
  }
  70% {
    transform: rotate(360deg) scale(1);
  }
  86% {
    transform: rotate(360deg) scale(1.6);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}