@import '../../styles/base';

.InfoTable {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th, td {
    vertical-align: baseline;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-right: .5em;
  }
  tr {
    + tr {
      th, td {
        border-top: 1px solid color('primary', .1);
      }
    }
  }
  thead {
    th {
      color: color('primary');
      padding-top: .5em;
      padding-bottom: .5em;
      text-transform: uppercase;
      font-weight: 800;
    }
  }
  tbody {
    th {
      max-width: 12.5em;
    }
    td {
      width: 61.8%;
    }
    + thead {
      th {
        border-top: 2px solid color('primary', .1);
        padding-top: 1em;
      }
    }
  }
  .BaseToggle {
    margin-top: 0;
    margin-bottom: 0;
  }
  td {
    a {
      color: color('primary');
      @extend %underlinedLinks;
    }
  }
  textarea {
    vertical-align: top;
  }
}