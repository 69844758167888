@import '../../../styles/base';

.ModuleSection {
  @media #{$only-phone} {
    padding: 0 1em;
  }
  &.spaceChildren {
    > * {
      + * {
        margin-top: size(md);
      }
    }
  }
}