@import '../../styles/base';

.BaseButton {
  position: relative;
  text-decoration: none;
  text-transform: var(--ButtonTextTransform);
  font-family: font(heading);
  line-height: 1;
  font-weight: 600;
  font-size: 87.5%;
  cursor: pointer;
  &.fullWidth {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  @extend %colorCodedStates;
  &.disabled,
  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }
  &.isLoading {
    opacity: .62;
    pointer-events: none;
    .BaseButtonInner {
      opacity: 0;
      pointer-events: none;
    }
  }
  .LoadingIndicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &.noop {
    pointer-events: none;
  }
}

.BaseButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
  transition: opacity .1s;
  .BaseButtonStaticLabel,
  .BaseButtonHoverLabel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.BaseButton--default {
  display: inline-flex;
  align-items: stretch;
  appearance: none;
  background-color: color('primary');
  border: none;
  border-radius: .5em;
  @media #{$tablet} {
    border-radius: .38em;
  }
  color: contrastColor('primary');
  // min-width: 6em;
  // font-size: 1.3rem;
  user-select: none;
  box-shadow: none;
  box-sizing: border-box;
  &.xs {
    font-size: 75%;
    min-width: unset;
    .BaseIcon {
      font-size: .85em;
    }
    .BaseButtonInner {
      padding: .38em .5em;
      text-transform: uppercase;
      font-weight: 800;
    }
    .BaseIcon {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  &.sm {
    font-size: 1.2rem;
    .BaseButtonInner {
      padding: .62em;
    }
    .BaseIcon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &.md {
    border-radius: .62em;
    .BaseButtonInner {
      padding: .875em 1.19em;
      @media #{$tablet} {
        font-size: 1.6rem;
        min-height: 2.5em;
        padding: .62em .875em;
      }
    }
  }
  &.lg {
    border-radius: .62em;
    .BaseButtonInner {
      padding: 1em 1.38em;
      font-size: 1.19em;
      @media #{$desktop} {
        padding: 1.19em 1.62em;
        .BaseIcon {
          font-size: 1.4rem;
        }
      }
    }
  }
  &.xl {
    border-radius: .62em;
    .BaseButtonInner {
      padding: 1em 1.38em;
      @media #{$desktop} {
        font-size: 1.38em;
        padding: 1.5em 2em;
        .BaseIcon {
          font-size: 1.4rem;
        }
      }
    }
  }
  &.rounded {
    border-radius: 100em;
    &.md {
      padding-left: .75em;
      padding-right: .75em;
    }
    &.lg, &.xl {
      padding-left: 1.38em;
      padding-right: 1.38em;
    }
  }
  &.circle {
    border-radius: 100em;
    min-width: unset;
    padding: 1em;
    .BaseButtonInner {
      padding: 0;
    }
    &.lg {
      padding: 1.5em;
    }
  }
  // .apple & {
  //   background-color: color('primary', .8);
  //   backdrop-filter: blur(1em);
  // }
  .BaseButtonStaticLabel > *,
  .BaseButtonHoverLabel > * {
    display: block;
    margin: 0 .25em;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.9);
  }
  &.subtle {
    background-color: color('primary',.138);
    background-image: none;
    color: color('primary');
  }
  &.inverted {
    background-color: color('white', .2);
    background-image: none;
    color: color('white');
  }
}

.BaseButton--text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  color: color('primary');
  padding: 0;
  border: none;
  cursor: pointer;
  margin-right: .38em;
  text-align: center;
  user-select: none;
  &.padded {
    .BaseButtonInner {
      padding: .875em .62em;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .BaseIcon {
    width: 1em;
    height: 1em;
  }
  @extend %colorCodedStates;
  .BaseButtonStaticLabel,
  .BaseButtonHoverLabel {
    // flex-direction: column;
    justify-content: center;
    > * {
      display: block;
      margin: 0 .25em;
      &.BaseIcon {
        margin-left: 0;
        margin-right: 0.25em;
      }
      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(.9);
  }
}

.BaseButton--icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  color: color('primary');
  border: none;
  padding: 0;
  &:hover {
    background-color: color('primary', .1);
  }
  &.md {
    .BaseButtonInner {
      padding: .5em;
    }
  }
  .BaseIcon {
    display: block;
  }
  &.rounded {
    border-radius: 50%;
  }
}

.BaseButton--tab {
  appearance: none;
  background-color: color('primary', .2);
  color: color('primary');
  padding: 1em .62em;
  text-align: center;
  border: none;
  min-width: 6.4rem;
  box-sizing: border-box;
  .BaseButtonStaticLabel,
  .BaseButtonHoverLabel {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .BaseButtonLabel {
    font-size: 85%;
    margin-top: .5em;
    font-weight: 500;
  }
}

.BaseButtonInner {
  position: relative;
}

.BaseButtonStaticLabel {
  .BaseButton.hasHoverLabel & {
    transition: opacity .1s;
  }
  .BaseButton.hasHoverLabel:hover & {
    opacity: 0;
  }
}
.BaseButtonHoverLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
  transition: opacity .1s;
  min-width: 100%;
  .BaseButton.hasHoverLabel:hover & {
    opacity: 1;
  }
}
