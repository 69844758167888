@import '../../styles/base';

.DeviceOfflineNotice {
  background-color: color('red');
  color: color('white');
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .5em;
  font-weight: 500;
  text-align: center;
  font-size: 1.4rem;
}