@import '../../styles/base';

.UICardOuter {
  --backgroundColor: #{color('background')}; 
  &.keepRatio {
    .UICard {
      height: 100%;
    }
  }
}

.UICard {
  position: relative;
  transform: translate3d(0,0,0);
  > .UICardSideA {
    &.clipContent {
      overflow: hidden;
    }
  }
  &.open {
    > .UICardSideA {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      .--cypress & {
        scroll-behavior: unset;
      }
    }
    &:not(.closing) {
      @media #{$only-phone} {
        border-radius: 0;
        > .UICardSideA {
          border-radius: 0;
        }
      }
      @media #{$tablet} {
        border-radius: 1em;
        box-shadow: 0 1em 5em rgba(0,0,0,0.2);
        > .UICardSideA {
          border-radius: 1em 1em 0 0;
        }
      }
      @media #{$desktop} {
        border-radius: 1.38em;
        > .UICardSideA {
          border-radius: 1.38em 1.38em 0 0;
        }
      }
    }
  }
  &.closing {
    > .UICardSideA {
      &.clipContent {
        overflow: hidden;
      }
      &:not(.clipContent) {
        overflow: visible;
      }
    }
  }
  > .UICardSideA {
    &.framed {
      background-color: var(--backgroundColor);
      border-radius: var(--CardRadius);
    }
    backface-visibility: none;
    transition: .2s;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate3d(0,0,0);
    .UICard:not(.open) &:active {
      transition: .1s;
      transform: scale(.98);
    }
  }
  &.opening, &.open {
    > .UICardSideA {
      transform: translate3d(0,0,-1px);
    }
  }

  > .UICardSideB {
    transform: rotateY(-180deg);
    backface-visibility: none;
    background-color: color('background');
    color: color('foreground');
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    opacity: 0;
    animation: sideBEnter .4s .1s forwards;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    .--cypress & {
      scroll-behavior: unset;
    }
    z-index: 1;
    .UICardSideBInner {
      display: none;
      transition: opacity .1s;
    }
    .AppPage {
      height: 100%;
    }
  }
  &.open > .UICardSideB .UICardSideBInner {
    display: block;
  }
  &.closing > .UICardSideB {
    opacity: 1;
    animation: sideBExit .3s .2s forwards;
    .UICardSideBInner {
      opacity: 0;
    }
  }

  &.open {
    > .UICardCloseButton {
      position: fixed;
      top: 1em;
      left: auto;
      right: 1em;
      opacity: 0;
      animation: UICardCloseButtonEnter .25s forwards;
    }
  }

  &[data-animation="flip"] {
    &.open {
      > .UICardCloseButton {
        left: 1em;
        right: auto;
      }
    }
  }

  &.closed {
    > .UICardCloseButton {
      animation: UICardCloseButtonExit .25s forwards;
    }
  }

}
.UICardSideAInner {
  .opening &,
  .closing &,
  .UICardOuter & {
    height: 100%;
  }
  min-height: 100%;
  transform: translate3d(0,0,0);
}
@keyframes sideBEnter {
  0% { opacity: 0 }
  10% { opacity: 0 }
  20% { opacity: 1 }
  100% { opacity: 1 }
}
@keyframes sideBExit {
  from { opacity: 1 }
  to { opacity: 0 }
}

.UICardCloseButton {
  appearance: none;
  background-color: color('foreground');
  color: color('background');
  [data-enable-blur-effect="true"] & {
    background-color: color('foreground', .5);
    backdrop-filter: blur(2em);
  }
  padding: .75em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  &:hover {
    background-color: color('foreground', .4);
  }
  [data-animation="flip"] & {
    right: auto;
    left: .75em;
  }
  .BaseIcon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@keyframes UICardCloseButtonEnter {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes UICardCloseButtonExit {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
