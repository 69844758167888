.CounsellingSessionPriceSetterForm {
  > * {
    + * {
      margin-top: .5em;
    }
  }

  .BaseToggleBox {
    align-self: start;
  }
  .BaseToggleLabel {
    > * + * {
      margin-top: 0.5em;
      line-height: 0.8em;
    }
  }
}
.CounsellingSessionPriceSetterFormPriceDisplay {
  font-size: 2.8rem;
}