@import '../../styles/base';

.ExportButton {
  @media #{$only-phone} {
    border-radius: 50%;
    .BaseButtonInner {
      border-radius: 50%;
    }
  }
  @media print {
    display: none !important;
  }

  .BaseButtonLabel a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}