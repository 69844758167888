@import '../../styles/base';

.ChatWindowVideoRoomParticipant {
  position: relative;
  background-color: color('black', .5);
  overflow: hidden;
  transform: translateZ(0);
  max-height: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 4 / 3 * 100%;
    padding-top: var(--AspectRatio, 4 / 3);
  }
  &[data-is-self="true"] {
    border: 2px solid color('primary');
  }
  &.interactable {
    cursor: pointer;
    &:hover {
      border: 2px solid color('primary');
    }
  }
  .UserIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
  &[data-video-on="false"] {
    video {
      opacity: 0;
    }
  }
  .hasOnlyOneVideoStream & {
    &[data-type="remote"] {
      height: 100%;
    }
  }
}

.ChatWindowVideoRoomParticipantInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  video {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
  
.ChatWindowVideoRoomParticipantFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  color: color('white');
  display: block;
}
.ChatWindowVideoRoomParticipantStatusDisplay {
  display: inline-block;
  background-color: color('black', .8);
  padding: 2px 5px;
  border-top-right-radius: 5px;
}
.ChatWindowVideoRoomParticipantNameTag {
  border-top-right-radius: 5px;
  background-color: color('black', .8);
  padding: 2px 5px;
  font-size: 1.4rem;
  font-weight: 500;
}