.SurveyEditorGAD7 {
	p {
		font-weight: 500;
	}
}

.SurveyEditorGAD7Question {
	> * {
		+ * {
			margin-top: .38em;
		}
	}
  + .SurveyEditorGAD7Question {
		margin-top: 1em;
  }
}