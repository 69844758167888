@import '../../styles/base';

.SearchBar {
  position: relative;
  [type="search"] {
    appearance: none;
    padding-right: 3em;
  }
  .BaseInput {
    width: 100%;
    input {
      padding-left: 1em;
      padding-right: 2.5em;
      border-radius: .5em;
    }
  }
  .SearchBarSubmitButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
}