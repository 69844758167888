@import '../../../../../../styles/base';

.CommunicationTypeSelectorOptionCard {
  border: 2px solid transparent;
  cursor: pointer;
  transition: .1s;
  &:hover {
    border-color: color('primary');
  }
  &:active {
    transform: scale(.98);
  }
  &[data-selected="true"] {
    color: contrastColor('primary');
  }
}