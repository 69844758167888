@import '../../styles/base';

.CountryDialCodeSelector {
  display: inline-flex;
  align-items: center;
  appearance: none;
  background-color: color('primary', .1);
  border-radius: .25em;
  color: inherit;
  border: 2px solid transparent;
  padding-top: .25em;
  padding-bottom: .25em;
  &:hover {
    border-color: color('primary', .3);
  }
  &:active {
    background-color: color('primary', .2);
  }
  &:focus {
    border-color: color('primary', .6);
  }
  > * {
    + * {
      margin-left: .19em;
    }
  }
  &:disabled {
    opacity: .75;
    cursor: not-allowed;
  }
}
.CountryPopupSelectorFlag {
  min-width: 1em;
  text-align: center;
}
.CountryPopupSelectorDialCode {
  opacity: .75;
  font-weight: 600;
  padding-left: 0.3em;
}

.CountryPopupSelectorDialogContent {
  height: 375px;
  overflow: auto;
  header {
    background-color: color('background');
    padding: 1em;
    position: sticky;
    top: 0;
    border-bottom: 1px solid color('primary', .1);
    display: flex;
    align-items: flex-start;
  }
}

.CountryPopupSelectorDialogContentHeaderInner {
  padding-right: 1em;
  flex: 1 1 100%;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}
.CountryPopupSelectorDialogCountryList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.CountryPopupSelectorDialogCountryButton {
  appearance: none;
  color: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  padding: .5em 1em;
  cursor: pointer;
  border-top: 1px solid color('primary', .1);
  &:hover {
    background-color: color('primary', .1);
  }
  > * {
    + * {
      margin-left: .5em;
    }
  }
}