@import '../../styles/base';

.SortToggle {
  display: inline-block;
  width: .5rem;
  height: 1.1rem;
  path {
    fill: color('foreground');
    fill-opacity: .19;
  }
  &[data-direction="asc"] {
    .SortToggleUpArrow {
      fill: color('positive');
      fill-opacity: 1;
    }
  }
  &[data-direction="desc"] {
    .SortToggleDownArrow {
      fill: color('positive');
      fill-opacity: 1;
    }
  }
}
