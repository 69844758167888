@import '../../styles/base';

.IndexDirectory {
  &.fullHeight {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;
    align-items: start;
    min-height: 100%;
    max-width: 100%;
    .nogrid & {
      display: block;
    }
    .BaseTable,
    .BaseTableTable {
      height: 100%;
    }
  }
  &[data-mode="calendar"] {
    @media #{$only-phone} {
      max-width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      .--cypress & {
        scroll-behavior: unset;
      }
    }
  }
  .CommandList {
    .BaseSelectorInlineInner {
      height: 2em;
      div {
        padding: .19em .75em;
      }
    }
    + .IndexDirectorySearchBar {
      padding-top: .5em;
    }
  }
}

.IndexDirectoryControls {
  width: 100%;
  padding-top: .5em;
  &:not(:empty) {
    margin-bottom: .5em;
  }
}

.IndexDirectoryViewModeSelector {
  text-transform: capitalize;
}

.IndexDirectoryRefreshButton {
  margin-right: .5em;
  padding: .5em;
  border-radius: 50%;
}

.IndexDirectoryContent {
  align-self: stretch;
}

.IndexDirectorySearchBarAndControlsWrapper {
  display: grid;
  grid-gap: .5em;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: auto;
  .nogrid & {
    gap: 0;
    > * {
      + * {
        margin-left: .5em;
      }
    }
  }
  @media #{$tablet} {
    grid-template-columns: minmax(19em,1fr) auto;
  }
  padding-top: .5em;
  margin-bottom: .38em;
  .BaseButton {
    padding-top: 0;
    padding-bottom: 0;
    height: 4rem;
    border-radius: .5em;
  }
}

.IndexDirectorySearchBarAndControlsLastUpdatedAndRefresher {
  display: grid;
  grid-gap: .5em;
  .nogrid & {
    display: flex;
    gap: 0;
    > * {
      + * {
        margin-left: .5em;
      }
    }
  }
  grid-template-columns: minmax(0,1fr) auto;
}
.IndexDirectorySearchBarAndControlsLastUpdated {
  background-color: color('positive', .15);
  color: color('positive');
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: .5em;
  padding: .5em .62em;
}

.IndexDirectoryFooter {
  min-height: .5em;
  &:not(:empty) {
    padding: 1em 0;
  }
}

.IndexDirectoryListEmptyListNotice {
  font-style: italic;
  text-align: center;
  font-size: 2rem;
  padding-top: 1em;
  padding-bottom: 1em;
  opacity: .5;
}

.IndexDirectoryListWrapper {
  margin-top: .5em;
}
.IndexDirectoryList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.IndexDirectoryExportButtonWrapper {
  display: flex;
  justify-content: flex-end;
}