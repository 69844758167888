@import '../../styles/base';

.LinkGroup {
  &.noUnderscore {
    a {
      text-decoration: none;
    }
  }
  &.allCaps {
    text-transform: uppercase;
    letter-spacing: .062em;
    font-weight: 700;
  }
  &.row {
    margin-left: -.19em;
    margin-right: -.19em;
    > * {
      display: inline-block;
      padding-left: .19em;
      padding-right: .19em;
    }
  }
  &.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * {
      display: inline-block;
      padding: 1em 0;
    }
  }
}