@import '../../styles/base';

.AssignmentEntryForAdmin {
  display: block;
  width: 100%;
  appearance: none;
  border: none;
  padding: .5em;
  background-color: color('primary', .1);
  border: 2px solid color('primary', .1);
  color: inherit;
  cursor: pointer;
  text-align: left;
  border-radius: .38em;
  h3 {
    font-size: inherit;
  } 
  &:hover {
    background-color: color('primary', .19);
  }
  &:active {
    background-color: color('primary', .25);
  }
  &.completed {
    background-color: color('positive', .25);
    &:hover {
      background-color: color('positive', .19);
    }
    &:active {
      background-color: color('positive', .25);
    }
  }
  .ColorTag {
    float: right;
    margin-left: .5em;
    font-size: 62%;
    text-transform: uppercase;
    font-weight: 700;
  }
  p {
    font-size: 85%;
    font-weight: 700;
    color: color('foreground', .62);
  }
}