@import '../../styles/base';

@keyframes AdminModuleEnter {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes AdminModuleEnterTablet {
  from {
    opacity: 0;
    transform: scale(1.062);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ModuleAdmin {
  .ModuleAdminInner {
    @media #{$tablet} {
      padding-left: var(--AppNavigationWidth);
    }
    [data-perf="high"].splashscreenVisible & {
      @media #{$only-phone} {
        opacity: 0;
        animation: AdminModuleEnter 1s 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      }
    }
    [data-perf="high"].splashscreenVisible.DEV & {
      @media #{$only-phone} {
        animation: AdminModuleEnter 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      }
    }
    [data-perf="high"].splashscreenVisible.--cypress & {
      @media #{$only-phone} {
        opacity: 1;
        animation: none;
      }
    }
  }
  .AppNavigation {
    color: color('white');
    @media #{$tablet} {
      background-image: linear-gradient(133deg, #17173C 3.41%, #5B599B 119%);
      bottom: 0;
    }
  }
  .AppNavigationInner {
    @media #{$only-phone} {
      background-color: color('dark');
      background-image: radial-gradient(266.67% 266.67% at 66.93% -103.7%, #17173C 3.41%, #5B599B 100%);
      background-size: 100% 10rem;
    }
    @media #{$tablet} {
      background-color: transparent;
    }
  }
}

.AdminSplashScreen {
	background: radial-gradient(234.12% 104.41% at 63.45% 100%, #009FE3 0, #12122e 100%);
  .Turn2MeLogo {
		font-size: 2.8rem;
	}
}

.ModuleAdminHomeButton {
  display: flex;
  padding: 1em;
  align-items: center;
  justify-content: center;
  background-color: color('white', .1);
  &.active {
    pointer-events: none;
  }
  svg {
    display: block;
  }
  &:hover {
    background-color: color('primary', .38);
  }
}