@import '../../styles/base';

.SessionAssignmentItem {
  position: relative;
  border-radius: .5em;
  cursor: pointer;
  &:not(.disabled) {
    &:hover {
      filter: brightness(1.1);
      box-shadow: inset 0 0 0 2px color('orange');
    }
    &:active {
      filter: brightness(.9);
    }
  }
}

.SessionAssignmentItemInner {
  padding: .5em;
  font-size: 1.4rem;
  background-color: color('primary', .1);
  .upcoming & {
    background-color: color('background', .1);
    border: 2px solid color('foreground', .075);
  }
  border-radius: .5em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  min-height: 6.2em;
  header {
    display: flex;
    div {
      flex: 1 1 auto;
      padding-right: .5em;
    }
    .BaseIcon {
      flex: 0 0 auto;
    }
  }
  .ColorTag {
    text-transform: none;
  }
}