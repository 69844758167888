@import '../../styles/base';

.InfiniteFeedLoader {
  appearance: none;
  border: 2px transparent solid;
  border-radius: .75em;
  display: flex;
  width: 100%;
  min-height: 4em;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
  padding-top: size(md);
  padding-bottom: size(md);
  background-color: color('primary', .19);
  color: color('primary');
  transform: translateZ(0);
  user-select: none;
  cursor: pointer;
  @media #{$tablet} {
    font-size: 1.8rem;
    min-height: 6em;
  }
  &.hasError {
    @include setPrimaryColor('red');
    opacity: 1;
  }
  &.done {
    @include setPrimaryColor('blueGreen');
    opacity: 1;
  }
  &.loading {
    background-color: color('primary', .38);
    pointer-events: none;
    opacity: 1;
  }
  &:focus {
    border-color: color('primary', .38);
  }
  &.invisible {
    opacity: 0 !important;
    height: 1px !important;
    border-width: 0 !important;
    min-height: unset !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}