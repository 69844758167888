@import '../../../../styles/base';

.AuthPage {
  min-height: var(--AppHeight);
  @media #{$tablet} {
    background-image: url("data:image/svg+xml,%3Csvg width='900' height='265' viewBox='0 0 900 265' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 228.412V0H900V187.47C642.857 132.163 489.864 243.496 298.803 256.425C173.823 264.882 76.1373 252.115 0 228.412Z' fill='url(%23paint0_linear)'/%3E%3Cpath d='M0 91.9393V0H900V242.059C813.807 202.554 639.266 162.33 375.658 189.625C235.199 204.168 57.4621 176.696 0 91.9393Z' fill='url(%23paint1_radial)'/%3E%3Cpath d='M0 178.851V0H900V142.219C538.339 124.436 508.294 209.084 314.605 221.947C166.27 231.799 61.7717 208.3 0 178.851Z' fill='url(%23paint2_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='11.4924' y1='149.401' x2='502.362' y2='587.777' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white' stop-opacity='0.05'/%3E%3Cstop offset='0.857584' stop-color='white' stop-opacity='0.0585758'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0.06'/%3E%3C/linearGradient%3E%3CradialGradient id='paint1_radial' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(197 -6.00001) rotate(31.2802) scale(745.344 832.356)'%3E%3Cstop stop-color='white' stop-opacity='0.08'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0.03'/%3E%3C/radialGradient%3E%3ClinearGradient id='paint2_linear' x1='82.5377' y1='-7.00458' x2='176.541' y2='404.703' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white' stop-opacity='0.09'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0.07'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}

.AuthPageInner {
  display: flex;
  flex-direction: column;
  max-width: getBreakpoint(md, md);
  margin-left: auto;
  margin-right: auto;
  padding: size(lg);
  @media #{$desktop} {
    padding-top: size(xl);
  }
  color: color('white');
  h1 {
    font-size: 3.2rem;
    font-weight: 400;
    margin-top: 1em;
    margin-bottom: .1em;
  }
  > * {
    opacity: 0;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation: contentEnter 1s $i * .1s cubic-bezier(.09, .19, 0, 1) forwards;
      }
    }
    .--cypress & {
      animation: none;
      opacity: 1;
    }
  }
  > footer {
    > * {
      + * {
        margin-top: .62em;
      }
    }
  }
  .BaseRadioGroup {
    @media #{$only-phone} {
      font-size: 1.5rem;
    }
  }
  .BaseToggleLabel {
    white-space: nowrap;
  }
  em {
    font-size: 85%;
  }
}