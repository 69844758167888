@import '../../styles/base';

.GuideVideoCardWrapper {
  // width: 100%;
  // // height: 100%;
  // border-radius: var(--CardRadius);
  // background-color: color('white');
  // overflow: hidden;
  // padding: size(sm);
  // text-align: center;
  // line-height: 1.38;
  // @extend %cardShadow;

  margin-bottom: size(md);

  .GuideVideoCard,
  video {
    min-height: 23em;

    @media #{$only-phone} {
      min-height: 16em;
    }

    @media #{$tablet} {
      // box-shadow: 0 1em 5em rgba(0, 0, 0, 0.2);
      min-height: 23em;
    }

    @media #{$desktop} {}
  }

  .GuideVideoCard {
    border-radius: var(--CardRadius);
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #222235;
    background-image: url("https://turn2me.ie/wp-content/uploads/2022/11/Screenshot-2022-11-08-at-09.45.14.png");
    transform: translate3d(0, 0, 0);

    &.removeBackgroundImg {
      background-image: none;
    }
  }

  .GuideVideoCardInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: inherit;

    position: relative;
  }
}

.GuideVideoCardTopTags {
  display: flex;
  justify-content: space-between;
}

.GuideVideoCardDismissButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.GuideVideoCardLeftTag,
.GuideVideoCardDismissButton {
  display: flex;
  align-items: center;
  color: color('white');
  font-weight: 700;
  padding: .5em .75em;
}

.GuideVideoCardLeftTag {
  background-color: color('emerald', .9);

  border-bottom-right-radius: .62em;

  >* {
    +* {
      margin-left: .38em;
    }
  }

  [data-enable-blur-effect="true"] & {
    background-color: color('emerald');
  }
  }

.GuideVideoCardDismissButton {
  padding: .5em .5em;
  user-select: none;
  cursor: pointer;
  background-color: color('red', .9);

  border-bottom-left-radius: .62em;

  >* {
    +* {
      margin-left: .38em;
    }
  }
[data-enable-blur-effect="true"] & {
  background-color: color('red');
}

&:hover {
  // opacity: color('red', .1)
  filter: brightness(1.2)
}
}

.GuideVideoCardPlayButtonCentering {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GuideVideoCardHeader {
  flex: 0 0 auto;
  color: color('white');
  font-weight: 700;
  padding: 1em;
  position: relative;
  // text-align: center;

  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: -2em;
    background-image: linear-gradient(to top, color('black') 0%, color('black', .4) 60%, color('black', 0) 100%);
  }

  @media #{$tablet} {
    padding: 1.5em;
  }

  .GuideVideoCardHeaderInner {
    position: relative;

    >* {
      +* {
        margin-top: .5em;
      }
    }
  }

  .GuideVideoCardTitle {
    padding-bottom: 1.1em;
    max-width: 16.2em;

    @media #{$tablet} {
      font-size: 3rem;
    }
  }
}

.GuideVideoCardVideoTitleWrapper {
  width: 100%;
  position: absolute;
  top: 0.5em;
  // left: 0.5em;

  .GuideVideoCardVideoTitleContainer {
    flex: 0 0 auto;
    color: color('white');
    font-weight: 700;
    position: relative;

    $base-padding: 1em;
    padding: 0.5em $base-padding 3em $base-padding;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      top: -1em;
      background-image: linear-gradient(to bottom, color('black') 0%, color('black', .4) 60%, color('black', 0) 100%);
    }
  }

  .GuideVideoCardVideoTitleInner {
    position: relative;
  }

  .GuideVideoCardVideoTitleText {
    font-size: 140%;
    color: white;
    text-shadow: 0 .1em .3em color('black', .622);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.GuideVideoCardVideoTitleTextAnimation {
  animation-name: fadeOut;
  animation-delay: 0s;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}