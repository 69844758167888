@import '../../styles/base';

.TabGroupItem {
  display: inline-flex;
  align-items: center;
  padding: .62em 1em;
  border-bottom-width: 2px;
  margin-bottom: -2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
  background-color: color('primary', .19);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1.4rem;
  + .TabGroupItem {
    margin-left: .33em;
  }
  &.active {
    background-color: color('primary');
    color: contrastColor('primary');
  }
  &.disabled, &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
  .BaseIcon {
    + * {
      margin-left: .38em;
    }
  }
}