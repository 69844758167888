@import '../../styles/base';

.SupportGroupReservationList {
  > * {
    + * {
      margin-top: .5em;
    }
  }
  .AutosuggestSearchBarResultList {
    background-color: color('background', .5);
    padding: 0;
    border-radius: .5em;
    max-height: 30em;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .AutosuggestSearchBarStateDisplay {
    background-color: color('background', .75);
    border-radius: .5em;
    padding: 1em;
  }
  .AutosuggestSearchBarResultItem {
    padding-left: 1em;
    padding-right: .62em;
  }
}
.SupportGroupReservationListInner {
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.SupportGroupReservationListSuggestionItem {
  
}