@import '../../styles/base';

.AutosuggestSearchBar {
  .SearchBar {
    z-index: 1;
  }
}
.AutosuggestSearchBarResultList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.AutosuggestSearchBarResultItem {
  display: grid;
  grid-gap: .5em;
  align-items: baseline;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-areas: 'actions inner';
  max-width: 100%;
  align-items: center;
  .nogrid & {
    display: flex;
  }
  &[data-disabled="true"] {
    cursor: not-allowed;
  }
  &[data-action-position="end"] {
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: 'inner actions';
  }
  cursor: pointer;
  + .AutosuggestSearchBarResultItem {
    border-top: var(--BorderMinor);
  }
  &:hover {
    background-color: color('primary', .1);
  }
}

.AutosuggestSearchBarResultItemInner {
  grid-area: inner;
  padding: .5em 0;
  .UserNameTag {
    overflow-wrap: break-word;
  }
}
.AutosuggestSearchBarResultItemActions {
  grid-area: actions;
  padding: .5em 0;
}
.AutosuggestSearchBarStateDisplay {
  padding: .0 1em;
  min-height: 2.5em;
  display: flex;
  align-items: center;
  opacity: .6;
  &.--error {
    color: color('red');
  }
  > * {
    + * {
      margin-left: .38em;
    }
  }
}