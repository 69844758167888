@import '../../styles/base';

@keyframes dialogEnter {
  from { transform: scale(.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}
@keyframes dialogExit {
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(1.05); opacity: 0; }
}
@keyframes dialogEnterSimple {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes dialogExitSimple {
  from { opacity: 1; }
  to { opacity: 0; }
}
@keyframes dialogBackdropEnter {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes dialogBackdropExit {
  from { opacity: 1; }
  to { opacity: 0; }
}
.DialogLayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1.5em 3em 1.5em;
  @media #{$landscape-phone} {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  @extend %colorCodedStates;
  @media print {
    display: none;
  }
}

.DialogBackdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: color('foreground', .19);
  animation: dialogBackdropEnter .38s;
  .closing & {
    animation: dialogBackdropExit .38s;
  }
  .--cypress & {
    animation-duration: 0s !important;
  }
}

.Dialog {
  position: relative;
  background-color: color('background',1,.19);
  border: 0;
  box-shadow: 0 1.5em 3em color('dark',.19);
  animation: dialogEnter .1s cubic-bezier(.38,0,0,2);
  @media (prefers-reduced-motion) {
    animation-name: dialogEnterSimple
  }
  .--cypress & {
    animation-duration: 0s !important;
  }
  max-width: 50rem;
  overflow: hidden;
  width: 90vw;
  max-height: calc(var(--AppHeight) - 4.5em);
  @media #{$landscape-phone} {
    max-height: calc(var(--AppHeight) - 2em);
  }
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  [data-enable-blur-effect="true"] & {
    background-color: color('background',.62,.19);
    background-image: linear-gradient(162deg, color('background', 1), color('background', 0) 16.2em);
    backdrop-filter: blur(2em);
  }
  @media #{$only-phone} {
    text-align: center;
    font-size: 1.4rem;
    border-radius: .38em;
  }
  @media #{$tablet} {
    width: 75vw;
    border-radius: 1em;
  }
  .closing & {
    animation: dialogExit .38s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
    @media (prefers-reduced-motion) {
      animation-name: dialogExitSimple
    }
    .--cypress & {
      animation-duration: 0s !important;
    }
  }
  code {
    overflow-wrap: break-word;
    color: inherit;
    background-color: transparent;
    padding: 0;
  }
  .ShadedBlock {
    margin-top: .38em;
    margin-bottom: .38em;
  }
}

.DialogHeader {
  padding: 1.5em 1.5em 0;
  + .DialogFooter {
    margin-top: 1.5em;
  }
  @media #{$desktop} {
    padding-right: 6.18em;
  }
}

h1.DialogHeading {
  font-family: inherit;
  font-size: 116%;
  color: color('primary');
  font-weight: 700;
  margin: 0;
}
.DialogBody {
  padding: .75em 1.5em 1.5em;
  font-family: font(ui);
  line-height: 1.19;
  @media #{$landscape-phone} {
    padding-bottom: 1em;
  }
  a {
    color: color('primary');
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  ol, ul {
    @media #{$only-phone} {
      text-align: left;
      background-color: color('primary', .08);
      padding: .5em .5em .5em 2em;
      margin-top: .5em;
      margin-bottom: .5em;
    }
    padding-left: 1.5em;
    li {
      + li {
        margin-top: .19em;
      }
    }
    + * {
      margin-top: .5em;
    }
  }
  * {
    + ol, + ul {
      margin-top: .5em;
      margin-bottom: .5em;
    }
  }
  ol {
    list-style-type: decimal;
    li {
      list-style: decimal;
    }
  }
  ul {
    list-style-type: disc;
    li {
      list-style: disc;
    }
  }
  p {
    + p {
      margin-top: .5em;
    }
  }
  * {
    + .ErrorRenderer {
      margin-top: .5em;
    }
  }
  .ErrorRenderer {
    background-color: color('alert', .1);
    padding: 1em;
    border-radius: .38em;
    font-family: font(monospace);
    font-size: 1.2rem;
  }
}

.DialogFooter {
  display: flex;
  @media #{$upto-tablet} {
    .BaseButton {
      border-width: 0;
      border-radius: 0;
      .BaseButtonInner {
        padding: 1.5em 1.38em;
      }
      &.subtle {
        + .subtle {
          margin-left: 1px;
        }
      }
    }
    > * {
      flex: 1 1 auto;
    }
  }
  @media #{$tablet} {
    justify-content: flex-end;
    padding: 0 1.3em;
    .BaseButton {
      margin: 0 .2em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .BaseButtonInner {
        padding: 1em 1.38em;
        min-width: 6.18em;
      }
    }
  }
}