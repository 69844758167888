@import '../../../../../styles/base';

.StatDataItem {
  display: block;
  padding: 1em;
  // text-align: center;
  background-color: color('background');
  border-radius: .5em;
  outline: 2px solid transparent;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
  @media print {
    background-color: transparent;
    padding: .5em;
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
  &.wide {
    grid-column-end: span 2;
  }
  &.halfWidth {
    grid-column-end: span 1;
    @media #{$desktop} {
      grid-column-end: span 3;
    }
  }
  &.halfWidthWide {
    grid-column-end: span 2;
    @media #{$desktop} {
      grid-column-end: span 3;
    }
  }
  &.halfWidth,
  &.halfWidthWide {
    .StatDataItemExplanation {
      opacity: 1;
      font-size: inherit;
    }
  }
  &.interactable {
    &:hover {
      background-color: color('secondary', .15);
      outline-color: color('secondary');
    }
  }
  &[data-background-intensity="stronger"] {
    background-color: color('secondary', .1);
    &.interactable {
      &:hover {
        background-color: color('secondary', .25);
      }
    }
  }
  &[data-background-intensity="solid"] {
    background-color: color('secondary');
    color: contrastColor('secondary');
    &.interactable {
      &:hover {
        background-color: color('secondary');
        outline-color: color('primary');
        filter: brightness(1.1);
      }
    }
  }
}

.StatDataItemData {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
  color: color('primary');
  font-weight: 700;
  @media #{$tablet} {
    font-size: 2rem;
  }
  @media #{$desktop} {
    font-size: 2.4rem;
  }
  @media #{$desktop-lg} {
    font-size: 3rem;
  }
  .Right,
  small {
    font-style: inherit;
    font-weight: inherit;
    opacity: inherit;
    color: color('secondary');
  }
}

.StatDataItemTitle {
  color: inherit;
  font-size: inherit;
  opacity: .85;
  margin-bottom: .5em;
  font-weight: 700;
}

.StatDataItemExplanation {
  &.plain-text {
    opacity: .75;
    font-size: 87.5%;
  }
  &:not(.plain-text) {
    p {
      opacity: .75;
      font-size: 87.5%;
    }
  }
  .PercentageBar {
    margin-top: .38em;
    margin-bottom: .38em;
  }
}