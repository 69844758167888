@import '../../styles/base';

@keyframes OverlayEnterFadeIn {
  from { opacity: 0; }
  to { opacity: 1 }
}
@keyframes OverlayExitFadeOut {
  from { opacity: 1; }
  to { opacity: 0 }
}
@keyframes OverlaySheetEnter {
  from { transform: translateY(100vh); opacity: 1; }
  to { transform: none; opacity: 1; }
}
@keyframes OverlaySheetExit {
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(100vh); opacity: 1; }
}
@keyframes OverlayCardEnter {
  from { transform: scale(.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}
@keyframes OverlayCardExit {
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(.95); opacity: 0; }
}
@keyframes OverlayBackdropEnter {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes OverlayBackdropExit {
  from { opacity: 1; }
  to { opacity: 0; }
}

.OverlayView {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--AppHeight);
  pointer-events: none;
  @media print {
    padding-top: 0 !important;
  }
  &[data-appearance="sheet"] {
    @media #{$tablet} {
      padding-top: size(xl);
      .AppPageHeaderInner {
        padding-top: size(md);
      }
    }
  }
  &[data-appearance="card"] {
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$tablet} {
      padding-bottom: size(lg);
    }
    .Overlay {
      max-height: calc(var(--AppHeight) - 3em);
      @media #{$tablet} {
        max-height: calc(var(--AppHeight) - 7em);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
    @media #{$only-phone} {
      padding-left: .62em;
      padding-right: .62em;
    }
    .AppPageHeaderInner {
      padding-top: size(md);
    }
  }
}

.OverlayBackdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--AppHeight);
  background-color: color('foreground',.19);
  animation: OverlayBackdropEnter .38s;
  pointer-events: initial;
  .closing & {
    animation: OverlayBackdropExit .38s forwards;
  }
  .--cypress & {
    animation-duration: 0s;
  }
}

.Overlay {
  background-color: color('background');
  color: color('foreground');
  transform: translateZ(0);
  display: flex;
  overflow: hidden;
  pointer-events: initial;
  opacity: 0;
  .OverlayView[data-appearance="sheet"] & {
    height: 100%;
    .AppPage {
      height: 100%;
    }
    animation: OverlaySheetEnter 1s cubic-bezier(0, 1, 0, 1) forwards;
    @media (prefers-reduced-motion) {
      animation: OverlayEnterFadeIn .38s forwards;
    }
  }
  .OverlayView[data-appearance="sheet"].closing & {
    animation: OverlaySheetExit .38s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
    @media (prefers-reduced-motion) {
      animation: OverlayExitFadeOut .38s forwards;
    }
  }
  .OverlayView[data-appearance="card"] & {
    .AppPage {
      max-height: var(--AppHeight);
    }
    animation: OverlayCardEnter .25s cubic-bezier(.19,2,.37,1) forwards;
    @media (prefers-reduced-motion) {
      animation: OverlayEnterFadeIn .38s forwards;
    }
    border-radius: 1em;
    .AppPageContent {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .OverlayView[data-appearance="card"].closing & {
    animation: OverlayCardExit .38s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
    @media (prefers-reduced-motion) {
      animation: OverlayExitFadeOut .38s forwards;
    }
  }
  .--cypress & {
    animation-duration: 0s !important;
  }
  width: 100%;
  @media #{$tablet} {
    width: 90%;
    max-width: 62rem;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1em 4em color('dark',0.2);
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
  @media #{$desktop} {
    border-top-left-radius: 1.38em;
    border-top-right-radius: 1.38em;
  }
  .AppPage {
    border-radius: inherit;
  }
  .AppPageHeader {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    @media #{$tablet} {
      left: 0;
    }
  }
  .AppPageTitle {
    font-size: 1.8rem;
    @media #{$tablet} {
      font-size: 2.4rem;
    }
  }
  .ScrollbarsCustom-Track {
    top: 20px !important;
    bottom: 0 !important;
  }
}

.OverlayInner {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: inherit;
  .AppPage {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .AppPageContent {
    > .UIBlock {
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: size(md);
      }
    }
  }
  > .ErrorBoundary {
    margin: size(md);
    .DefaultErrorFallback {
      padding: size(md);
    }
  }
}