@import '../../styles/base';

.InfoDisplayItemContent {
  font-size: 1.8rem;
  color: color('primary');
  font-weight: 500;
}

.InfoDisplayItemLarge {
  .InfoDisplayItemContent {
    @media #{$tablet} {
      font-size: 1.8rem;
    }
  }
}