@import '../../styles/base/index';

.PseudoLink {
  appearance: none;
  display: inline;
  color: color('primary');
  text-decoration: underline;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-weight: 600;
  &.inheritColor {
    color: inherit;
  }
  &:hover {
    filter: brightness(1.1);
  }
}