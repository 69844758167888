@import '../../styles/base';

.SupportGroupReservationListItem {
  cursor: pointer;
  background-color: color('primary', .1);
  display: flex;
  align-items: center;
  border-radius: .38em;
  &:hover {
    background-color: color('primary', .15);
  }
  &:active {
    background-color: color('primary', .19);
  }
  &.revoked {
    @include setPrimaryColor('median');
  }
}
.SupportGroupReservationListItemHeader {
  padding: .5em;
  flex: 1 1 auto;
  h3 {
    font-size: inherit;
    font-weight: inherit;
  }
  .UsernameRenderer {
    font-weight: 700;
  }
}
.SupportGroupReservationListItemControls {
  padding: .5em;
  flex: 0 0 auto;
  color: color('primary');
}
.SupportGroupReservationListItemKeyworkerInfo {
  font-size: 1.4rem;
  font-weight: 600;
  &.--error {
    color: color('red');
  }
}