@import '../../styles/base';

.ServiceCard {
  // min-width: 22.5rem;
  .UICardSideA {
    background-color: color('primary');
  }
  .UICardSideAInner {
    color: contrastColor('primary');
    padding: 1em;
    height: 20rem;
    header {
      display: flex;
    }
    h3 {
      font-size: inherit;
      flex: 1 1 auto;
      margin-right: .5em;
    }
    .BaseIcon {
      width: 1.3rem;
      height: 1.3rem;
      flex: 0 0 auto;
    }
  }
}