@import '../../../../styles/base';

.CounsellorApplicationsOverviewSection {
  padding: 1em;
  background-color: color(blueGreen, .1);
  h3 {
    font-size: 1.8rem;
    color: color(primary);
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
}

.CounsellorApplicationsOverviewSectionHeader {
  display: flex;
  align-items: flex-start;
}
.CounsellorApplicationsOverviewSectionHeaderInner {
  flex: 1 1 auto;
  margin-right: 1em;
  > p {
    font-weight: 500;
    opacity: .85;
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
}
.CounsellorApplicationsOverviewSectionHeaderControls {
  flex: 0 0 auto;
}