@import '../../styles/base';

.BasePagination {
  background-color: color('primary', .19);
  padding: .25em 1em;
  border-radius: 1em;
  display: flex;
  max-width: 100%;
  button {
    appearance: none;
    background-color: transparent;
    border: none;
    color: color('foreground', .85);
    font-weight: 500;
    transition: .25s ease;
    cursor: pointer;
    padding: .19em;
    border-radius: 1em;
    text-transform: uppercase;
    font-size: 87.5%;
    letter-spacing: .03em;
    font-weight: 600;
    &:hover {
      transition: .05s;
      background-color: color('background');
    }
    &:active {
      transition: 0s;
      background-color: color('primary', .19);
    }
    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }
    &.active {
      color: color('primary');
      background-color: color('background');
    }
    &.BasePaginationPrevButton,
    &.BasePaginationNextButton {
      padding-left: .62em;
      padding-right: .62em;
    }
  }
}

.BasePaginationPageList {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  button {
    flex: 1 0 auto;
    max-width: 4em;
    min-width: auto;
    margin-left: .1em;
    margin-right: .1em;
  }
}