@import '../../styles/base';

.TwoColumnViewSidebarItem {
  &.active {
    background-color: color('primary');
    color: contrastColor('primary');
    &:active {
      filter: brightness(.9);
      background-color: color('primary');
      color: contrastColor('primary');
    }
  }
}