@import '../../../../styles/base';

.ThoughtEntryForAdmin {
  background-color: color('background');
  color: color('foreground');
  border-radius: .5em;
  border: 1px solid color('primary', .38);
  .CommentComposer {
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
  }
  &.private {
    @include setBackgroundColor('dark');
    @include setForegroundColor('white');
    background-color: color('background', .9);
    color: color('foreground');
  }
  .CommentComposer {
    overflow: hidden;
    textarea.BaseInputTextarea, 
    button.BaseButton.BaseButton--default.md {
      border-radius: 0;
    }
  }
  &[data-emotion="1"] { @include setPrimaryColor('orange') }
  &[data-emotion="2"] { @include setPrimaryColor('blueGreen') }
  &[data-emotion="3"] { @include setPrimaryColor('skyblue') }
  &[data-emotion="4"] { 
    @include setPrimaryColor('blue');
    &.private {
      @include setPrimaryColor('brighterBlue');
    }
  }
  &[data-emotion="5"] { @include setPrimaryColor('steel') }
  .CommentEntry .CommentEntryBody {
    display: block;
    margin-top: .5em;
    margin-bottom: .5em;
  }
  .CommentList {
    padding-top: .38em;
    padding-bottom: .38em;
    > * {
      padding: .62em 1em;
      + * {
        border-top: 1px solid color('primary', .3);
      }
    }
  }
  &.hidden {
    border: 1px dashed color('foreground', .5);
    > * {
      opacity: .62;
      transition: opacity .1s;
    }
    &:hover {
      > * {
        opacity: 1;
      }
    }
  }
}

.ThoughtEntryForAdminHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: color('primary', .19);
  > * {
    padding: 1em;
  }
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  .ThoughtTypeRenderer {
    cursor: pointer;
  }
}

h3.ThoughtEntryForAdminTitle {
  font-size: inherit;
  // color: color('foreground');
  font-weight: 400;
  // strong {
  //   font-weight: inherit;
  // }
  > * {
    vertical-align: middle;
    + * {
      // &:before {
      //   content: " • ";
      // }
      margin-left: .38em;
    }
  }
}

.ThoughtEntryForAdminFooter {
  border-top: 1px solid color('primary', .2);
  padding: 1em;
  .ThoughtControlsSet {
    .BaseSpacer {
      width: .5em;
    }
  }
}

.ThoughtEntryForAdminContent {
  display: grid;
  padding: 1em;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto auto;
  grid-gap: .5em;
  @media #{$tablet} {
    grid-gap: 1em;
  }
  .nogrid & {
    display: block;
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
  .BaseLabel {
    color: color('primary');
  }
}

.ThoughtEntryForAdminContentBody {
  grid-column: span 2;
  &[data-length="short"] {
    font-size: 1.8rem;
    @media #{$tablet} {
      font-size: 2.2rem;
    }
  }
  &[data-length="long"] {
    font-size: 1.6rem;
    @media #{$tablet} {
      font-size: 1.8rem;
    }
  }
}

.ThoughtEntryForAdminControls {
  display: inline-flex;
  align-items: center;
  > * {
    + * {
      margin-left: .62em;
    }
  }
}

.ThoughtEntryForAdminCommentListContainer {
  // padding: 1em;
  border-top: 2px solid color('primary', .19);
}

.ThoughtEntryForAdminContentBodyOriginal {
  font-style: italic;
  opacity: .75;
  font-size: 1.4rem;
  margin-top: .5em;
  div {
    display: inline;
  }
}