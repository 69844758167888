@import '../../styles/base/index';

.RatingInput {
  display: inline-flex;
  &.inverted {
    > .RatingInputStar {
      color: color('white', .1);
      &.isFull,
      &:hover {
        color: color('yellow', .9);
      }
    }
  }
  > .RatingInputStar {
    color: color('primary', .1);
    &.isFull,
    &:hover {
      color: color('yellow', .9);
    }
    + .RatingInputStar {
      margin-left: .25em;
    }
  }
}