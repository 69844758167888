@import '../../../../styles/base';

.FlaggableItemManager {
  background-color: color('primary', .1);
  border-radius: .5em;
  padding: .5em;
  @media #{$tablet} {
    padding: .75em;
  }
  margin-bottom: .5em;
  border-radius: .5em;
  h3 {
    font-size: inherit;
  }
  &.safe {
    background-color: color('green', .1);
  }
  &.unsafe {
    background-color: color('red', .1);
    h3 {
      color: color('alert');
    }
  }
  code {
    background-color: color('orange', .9);
    color: contrastColor('orange');
    padding-left: .19em;
    padding-right: .19em;
    border-radius: .19em;
  }
}
.FlaggableItemManagerInner {
  > * {
    + * {
      margin-top: .5em;
    }
  }
}
.FlagReporterUserNametagList {
  padding: 0;
  margin-left: -1px;
  margin-right: -1px;
  li {
    display: inline-block;
    background-color: color('alert', .19);
    padding: .5em .75em;
    border-radius: 2px;
    margin: 1px;
    cursor: pointer;
    border-radius: .2em;
    &:hover {
      background-color: color('alert', .25);
    }
  }
}
.FlaggableItemManagerSelectorSet {
  display: flex;
  @media #{$only-phone} {
    .compact & {
      justify-content: center;
    }
  }
  > * {
    flex: 0 0 auto;
    + * {
      margin-left: .5em;
    }
  }
  .BaseButton {
    height: 3em;
    @media #{$tablet} {
      height: 3.2em;
    }
  }
}
.FlaggableItemManagerIsSafeSelector {
  width: 8em;
  @media #{$tablet} {
    width: 12em;
  }
}