@import '../../styles/base';

.OTPVerification {
  .BaseInput input {
    font-size: 2.2rem;
  }
  h1 {
    span {
      @media #{$only-phone} {
        display: block;
      }
    }
  }
  h2 {
    font-size: 3.2rem;
  }
  ol {
    padding-left: 2em;
    counter-reset: item;
    list-style-type: none;
    li:before {
      content: '1.' counter(item, decimal) '. ';
      counter-increment: item;
      font-style: bold;
    }

    > * + * {
      margin-top: .5em;
    }
  }

  .ButtonBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: .5em;
  }

  .VerificationCodeField {
    @include setPrimaryColor('blueGreen');
    color: color('blueGreen');
    input {
      letter-spacing: .5em;
      @media #{$tablet} {
        padding-left: 1em;
        letter-spacing: 1em;
      }
    }
  }
}