@import '../../../../styles/base';

.PermissionGroupManager {
  background-color: color('primary', .1);
  border: 2px solid color('primary', .3);
  border-radius: .5em;
  overflow: hidden;
  + * {
    margin-top: size(sm);
  }
}

.PermissionGroupManagerHeader {
  display: flex;
  background-color: color('primary', .1);
  padding: 1em;
  + * {
    margin-top: .5em;
  }
  h3 {
    margin-bottom: .5em;
    font-size: inherit;
    font-weight: 800;
  }
  p {
    max-width: 48em;
    font-size: 1.4rem;
    opacity: .8;
  }
}
.PermissionGroupManagerHeaderInner {
  flex: 1 1 auto;
  padding-right: 1em;
}
.PermissionGroupManagerHeaderEndSlot {
  flex: 0 0 auto;
}

.PermissionGroupManagerToggleList {
  padding: .5em 1em 1em;
  transform: translateZ(0);
  .BaseToggle {
    display: inline-flex;
    width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1 1 100%;
    @media #{$tablet} {
      columns: 1;
      flex: 0 1 50%;
    }
    @media #{$desktop} {
      columns: 1;
      flex: 0 1 33.33%;
    }
  }
}