@import '../../styles/base';

.PaymentEntry {
  background-color: color('primary', .1);
  border: 2px solid color('primary', .38);
  padding: .5em 1em;
  border-radius: .5em;
  h3 {
    color: color('primary');
    margin-top: 0;
  }
  .BaseHeading {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}