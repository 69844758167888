@import '../../styles/base';

.CounsellingJourneyEntry {
  display: block;
  width: 100%;
  appearance: none;
  color: inherit;
  border: none;
  position: relative;
  background-color: color('white');
  border-radius: var(--CardRadius);
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  transition: transform .1s;
  padding: .75em 1em 1em;
  border: 2px solid transparent;
  @extend %cardShadow;
  &:hover,
  &:focus {
    box-shadow: 0 0 .1em .1em color('primary');
  }
  &:active {
    transform: scale(.99);
  }
  &.completed {
    border-color: color('green');
  }
  .ShadedBlock {
    margin-top: .5em;
    h3 {
      font-size: 1.6rem;
    }
  }
  &.archived {
    border-color: color('median', .2);
  }
  &.ongoing {
    border: 2px solid color('primary');
    background-image: linear-gradient(133deg, contrastColor('primary', .1), contrastColor('primary', 0));
  }
}

.CounsellingJourneyEntryHeader {
  display: flex;
  align-items: flex-start;
  z-index: 1;
}

.CounsellingJourneyEntryHeaderInner {
  flex: 1 1 auto;
  .BaseIcon {
    color: color('primary');
  }
}

.CounsellingJourneyEntryTitle {
  font-size: 1.8rem;
  margin-top: .38em;
  @media #{$tablet} {
    font-size: 2.2rem;
    font-weight: 500;
  }
}
.CounsellingJourneyEntryMeta {
  margin-top: .19em;
}

.CounsellingJourneyEntryTopRightTag {
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: .19em .62em;
  border-radius: 1em;
  color: color('primary');
  background-color: color('primary', .1);
}

.CounsellingJourneyEntryOngoingContent {
  margin-top: .5em;
  h3 {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: color('primary');
    font-weight: 800;
    margin-bottom: .5em;
  }
}

.CounsellingJourneyEntryOngoingContentPendingGoalSheetNotice {
  display: flex;
  align-items: center;
  .BaseIcon {
    margin-right: .5em;
    flex: 0 0 auto;
  }
}