@import '../../styles/base';

.UsernameRenderer {
	white-space: nowrap;
}
.UsernameRendererColorDot {
	display: inline-block !important;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9C9AAA;
	border: 1px solid color('foreground', .1);
}