@import '../../styles/base';

.BaseCalendar {
  position: relative;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
  &.hasOptionsPanel {
    display: grid;
    grid-template-columns: minmax(20rem, 13.8vw) auto;
    grid-template-areas: 'options inner';
    .nogrid & {
      display: flex;
    }
  }
  &.fullHeight {
    min-height: 100%;
  }
  &.fitInnerToAvailableHeight {
    height: 100%;
    max-height: 100%;
    flex: 1 1 auto;
    .BaseCalendarInner {
      height: 100%;
    }
  }
  th, td {
    text-align: left;
    @media #{$tablet} {
      text-align: right;
    }
    + th,
    + td {
      border-left: var(--BorderMinor);
    }
  }
  th {
    overflow: hidden;
    padding: .5em;
    @media #{$tablet} {
      // padding: .5em .75em;
      text-transform: uppercase;
      font-size: 75%;
      font-weight: 800;
      padding-top: .75em;
      padding-bottom: .75em;
    }
    // color: color('primary');
  }
  td {
    padding: 0;
  }
  tr {
    + tr {
      th, td {
        border-top: var(--BorderMinor);
      }
    }
  }
}

.BaseCalendarOptionsPanel {
  grid-area: options;
  .nogrid & {
    flex: 0 0 20rem;
  }
}

.BaseCalendarInner {
  grid-area: inner;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas: 'header' 'body';
  min-height: 100%;
  .nogrid & {
    flex: 1 1 auto;
    display: block;
  }
  // @media #{$only-phone} {
  //   grid-gap: .5em;
  // }
}

.BaseCalendarHeader {
  grid-area: header;
  position: sticky;
  top: 0;
  background-color: color('background');
  z-index: 1;
}
.BaseCalendarBody {
  grid-area: body;
  min-height: 100%;
}