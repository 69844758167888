@import '../../../../../styles/base';

.VerifyEmailCard {
  appearance: none;
  border: none;
  width: 100%;
  background-image: radial-gradient(122.97% 151.94% at 53.51% 104.08%, rgba(0, 172, 211, 0.7) 0%, #004bac 100%);
  color: white;
  padding: 1.38em 1.62em 1.62em 1.38em;
  border-radius: 1em;
  text-align: center;
  cursor: pointer;
  @extend %cardShadow;
  &:hover,
  &:focus {
    box-shadow: 0 0 .1em .1em color('orange');
  }
  h3 {
    font-size: 2.4rem;
  }
  P {
    margin-top: .5em;
    font-weight: 500;
  }
}