@import '../../styles/base';

.EmotionIcon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &[data-interactable="true"] {
    .EmotionIconIcon {
      border: 3px transparent solid;
      transition: .1s;
      &:active {
        transform: scale(.97);
      }
    }
  }
  svg {
    display: block;
  }
  &[data-selected="true"] {
    .EmotionIconIcon {
      border-color: color('primary');
      transform: scale(1.1);
    }
  }
  &[data-not-selected="true"] {
    .EmotionIconIcon {
      opacity: .5;
      transform: scale(.9);
    }
  }
  &[data-emotion="1"] {
    @include setPrimaryColor('red');
  }
  &[data-emotion="2"] {
    @include setPrimaryColor('blueGreen');
  }
  &[data-emotion="3"] {
    @include setPrimaryColor('skyblue');
  }
  &[data-emotion="4"] {
    @include setPrimaryColor('blue');
  }
  &[data-emotion="5"] {
    @include setPrimaryColor('steel');
  }
  &[data-drop-shadow="true"] {
    background-color: color('background');
    box-shadow: 0 .1em .3em rgba(0,0,0,0.19);
  }
}

.EmotionIconIcon {
  border-radius: 50%;
}

.EmotionIconLabel {
  text-transform: uppercase;
  font-weight: 800;
  margin-top: .5em;
  font-size: 1.2rem;
}