.Turn2MeLogo {
  display: block;
  text-transform: lowercase;
  font-size: 2.2rem;
  font-weight: 900;
  &[data-version="full"] {
    font-size: .62rem;
  }
  svg {
    width: 20em;
    height: 5.4em;
    display: block;
    fill: currentColor;
  }
}
.Turn2MeLogoAreaTag {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 800;
  opacity: .38;
}