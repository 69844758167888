@import '../../styles/base';

.AppPageContentFooter {
  @extend %paddable;
  &.has-end-slot {
    display: grid;
    grid-gap: .5em;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: center;
    .nogrid & {
      display: flex;
    }
  }
  &.shaded {
    background-color: color('primary', .1);
  }
}

.AppPageContentFooterInner {
  .nogrid & {
    flex: 1 1 auto;
  }
}