@import '../../styles/base';

.OverlaySupportGroupManager {
  &.young-people {
    .AppPageHeaderInner {
      background-color: color(yellow, .19);
    }
  }
  &.for-front-line-workers {
    .AppPageHeaderInner {
      background-color: color(aqua, .19);
    }
  }
  &.archived {
    .AppPageHeaderInner {
      background-color: color(median, .05);
    }
  }
  &.young-people,
  &.for-front-line-workers,
  &.archived {
    .AppPageContent {
      padding-top: 1em;
    }
  }
  background-image: linear-gradient(to bottom, color('primary', 0.19), color('primary', 0) 10em);
}

.OverlaySupportGroupManagerInner {
  display: grid;
  grid-gap: .62em;
  .nogrid & {
    display: flex;
    gap: 0;
  }
  .BaseHeading {
    color: color('primary');
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 800;
  }
  > * {
    .OverlaySupportGroupManager[data-from-tablet="true"] & {
      background-color: color('primary', .1);
      border-radius: .75em;
      padding: 1em; 
    }
  }
  .OverlaySupportGroupManager[data-from-tablet="true"] & {
    grid-gap: 1em;
    grid-template-columns: 1fr 37.5rem;
    align-items: start;
    .nogrid & {
      grid-gap: 0;
    }
  }
}

.OverlaySupportGroupManagerInnerAside {
  .nogrid & {
    @media #{$tablet} {
      flex: 0 0 37.5rem;
      width: 37.5rem;
      margin-left: 1em;
    }
  }
}

.OverlaySupportGroupManagerPreviewWrapper {
  @media #{$only-phone} {
    background-color: color('primary', .1);
    border-radius: 1.38em;
    padding: .75em;
  }
}

.SupportGroupManagerKeyWorkerInfo {
  color: color('orange', 1, -.1);
}