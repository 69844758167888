@import '../../../../styles/base';

.OverlayApplicationManager {
  .CounsellingSessionsCalendar {
    border-top: var(--BorderMinor);
    border-bottom: var(--BorderMinor);
  }
  @include setPrimaryColor('brightPurple');
  &[class*="young-people"] {
    .AppPageHeaderInner {
      background-color: color('yellow', .2);
    }
  }
  &.couples {
    .AppPageHeaderInner {
      background-color: color('firebrick', .15);
    }
  }
  &.rejected {
    .AppPageHeaderInner {
      background-color: color('red', .25);
    }
  }
  &.completed {
    border-top: 3px solid color('green');
  }
  &.archived {
    border-top: 3px solid color('median', .62);
     .AppPageHeaderInner {
      background-color: color('median', .1);
    }
  }
  .AppPageAfterTitle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: .5em;
  }
  .BaseCalendarDayInner {
    min-height: 3em;
  }
  .UIBlockTitle {
    color: color('primary');
  }
  .AppPageContent {
    > .UIBlock {
      &:first-child {
        padding-top: 1em;
      }
    }
  }
}

.OverlayApplicationManagerInner {
  .UIBlock {
    padding-top: .5em;
    padding-bottom: 1em;
    border-top: 1px solid color('foreground', .2);
  }
  &.split {
    @media #{$desktop} {
      display: grid;
      grid-template-columns: 2fr minmax(50rem, 2fr);
      height: 100%;
      grid-gap: 2px;
      background-color: color('foreground', .1);
      .nogrid & {
        display: flex;
      }
      > * {
        background-color: color('background');
        flex: 0 0 50%;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        .--cypress & {
          scroll-behavior: unset;
        }
      }
    }
  }
}

.OverlayApplicationManagerInnerFrame {
  &.--email {
    .UIBlock {
      padding-top: 0;
      padding-left: size(md);
      padding-right: size(md);
    }
    .UIBlockHeader {
      padding-top: size(md);
    }
  }
}


.OverlayApplicationManagerRejectionNotice {
  .BodyCopyRenderer {
    margin-top: .5em;
    background-color: color('red', .1);
    padding: 1em;
    border-radius: .5em;
  }
}