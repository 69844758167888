@import '../../styles/base';

.MenuToggle {
  position: relative;
  user-select: none;
  > .BaseIcon {
    width: 1.8rem;
    height: 1.8rem;
    padding: .6rem;
    box-sizing: content-box;
  }
}

@keyframes MenuToggleMenuMobileBackdropEnter {
  from { opacity: 0 } to { opacity: 1 }
}
@keyframes MenuToggleMenuMobileBackdropExit {
  from { opacity: 1 } to { opacity: 0 }
}
@keyframes MenuToggleMenuMobileEnter {
  from { transform: translateY(150%) }
  to {transform: translateY(0) }
}
@keyframes MenuToggleMenuMobileExit {
  from { transform: translateY(0) }
  to {transform: translateY(150%) }
}
@keyframes MenuToggleMenuEnter {
  from { opacity: 0 }
  to { opacity: 1  }
}
@keyframes MenuToggleMenuExit {
  from { opacity: 1 }
  to { opacity: 0  }
}

.MenuToggleMenu {
  position: fixed;
  background-color: transparent;
  user-select: none;
  @media #{$only-phone} {
    font-size: 1.6rem;
    &:before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: color('dark', .19);
      display: block;
      animation: MenuToggleMenuMobileBackdropEnter .3s forwards;
      pointer-events: none;
      .exiting & {
        animation: MenuToggleMenuMobileBackdropExit .25s forwards;
      }
    }
  }
  // @media #{$tablet} {
  //   border-radius: .38em;
  // }
  ul {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 16.2rem;
    border-radius: .5em;
    overflow: hidden;
    padding-top: .38em;
    padding-bottom: .38em;
    .exiting & {
      pointer-events: none;
    }
    @media #{$only-phone} {
      position: fixed;
      bottom: .62em;
      left: .62em;
      right: .62em;
      animation: MenuToggleMenuMobileEnter .25s cubic-bezier(0, .62, .38, 1) forwards;
      background-color: color('background');
      [data-enable-blur-effect="true"] & {
        background-color: color('background', .8);
        backdrop-filter: blur(1em);
      }
      .exiting & {
        animation: MenuToggleMenuMobileExit .25s cubic-bezier(0, .62, .38, 1) forwards;
      }
    }
    @media #{$tablet} {
      animation: MenuToggleMenuEnter .162s;
      box-shadow: 0 1em 2.5em color('dark',.19);
      background-color: color('background');
      .exiting & {
        animation: MenuToggleMenuExit .1s forwards;
      }
    }
  }
  li {
    @extend %colorCodedStates;
    &[class*=state-] {
      color: color('primary');
    }
    @media #{$only-phone} {
      + li {
        border-top: color('median', .1) 1px solid;
      }
    }
  }
  button {
    width: 100%;
    display: flex;
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0 solid transparent;
    color: inherit;
    padding: .75em 1em;
    font-weight: 500;
    @media #{$tablet} {
      cursor: pointer;
      padding: .5em .75em .5em .5em;
      &:hover {
        background-color: color('primary', .1);
      }
    }
    .BaseIcon {
      flex: 0 0 auto;
      margin-right: .5em;
    }
    span {
      flex: 1 1 auto;
      text-align: left;
    }
  }
}