@import '../../styles/base';

.ThoughtControlsSet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -.62em;
  margin-left: -.38em;
  margin-bottom: -.62em;
  > * {
    display: flex;
    align-items: center;
  }
  &.disableInteractions {
    pointer-events: none;
  }
}

.ThoughtControlsSetIconButtonList {
  > * {
    + * {
      margin-left: .5em;
    }
  }
}

.ThoughtControlsSetFlagCounter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: color('red', .8);
  color: contrastColor('red');
  font-weight: 800;
  text-transform: uppercase;
  font-size: 75%;
  padding: .1em .5em;
  border-radius: 1em;
}