@import '../../styles/base';

.OverlayCounsellingSessionManager {
  .AppPageBeforeTitle {
    display: flex;
    align-items: center;
  }
  .UIBlock.spaceChildren {
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
  .ShadedBlockInner {
    > h4 {
      text-transform: uppercase;
      font-weight: 900;
    }
  }
}
