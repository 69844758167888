@import '../../../../../styles/base';

.StatDataSetStateDisplayer {
  display: inline-flex;
  align-items: center;
  height: 1.5em;
  padding-left: .62em;
  padding-right: .62em;
  border-radius: 3px;
  font-weight: 700;
  color: color('positive');
  background-color: color('positive', .19);
  @media #{$only-phone} {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.2rem;
  }
  > * {
    + * {
      margin-left: .5em;
    }
  }
  &[data-status="loading"] {
    color: color('attention');
    background-color: color('attention', .19);
  }
  &[data-has-error="true"] {
    color: color('alert');
    background-color: color('alert', .19);
  }
  @media print {
    display: none;
  }
}