.BaseButtonGroup {
  margin-left: -.19em;
  > .BaseButton {
    display: inline-flex;
    vertical-align: middle;
    margin-left: .19em;
    margin-right: .19em;
  }
  &.flex {
    display: flex;
    > * {
      flex: 1 1 0;
    }
  }
}