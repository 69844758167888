@import '../../styles/base';

.ThoughtEditor {
  &.standalone {
    .EmotionSelector {
      max-width: unset;
      justify-content: center;
      background-color: color('primary');
      border-radius: .38em;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
  footer {
    padding-top: 1em;
  }
  &[data-emotion="1"] {
    @include setPrimaryColor('orange');
    &.standalone {
      .EmotionSelector {
        background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(240, 213, 147, 0.95) 0%, rgba(243, 218, 126, 0) 66.67%);
      }
    }
  }
  &[data-emotion="2"] {
    @include setPrimaryColor('blueGreen');
    &.standalone {
      .EmotionSelector {
        background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(44, 193, 204, 0.95) 0%, rgba(182, 251, 255, 0) 66.67%);
      }
    }
  }  
  &[data-emotion="3"] {
    @include setPrimaryColor('skyblue');
    &.standalone {
      .EmotionSelector {
        background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(31, 203, 233, 0.95) 0%, rgba(26, 115, 218, 0) 66.67%);
      }
    }
  }
  &[data-emotion="4"] {
    @include setPrimaryColor('blue');
    &.standalone {
      .EmotionSelector {
        background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(212, 182, 236, 0.95) 0%, rgba(239, 226, 241, 0) 66.67%);
      }
    }
  }
  &[data-emotion="5"] {
    @include setPrimaryColor('dark');
    &.standalone {
      .EmotionSelector {
        background-image: radial-gradient(506.39% 150.49% at 50% 111.05%, rgba(112, 154, 189, 0.95) 0%, rgba(40, 244, 255, 0.014) 66.67%);
      }
    }
  }
}

.ThoughtEditorTextareaFieldSet {
  .ThoughtEditor.standalone & {
    > * {
      + * {
        padding-top: .5em;
      }
    }
  }
}

.ThoughtEditorPrivateToggleContainer {
  margin-top: .5em;
  .BaseToggle {
    display: inline-flex;
    .BaseToggleLabel {
      white-space: nowrap;
    }
  }
}

.ThoughtEditorFooterButtonGroup {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  > * {
    + * {
      margin-right: .5em;
    }
  }
  @media #{$only-phone} {
    > * {
      flex: 1 1 auto;
    }
  }
}