.SurveyEditorPHQ9 {
	p {
		font-weight: 500;
	}
}

.SurveyEditorPHQ9Question {
	> * {
		+ * {
			margin-top: .38em;
		}
	}
  + .SurveyEditorPHQ9Question {
		margin-top: 1em;
  }
}