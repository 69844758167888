@import '../../styles/base';

:root {
  --AppNavigationWidth: 100vw;
  --AppNavigationHeight: 6rem;
  @media #{$tablet} {
    --AppNavigationWidth: 6rem;
    --AppNavigationHeight: 100vh;
  }
}
.AppNavigation {
  position: fixed;
  top: calc(var(--AppHeight) - var(--AppNavigationHeight));
  left: 0;
  backface-visibility: hidden;
  transform: translateZ(0);
  @media #{$only-phone} {
    right: 0;
  }
  @media #{$tablet} {
    top: 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    text-align: center;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media print {
    display: none;
  }
  user-select: none;
  [data-perf="high"] & {
    &:before {
      content: '';
      background-image: linear-gradient(to top, color('black', .19), color('black', 0));
      @media #{$tablet} {
        background-image: linear-gradient(to right, color('black', .19), color('black', 0));
      }
      position: absolute;
      top: -1em;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }
}
.AppNavigationInner {
  position: relative;
  display: flex;
  @media #{$tablet} {
    flex-direction: column;
  }
  [data-perf="high"] & {
    @media #{$only-phone} {
      border-top-left-radius: .62em;
      border-top-right-radius: .62em;
    }
  }
  border-top: 1px solid color('white', .1);
	background-color: color('background');
  [data-perf="low"] & {
    border-top: 1px solid color('dark', .1);
  }
  @media #{$only-phone} {
    [data-enable-blur-effect="true"] & {
      background-color: transparent;
      background-image: linear-gradient(133deg, color('background', 1), color('background', .6));
      background-size: 100% 100%;
      background-repeat: no-repeat;
      backdrop-filter: blur(2em);
    }
  }
  [data-tab-length="5"] & {
    @media #{$only-phone} {
      .AppNavTab {
        &:nth-child(3) { order: 1 }
        &:nth-child(4) { order: 2 }
        &:nth-child(1) { order: 3 }
        &:nth-child(5) { order: 4 }
        &:nth-child(2) { order: 5 }
      }
    }
  }
}

.AppNavigationControls {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  .AdminNavControlSet {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: size(md);
  }
}