@import '../../styles/base';

.AppTitleBar {
  position: relative;
  height: var(--TitleBarHeight);
  width: 100%;
  @media print {
    display: none;
  }
  [data-perf="high"] & {
    &:before {
      @media #{$only-phone} {
        content: '';
      }
      background-image: linear-gradient(to bottom, color('black', .19), color('black', 0));
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: var(--TitleBarHeight);
      box-sizing: content-box;
      padding-bottom: 1em;
      pointer-events: none;
    }
  }
  .Turn2MeLogo {
    color: color('skyblue');
    @media #{$tablet} {
      font-size: .75rem;
    }
  }
}
.AppTitleBarInner {
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  display: flex;
  align-items: center;
  padding-top: env(safe-area-inset-top);
  @media #{$only-phone} {
    width: var(--AppWidth);
    transform: translateZ(1px);
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  @media #{$tablet} {
    width: calc(var(--AppWidth) - #{size(md)} - #{size(md)});
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
    box-shadow: 0 1em 3em rgba(0,0,0,0.06);
  }
  max-width: $containerMaxWidth;
  margin-left: auto;
  margin-right: auto;
  transform: translateZ(0);
  [data-perf="low"] & {
    border-bottom: 1px solid color('dark', .1);
  }
  padding-left: 1em;
  padding-right: 1em;
  background-color: color('background');
  [data-enable-blur-effect="true"] & {
    background-color: transparent;
    background-image: linear-gradient(133deg, color('background', 1), color('background', .5));
    backdrop-filter: blur(2em);
  }
}
.AppTitleBarStart {
  flex: 1 1 1em;
  text-align: left;
  margin-left: -.19em;
  padding-top: .5em;
  padding-bottom: .5em;
  @media #{$only-small-phone} {
    padding-top: .25em;
    padding-bottom: .25em;
  }
  @media #{$tablet} {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: .5em;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    + * {
      margin-left: 1em;
    }
  }
}
.AppTitleBarCenter {
  flex: 3 3 auto;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}
.AppTitleBarEnd {
  flex: 1 1 1em;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  padding-top: .5em;
  padding-bottom: .5em;
  a {
    text-decoration: none;
  }
  @media #{$only-small-phone} {
    padding-top: .25em;
    padding-bottom: .25em;
  }
  > * {
    + * {
      margin-left: .5em;
    }
  }
  .BaseButton {
    font-size: 1.4rem;
    align-self: stretch;
    white-space: nowrap;
    padding: .19em .62em;
    .BaseButtonInner {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.AppTitleBarTitle {
  text-align: center;
  font-size: 1.6rem;
}
.Turn2MeLogoContainer {
  color: color('primary');
}
.AppTitleBarAccountButton {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  > * {
    + * {
      margin-left: .38em;
    }
  }
  > span {
    font-weight: 600;
  }
}
.AppTitleBarAccountButtonUsername {
  > * {
    display: block;
  }
}
.AppTitleBarAccountControls {
  display: flex;
  align-items: center;
  background-color: color('primary', .1);
  padding: .19em .5em .19em .62em;
  border-radius: 2em;
  a {
    text-decoration: none;
  }
  > * {
    + * {
      margin-left: .5em;
    }
  }
  .VerticalSeparator {
    margin-left: .38em;
  }
}