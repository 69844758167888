@import '../../../styles/base';

.PageLanding {
  padding: size(xl);
  background-color: color('primary', .1);
  min-height: var(--AppHeight);
  color: color('primary');
  display: flex;
  flex-direction: column;
  .Turn2MeLogo {
  }
  header {
    flex: 1 1 auto;
    > * {
      + * {
        margin-top: 1em;
      }
    }
  }
  footer {
    padding-top: size(lg);
  }
}