@import '../../../styles/base';


.PageRegisterHeader {
  margin-bottom: 1em;
}

.PageRegisterBody {
  margin-bottom: .5em;
}

.RegisterForm {
  p {
    font-size: 1.4rem;
  }
}

.PageRegisterFooter {
  a {
    text-decoration: none;
  }
}

.PageRegister {
  .formWrapper {
    @media #{$tablet} {
      padding: 1em;
      background-color: color('background');
      border-radius: .5em;
      margin-top: .5em;
      box-shadow: 0 .1em .6em color('primary', .2);
    }
  }
}