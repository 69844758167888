@import '../../styles/base';

.FeedbackForm {
  > * {
    + * {
      margin-top: 1em;
    }
  }
  .RatingInput {
    font-size: 2.8rem;
    @media #{$tablet} {
      font-size: 3.6rem;
    }
  }
}