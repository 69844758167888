@import '../../../../styles/base';

.AdminUpcomingSessionsAndGroups {
  overflow: hidden;
  margin-left: size(md, -1);
  margin-right: size(md, -1);
  padding-left: size(md);
  padding-right: size(md);
  .BaseHeader {
    h3 {
      @media #{$only-phone} {
        font-size: 1.6rem;
      }
    }
  }
  .SwiperContainer {
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: visible;
  }
  .SwiperSlide {
    overflow: visible;
    height: auto;
    display: flex;
    align-items: stretch;
    > * {
      flex: 1 1 100%;
    }
  }
  .swiper-wrapper {
    overflow: visible;
    align-items: stretch;
  }
  .SupportGroupSummaryCard {
    height: 100%;
    .SupportGroupSummaryCardInner {
      background-color: color('primary', .38);
      border: 2px solid color('primary', .2);
    }
    &.upcoming {
      .SupportGroupSummaryCardInner {
        background-color: color('background');
      }
    }
  }
}
.UpcomingSessionsEmptyNotice {
  background-color: color('skyblue', .1);
  text-align: center;
  padding: .62em .75em .75em;
  font-size: 1.6rem;
  border-radius: .38em;
  color: color('blueGreen');
  .BaseIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}