@import '../../styles/base';

.SupportGroupHasJoinedTag {
  display: inline-flex;
  align-items: center;
  background-color: color('blueGreen', .19);
  font-weight: 700;
  border-radius: .38em;
  padding: .19em .38em;
  color: color('blueGreen');
  font-size: 1.4rem;
  + .BodyCopyRenderer {
    margin-top: .5em;
  }
  .BaseIcon {
  }
}