@import '../../styles/base';

.ChatWindowHeader {
  background-color: color('primary');
  color: contrastColor('primary');
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 1;
  &.ended {
    filter: grayscale(.38);
  }
  [data-mode='docked'] & {
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: .38em;
    padding-right: .38em;
  }
  .--closed & {
    border-top-left-radius: .38em;
    border-top-right-radius: .38em;
  }
  @media print {
    color: inherit;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid color('primary', .2);
    margin-top: .5em;
    -webkit-print-color-adjust: exact;
    padding-left: 0;
  }
  @media #{$only-phone} {
    .ChatWindow.live & {
      padding-top: .38sem;
      padding-bottom: .38sem;
      .ChatWindowHeaderContent {
        padding-top: 0;
      }
    }
    .ChatWindow.ended & {
      display: grid;
      grid-template-columns: auto minmax(0, 1fr);
      grid-gap: 0;
      grid-template-rows: auto auto;
      grid-template-areas: 'back title' 'back controls';
      .nogrid & {
        display: block;
      }
    }
  }
  .BackButton {
    flex: 0 0 auto;
    @media #{$only-phone} {
      grid-area: back;
      margin-left: .38em;
    }
  }
  .SupportGroupIcon {
    margin-left: size(md);
    flex: 0 0 auto;
    + * {
      padding-left: 1em !important;
    }
    [data-mode='docked'] & {
      margin-left: .25em;
      margin-right: .25em;
      + * {
        padding-left: .5em !important;
      }
    }
  }
}
.ChatWindowHeaderPreTitle {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  [data-mode="standalone"] & {
    @media #{$tablet} {
      font-size: 1.2rem;
      font-weight: 800;
    }
  }
}
h3.ChatWindowHeaderTitle {
  font-weight: 700;
  font-size: inherit;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
  @media print {
    font-size: 2.8rem !important;
    .ColorTag {
      display: none;
    }
  }
}
.ChatWindowHeaderContent {
  flex: 1 1 auto;
  padding: .38em .5em;
  [data-mode="standalone"] & {
    padding: 1.38em 1em 1.38em .5em;
    @media #{$only-phone} {
      padding-bottom: 0;
      padding-top: .5em;
    }
    @media print {
      padding-left: 0;
    }
    @media #{$tablet} {
      padding-left: 1em;
    }
  }
  background-color: transparent;
  white-space: normal;
  [data-mode='docked'] & {
    cursor: pointer;
    &:hover {
      background-color: contrastColor('primary', .1);
    }
  }
  @media #{$only-phone} {
    grid-area: title;
  }
}
.ChatWindowHeaderPrintInformation {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  @media screen {
    display: none;
  }
  > * {
    margin-right: 1em;
  }
  > p {
    > strong {
      display: block;
    }
  }
}
.ChatWindowHeaderControls {
  display: flex;
  @media #{$only-phone} {
    grid-area: controls;
    padding-left: .38em;
  }
  > * {
    flex: 0 0 auto;
  }
  @media print {
    display: none;
  }
  .BaseButtonLabel {
    font-weight: 600;
    @media #{$only-phone} {
      display: none;
    }
    @media #{$tablet} {
      font-size: 1.2rem;
    }
  }
}
.ChatWindowHeaderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  color: inherit;
  background-color: transparent;
  padding: .62em;
  flex: 0 0 auto;
  font-size: 1.6rem;
  font-weight: 600;
  min-width: unset;
  margin: 0;
  border-radius: 0;
  &:hover {
    background-color: contrastColor('primary', .1);
  }
}