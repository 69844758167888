@import '../../styles/base';

.InfoBanner {
  background-color: color('background');
  color: color('foreground');
  @extend %colorCodedStates;
  border-radius: .5em;
  @media #{$tablet} {
    border-radius: .38em;
  }
  * + & {
    margin-top: .5em;
  }
  & + * {
    margin-top: .5em;
  }
  code {
    background-color: transparent;
    padding: 0;
    color: color('primary');
    word-break: break-all;
  }
  &.intense {
    .InfoBannerInner {
      background-color: color('primary');
      border: 2px solid color('primary', 1, .19);
      color: contrastColor('primary');
      font-weight: 500;
    }
    .InfoBannerIcon {
      color: contrastColor('primary');
    }
  }
  &.compact {
    font-weight: 500;
    .InfoBannerInner {
      font-size: 1.4rem;
      padding: .38em;
      grid-gap: 0 .38em;
      border-width: 1px;
    }
  }
}

.InfoBannerInner {
  background-color: color('primary', .19);
  border: 2px solid color('primary', .38);
  border-radius: inherit;
  overflow: hidden;
  padding: .62em;
  display: grid;
  text-align: left;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'icon header controls'
    'icon body controls'
  ;
  grid-gap: 0 .5em;
  font-size: 1.6rem;
  .nogrid & {
    display: block;
    gap: 0;
  }
  h1, h2, h3, h4, h5, h6, p {
    a {
      font-weight: 700;
      @extend %underlinedLinks;
    }
  }
}

.InfoBannerIcon {
  grid-area: icon;
  color: color('primary');
  .nogrid & {
    float: left;
    margin-right: .5em;
  }
}

.InfoBannerHeader {
  grid-area: header;
  h4.InfoBannerHeading {
    font-size: inherit;
    font-weight: 700;
  }
}

.InfoBannerBody {
  grid-area: body;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}
