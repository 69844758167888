@import '../../styles/base';

.ChatWindowContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  position: relative;
  .nogrid & {
    flex: 1 1 auto;
  }
  .ChatWindowTypingIndicator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .38em 1em .25em 1em;
    opacity: 0;
    background-image: radial-gradient(100% 100% at 0% 100%, color('background') 30%, color('background', 0) 100%);
    &[data-on="true"] {
      transition: .1s;
      opacity: 1;
    }
  }
  .ChatWindowVideoFrame {
    flex: 2 2 61.8%;
  }
  @media print {
    max-height: unset !important;
    border: none;
  }
}
.ChatWindowContentScrollFrame {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: unset;
  }
  height: 48rem;
  max-height: 80vh; /** fallback patch to make it at least useable */
  @media print {
    height: auto !important;
    max-height: unset !important;
    overflow: visible;
  }
}
.ChatWindowContentInner {
  padding-top: 1em;
  padding-bottom: 1em;
  flex: 0 0 100%;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 62rem;
  margin-left: auto;
  margin-right: auto;
  @media print {
    max-width: 38em;
    margin-left: 0;
    padding-top: 0;
  }
  > * {
    width: 100%;
  }
  .ChatMessageList {
    padding-left: 1em;
    padding-right: 1em;
    + .ChatMessageList {
      margin-top: .5em;
    }
  }
}
.ChatWindowContentSeparator {
  display: flex;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  align-items: center;
  opacity: .5;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  &:before,
  &:after {
    content: '';
    border-top: 1px solid currentColor;
    flex: 1 1 auto;
    opacity: .5;
  }
  span {
    flex: 0 1 auto;
    padding-left: .5em;
    padding-right: .5em;
  }
  @media print {
    margin-top: .5em;
  }
}

.ChatWindowContentTipBlock {
  box-sizing: border-box;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  font-size: 1.4rem;
  background-color: color('primary', .19);
  padding: 1em;
  border-radius: .5em;
  width: calc(100% - 2em);
  @media print {
     display: none;
  }
  h3 {
    font-size: inherit;
    margin-bottom: .5em;
  }
}

.ChatWindowContentDeclinedCallWarning {
  background-color: color('orange', .19);
  padding: 1em;
  font-weight: 500;
}