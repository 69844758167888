@import '../../styles/base';

.LoadingIndicatorSection {
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-colored="true"] {
    background-color: color('primary', .1);
    color: color('primary');
    border-radius: .5em;
    * + & {
      margin-top: .62em;
    }
    & + * {
      margin-top: .62em;
    }
  }
}