@import '../../styles/base';

.PaymentCardForm {
  padding: .62em;
  border: 2px solid color('primary', .2);
  border-radius: 3px;
  &:not(.isComplete):not(.isEmpty):not(.hasError) {
    border-color: color('attention', .75);
    background-color: color('attention', .1);
  }
  &.hasError {
    border-color: color('alert', .75);
    background-color: color('alert', .1);
  }
}