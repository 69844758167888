@import '../../styles/base';

@keyframes ReactionPickerEnter {
  from {
    opacity: 0;
    transform: scale(.6);
  }
  to {
    opacity: 1;
    transform: translateY(-100%);
  }
}

@keyframes ReactionPickerExit {
  from {
    opacity: 1;
    transform: translateY(-100%);
  }
  to {
    opacity: 0;
    transform: translateY(-62%) scale(.1);
  }
}

.ReactionPicker {
  position: fixed;
  animation: ReactionPickerEnter .6s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  transform-origin: left bottom;
  &.exiting {
    animation: ReactionPickerExit .25s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  }
}
.ReactionPickerList {
  background-color: color('background');
  padding: .5em 1em;
  border-radius: 10em;
  box-shadow: 0 .2em 1em rgba(0,0,0,0.1);
  transform-origin: left;
  display: flex;
  align-items: flex-end;
  [data-readonly="true"] & {
    cursor: not-allowed;
  }
  > * {
    + * {
      margin-left: .19em;
    }
  }
  .ReactionIcon {
    cursor: pointer;
    transition: .25s;
    position: relative;
    overflow: visible;
    display: flex;
    align-items: flex-end;
    box-sizing: content-box;
    width: 3.8rem;
    height: 3.8rem;
    svg {
      transition: .25s;
      width: 3.8rem;
      height: 3.8rem;
    }
    &:hover {
      width: 5.2rem;
      svg {
        width: 5.2rem;
        height: 5.2rem;
      }
    }
    &:active {
      transform: scale(.9);
    }
  }
}

.ReactionDisplay {
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  border: none;
  .ReactionIcon {
    &.ReactionSelected {
      border-radius: 4em;
      width: 4.2rem;
      height: 4.2rem;
      transition: .1s;
      user-select: none;
      padding: .5em;
      &:active {
        transform: scale(.9);
      }
      [data-user-selected-type=None] & {
        transform: scale(.9);
      }
    }
  }
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
}
.AllReactionsDisplay {
  display: flex;
  align-items: center;
  background-color: color('primary', .1);
  border-radius: 1.5em;
  padding: .19em .62em .19em .38em;
  margin: 0;
  color: color('primary');
  font-weight: 800;
  font-size: 85%;
  .ReactionIcon {
    width: 2.4rem;
    height: 2.4rem;
  }
  > * {
    display: flex;
    align-items: center;
    padding-left: .1em;
    padding-right: .1em;
    + * {
      margin-left: .1em;
    }
  }
}

@keyframes ReactionRemoveButtonEnter {
  from {
    opacity: 0;
    transform: scale(.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ReactionRemoveButton {
  appearance: none;
  background-color: color('background');
  background-image: linear-gradient(to bottom, color('alert', .1), color('alert', .1));
  color: color('alert');
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
  border-radius: 2em;
  border: 0;
  position: absolute;
  height: 2.5em;
  top: 100%;
  left: 0em;
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  animation: ReactionRemoveButtonEnter .62s .09s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}