@import '../../styles/base';

.CountWithIcon {
  display: inline-flex;
  align-items: center;
  color: color('primary');
  font-weight: 700;
  > * {
    + * {
      margin-left: .19em;
    }
  }
  &.interactable {
    cursor: pointer;
  }
}