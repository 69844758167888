@import '../../styles/base';

@keyframes BaseCalendarEventEnter {
  from {
    opacity: 0;
    transform: translateY(6px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.BaseCalendarEvent {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  grid-gap: .5em;
  text-align: left;
  padding: .75em .5em;
  @media #{$tablet} {
    padding: .25em .5em;
  }
  background-color: color('primary', .19);
  margin-top: -1px;
  cursor: pointer;
  line-height: 1;
  font-size: 1.4rem;
  &:hover {
    background-color: color('primary', .38);
  }
  &[data-hidden="true"] {
    display: none;
  }
  &.with-border-left {
    border-left: 3px color('primary') solid;
  }
  &.disabled {
    opacity: .5;
    filter: grayscale(.62);
    pointer-events: none;
  }
  &.hidden {
    display: none;
  }
}

.BaseCalendarEventContent {
  grid-area: content;
}

.BaseCalendarEventHeader {
  overflow: hidden;
  text-overflow: ellipsis;
}

.BaseCalendarEvent h3,
.BaseCalendarEventTitle {
  font-size: inherit;
  font-weight: 500;
  color: inherit;
}

.BaseCalendarEventTimeWrapper {
  float: right;
  grid-area: time;
  font-feature-settings: 'tnum' 1;
  padding-left: .25em;
  font-weight: 700;
  > * {
    display: block;
    text-align: right;
  }
  em {
    font-weight: 700;
    font-style: normal;
    font-size: 75%;
    text-transform: uppercase;
    margin-top: .19em;
  }
  time {
    font-size: 85%;
  }
}

.BaseCalendarEvent {
  .BaseCalendarEventBody {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > * + * {
      margin-top: .25em;
    }
  }
}