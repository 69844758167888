@import '../../../../styles/base';

.OverlayPhoneVerification {
  .BaseInput input {
    font-size: 2.2rem;
  }
  h1 {
    span {
      @media #{$only-phone} {
        display: block;
      }
    }
  }
  h2 {
    font-size: 3.2rem;
  }
}

.VerificationCodeField {
  @include setPrimaryColor('blueGreen');
  color: color('blueGreen');
  input {
    letter-spacing: .5em;
    @media #{$tablet} {
      padding-left: 1em;
      letter-spacing: 1em;
    }
  }
}