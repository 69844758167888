@import '../../../../styles/base';

.OverlayAdminSingleThoughtManager {
  border-top: 2px solid transparent;
  .BaseHeading {
    color: color('primary');
    font-size: 1.8rem;
  }
  &.private {
    @include setPrimaryColor('purple');
  }
  &.deleted {
    filter: grayscale(1);
  }
  &.safe {
    border-top-color: color('green');
    .AppPageHeaderInner {
      background-image: linear-gradient(to bottom, color('green', .1), color('green', 0) 4em);
    }
  }
  &.unsafe {
    border-top-color: color('red');
    .AppPageHeaderInner {
      background-image: linear-gradient(to bottom, color('red', .1), color('red', 0) 4em);
    }
  }
}
.OverlayAdminSingleThoughtManagerContent {
  @media #{$tablet} {
    display: grid;
    grid-template-columns: 6.2fr minmax(20rem, 3.8fr);
    grid-gap: 1em;
    .nogrid & {
      display: flex;
    }
  }
}
.OverlayAdminSingleThoughtManagerContentMain {
  .nogrid & {
    flex: 1 1 auto;
  }
}
.OverlayAdminSingleThoughtManagerContentAside {
  .nogrid & {
    flex: 0 0 30rem;
  }
}
.OverlayAdminSingleThoughtManagerDeletedTag {
  font-weight: 700;
}