@import '../../../../styles/base';

.AdminModuleHeader {
	padding-left: size(md);
	padding-right: size(md);
	padding-bottom: .62em;
	[data-from-tablet-and-tall=true] & {
		padding-top: size(md);
	}
	display: grid;
	gap: .5em;
	grid-template-columns: minmax(0, 1fr) auto;
	grid-template-areas: 'inner end';
	align-items: center;
	.nogrid & {
		display: flex;
	}
}

.AdminModuleTitle {
	font-weight: 700;
	font-size: 2.8rem;
	@media #{$tablet} {
		font-size: 2.4rem;
		font-weight: 800;
	}
	@media #{$desktop} {
		font-size: 2.6rem;
	}
}

.AdminModuleHeaderInner {
	grid-area: inner;
	.nogrid & {
		flex: 1 1 auto;
	}
}
.AdminModuleEndSlot {
	grid-area: end;
	.nogrid & {
		flex: 0 0 auto;
	}
}