@import '../../styles/base';

.BaseInput {
  @extend %colorCodedStates;
  label:not(.BaseLabel) {
    @extend %label;
  }
  &.hasActions {
    .BaseInputInner {
      position: relative;
    }
    &.focus {
      .BaseInputActionButtonGroup {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
  + p {
    > em:only-child {
      margin-top: .5em;
    }
  }
  &.error {
    @include setPrimaryColor('red');
    .BaseInputInput,
    .BaseInputTextarea {
      border: 2px solid color('primary');
    }
  }
}

.BaseInputInfoAfterInputField {
  margin-top: .5em;
  color: color('foreground', .62);
  font-style: italic;
  @media #{$only-phone} {
    font-size: 1.4rem;
  }
}

.BaseInputInput,
.BaseInputTextarea {
  @extend %defaultInput;
}
.BaseInputInput {
  &[type="text"],
  &[type="email"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    appearance: none;
    @media #{$only-phone} {
      font-size: 16px;
    }
  }
  &[type="url"] {
    font-family: font(monospace);
    font-feature-settings: 'zero' 1;
  }
  &[type="password"] {
    font-family: font(default);
  }
  &[type="time"] {
    // font-family: font(monospace);
    width: 100%;
    .iOS & {
      appearance: none;
    }
  }
  &[type="color"] {
    [data-browser*="chrome"] &,
    [data-browser*="apple"] & {
      padding: 0.125em;
    }
    filter: none !important;
  }
}

.BaseInputTextarea {
  display: block;
  height: auto;
  @media #{$only-phone} {
    font-size: 16px;
  }
}
.BaseInputColor {
  padding: 0;
  appearance: none;
  width: 2em;
  height: 2em;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  overflow: hidden;
}

.BaseInputContentEditable {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -.1em;
    right: -.1em;
    bottom: -.1em;
    left: -.1em;
    background-color: transparent;
    pointer-events: none;
  }
  &:hover {
    &:before {
      background-color: color('primary',.038);
    }
  }
  &:focus {
    &:before {
      background-color: color('primary', .075);
    }
  }
}

.BaseInputActionButtonGroup {
  display: flex;
  align-items: stretch;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  > .BaseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .1s;
    color: color('primary');
    font-size: 1.4rem;
    width: 3em;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    &:hover {
      color: color('alert');
    }
    &.BaseInputHideKeyboardIcon {
      font-size: 1.8rem;
    }
  }
}