@import '../../styles/base';

.CounsellingEmail {
  border: 1px solid color('foreground', .1);
  background-color: color('background');
  &.open {
    border-color: color('primary');
  }
  &.own {
    border-color: color('primary', .5);
  }
}

.CounsellingEmailHeader {
  padding: 1em;
  &::marker {
    display:none;
  }
  .DateRenderer {
    text-transform: uppercase;
    font-weight: 700;
    opacity: .62;
    font-size: 80%;
    + * {
      margin-top: .19em;
    }
    @media #{$tablet} {
      text-align: right;
      float: right;
    }
  }
  h4 {
    margin-bottom: .5em;
    font-size: inherit;
  }
}

.CounsellingEmailBody {
  padding: 1em;
  line-height: 1.38;
  a {
    color: color('primary');
    text-decoration: underline;
    overflow-wrap: break-word;
  }
}

.CounsellingEmailBodyInner {
  margin-top: -1em;
  border-top: 1px solid color('foreground', .1);
  padding-top: 1em;
}