
@import '../../styles/base';

.OverlayReceipt {
  .PrintSpacer {
    display: none;
  }

  .DocumentHeader {
    display: none;
    justify-content: space-between;
    align-items: center;

    .Turn2MeLogo {
      font-size: 1rem;
      color: color('primary');
    }
    .BaseHeading {
      font-size: 3rem;
    }
  }

  // .InfoTable,
  // .BaseTable {
  //   @include setPrimaryColor('primary');
  // }

  .InfoTable {
    th {
      color: color('primary');
    }
  }

  .ReceiptFooter {
    display: grid;
    place-items: center;
    color: color('primary');
  }

  .BaseTable {
    // &,
    // .BaseTableHeadCell {
    //   text-align: center;
    // }

    .BaseTableBodyEmptyCell {
      border: none;
    }
  }

  @media print {
    .AppPageHeader {
      display: none;
    }
    .DocumentHeader {
      display: flex;
    }
    .PrintSpacer {
      display: inline;
    }
    .BaseTable,
    .ReceiptFooter {
      margin-top: size(lg);
    }
  }

}