@import '../../styles/base';

.BaseGrid {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  grid-gap: .5em;
  .nogrid & {
    display: flex;
    flex-wrap: wrap;
    gap: 0 !important;
    margin-left: -.25em;
    margin-right: -.25em;
    margin-top: -.5em;
  }
  @media #{$only-phone} {
    grid-template-columns: [grid-column-start] minmax(0, 1fr) [grid-column-end];
    > * {
      grid-column-end: span 1;
    }
  }
  > * {
    .nogrid & {
      flex: 1 1 var(--BaseGridColumnFlexBasisFallback, auto);
      margin-left: .25em;
      margin-right: .25em;
      margin-top: .5em;
    }
  }
}
.BaseGridCell {
  grid-column-end: var(--grid-column-end);
  grid-row-end: var(--grid-row-end);
}