.UserCard {
  display: inline-grid;
  grid-gap: .5em;
  align-items: center;
  grid-auto-flow: column;
  .nogrid & {
    display: flex;
  }
}
.UserCardHeader {
  .nogrid & {
    flex: 1 1 100%;
  }
  h3 {
    font-weight: 700;
    font-size: inherit;
  }
}