@import '../../styles/base';

@keyframes nextSessionHighlighter {
  from {
    background-position: left 0% top 50%;
  }
  to {
    background-position: left 100% top 50%;
  }
}
.SupportGroupSummaryCard {
  display: block;
  width: 100%;
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: transform .1s;
  text-align: left;
  &.isForFrontLineWorkers {
    .SupportGroupSummaryCardInner {
      background-image: linear-gradient(175deg, color('aqua', .15), color('aqua', 0) 6.2rem);
    }
  }
  &:active {
    transform: scale(.99);
  }
  &.preview {
    cursor: not-allowed;
  }
  &.upcoming {
    &:before {
      content: '';
      background-color: color('white');
      background-image: linear-gradient(45deg,
        color('primary', .2), color('primary'),
        color('primary', .2), color('primary'),
        color('primary', .2)
      );
      background-size: 262% 100%;
      background-repeat: no-repeat;
      animation: nextSessionHighlighter 2s infinite linear;
      display: block;
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border-radius: calc(var(--CardRadius) + 3px);
      .--cypress & {
        animation: none;
      }
    }
  }
  &:hover,
  &:focus {
    .SupportGroupSummaryCardInner {
      box-shadow: 0 0 .1em .1em color('primary');
    }
  }
  .SessionOrGroupTimeDisplay {
    background-color: color('primary', .1);
  }
}

.SupportGroupSummaryCardInner {
  position: relative;
  padding: .75em;
  background-color: color('background');
	color: color('foreground');
	background-image: linear-gradient(216deg, color('background', .62), color('background'));
	min-height: 100%;
  border-radius: var(--CardRadius);
  transition: box-shadow .1s;
  @extend %cardShadow;
  > * {
    + * {
      margin-top: .5em;
    }
  }
  .SupportGroupHasJoinedTag {
    display: flex;
  }
}

.SupportGroupSummaryCardHeader {
  display: flex;
  justify-content: flex-start;
	align-items: flex-start;
	.SmallHeaderButton {
		color: inherit;
    background-color: color('foreground', .19);
    font-size: 1.2rem;
	}
}

.SupportGroupSummaryCardIconWrapper {
	flex: 0 0 auto;
  margin-right: .5em;
  overflow: hidden;
}

.SupportGroupSummaryCardHeaderInner {
  flex: 1 1 auto;
}

.SupportGroupSummaryCardBeforeTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
	text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  .SmallHeaderButton {
    font-size: 1.1rem;
    padding-top: .0;
    padding-bottom: 0;
    margin-bottom: -.19em;
  }
}
.SupportGroupSummaryCardTitle {
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 1.19;
}
.SupportGroupSummaryCardSubtitle {
	font-weight: 700;
	font-size: 1.4rem;
  line-height: 1.19;
  color: color('primary');
}

.SupportGroupSummaryStartingTag {
  // color: white;
  // background-color: color('red');
  color: color('attention');
  // padding: .18em .5em;
  border-radius: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: .19em;
  display: inline-block;
}

.SupportGroupSummaryCardDetails {
  .BodyCopyRenderer {
    margin-bottom: .5em;
  }
  footer footer {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .UsernameRenderer {
      margin-left: .5em;
    }
  }
}

.SupportGroupSummaryCardParticipantCount {
	display: flex;
	align-items: center;
  justify-content: flex-end;
  color: color('primary');
  margin-top: .38em;
	.BaseIcon {
		margin-right: .38em;
	}
}