@import '../../styles/base';

.CommunicationTypeRenderer {
  display: inline-flex;
  align-items: baseline;
  font-weight: 700;
  color: color('primary');
  .BaseIcon {
    flex: 0 0 auto;
    margin-right: .25em;
    position: relative;
    top: .38em;
    margin-top: -.19em;
  }
}