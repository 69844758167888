@import '../../../../styles/base';

.OverlayUserPermissionManager {}

.OverlayUserPermissionManagerGlobalControlSet {
  @media #{$tablet} {
    display: flex;
    .BaseButtonGroup {
      &:first-of-type {
        flex: 1 1 auto;
      }
      &:last-of-type {
        flex: 0 0 auto;
      }
    }
  }
}

.OverlayUserPermissionManagerEndSlotWrapper {
  display: flex;
  align-items: center;

  .UnsavedChangesColorTag {
    padding: .7em 1em;
  }
}