@import '../../../../../styles/base';

.AccountPageAddressManagerNewAddressEditor {
  @include setPrimaryColor('green');
  border: 2px solid color('primary', .2);
  background-image: linear-gradient(162deg, color('primary', .1) 0, color('primary', 0) 8em);
  padding: 1em;
  border-radius: .5em;
  > * {
    + * {
      margin-top: 1em;
    }
  }
}