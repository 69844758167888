.BaseArticle {
  line-height: 1.38;
  > * {
    + * {
      margin-top: .5em;
    }
  }
  blockquote {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
  }
}