@import '../../../../styles/base';

.ClientAppSubmodule {
  @include clientSubmoduleBackground;
  @media #{$tablet} {
    padding-left: size(md);
    padding-right: size(md);
  }
}

.ClientAppSubmoduleInner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: $containerMaxWidth;
  min-height: var(--AppHeight);
  @media #{$tablet} {
    display: grid;
    .nogrid & {
      display: block;
      position: relative;
    }
    .ClientAppSubmodule.fullHeight & {
      height: var(--AppHeight);
      overflow: hidden;
    }
    grid-template-columns: 5em minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-areas: 'titleBar titleBar' 'navTabs content';
    grid-gap: 0 size(md);
  }
}

.AppTitleBar {
  grid-area: titleBar;
}

.ClientAppSubmoduleNavTabs {
  grid-area: navTabs;
  @media #{$tablet} {
    .nogrid & {
      position: absolute;
      left: 0;
      top: 6em;
    }
  }
  @media print {
    display: none;
  }
}

.ClientAppSubmoduleContent {
  grid-area: content;
  @media #{$tablet} {
    .nogrid & {
      padding-left: 6em;
    }
  }
}

.ClientAppSubmoduleNav {
  position: sticky;
  top: calc(var(--TitleBarHeight) + #{size(md)});
  background-color: color('background', .8);
  border-radius: 10em;
  padding-top: 1em;
  padding-bottom: 1em;
  overflow: hidden;
  transform: translateZ(0);
  @extend %cardShadow;
}