@import '../../styles/base';

@keyframes ClientModuleEnter {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.ModuleClient {
  .ModuleClientInner {
    [data-perf="high"].splashscreenVisible & {
      @media #{$only-phone} {
        opacity: 0;
        animation: ClientModuleEnter 1s 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      }
    }
    [data-perf="high"].splashscreenVisible.--cypress & {
      @media #{$only-phone} {
        opacity: 1;
        animation: none;
      }
    }
  }
  background-color: color('background');
}

.ClientSplashScreen {
	background: radial-gradient(122.97% 151.94% at 53.51% 104.08%, #12D4FF 0%, #008ECB 100%);;
}