@import '../../styles/base';

.UIBlock {
  @extend %paddable;
  &.sticky {
    position: sticky;
    z-index: 1;
    &.top { top: 0; }
    &.left { left: 0; }
    &.bottom { bottom: 0; }
    &.right { right: 0; }
  }
  &.fullHeight {
    min-height: 100%;
  }
  &.scrollable {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    .--cypress & {
      scroll-behavior: unset;
    }
  }
  &.spaceChildren {
    > *:not(.UIBlockHeader) {
      + * {
        margin-top: 1em;
      }
    }
  }
  &[data-vertical-layout-mode="flex"] {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    > * { 
      + * {
        flex: 0 0 auto;
      }
    }
  }
  &[data-vertical-layout-mode="grid"] {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(0, auto);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.UIBlockHeader {
  padding: .62em 0;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-gap: 1em;
  min-height: 4.5rem;
  box-sizing: border-box;
  .AppPageContent.padSections & {
    padding-top: 0;
  }
  + * {
    margin-top: 0;
  }
}

.UIBlockHeaderEndSlot {
  text-align: right;
}

.UIBlockTitle {
  font-size: 1.8rem;
  font-weight: 800;
  color: color('primary');
}