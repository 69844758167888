@import '../../../../../styles/base';

.CounsellingApplicationStepFinancialAssessmentOption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
  background: color('background');
  color: color('foreground');
  font-weight: 700;
  &[data-selected=true] {
    &.--true {
      background-color: color('primary');
      color: contrastColor('primary');
    }
    &.--false {
      background-color: color('alert');
      color: contrastColor('alert');
    }
  }
}

.CounsellingApplicationStepFinancialAssessmentOptionGroup {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2,1fr);
  max-width: 25em;
  .nogrid & {
    display: block;
    > * {
      + * {
        margin-top: .5em;
      }
    }
  }
}