@import '../../../../../styles/base';

.AccountPageProfile {
  .AppPageHeader {
    background-color: color('primary');
    color: contrastColor('primary');
    background-size: 100% auto;
    background-repeat: no-repeat;
    .BaseButton {
      color: inherit;
      background-color: contrastColor('primary', .19);
      border-radius: 2em;
    }
  }
  .AppPageHeaderInner {
    background-image: linear-gradient(to bottom, color('dark', 0.1), color('dark', .25));
  }
  .AppPageHeaderSlot {
    display: flex;
    align-items: flex-end;
  }
  .AppPageBeforeTitle,
  .AppPageAfterTitle {
    color: inherit;
    line-height: 1.38;
  }
  .ThoughtCard {
    border: 2px solid color('primary', .19);
    &:not(.private) {
      background-color: color('primary', .08);
    }
  }
  .BaseHeader {
    padding-bottom: .62em;
    h3 {
      color: color('primary');
    }
  }
}