@import '../../styles/base';

.TitleBarHeightSpacer {
	width: 100%;
	flex: 0 0 auto;
	.Overlay & {
		display: none;
	}
	@media #{$tablet} {
		display: none;
	}
	@media print {
		display: none;
	}
}