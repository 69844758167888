@import '../../styles/base';

.BaseHeader {
  display: grid;
  grid-template-columns: minmax(0,1fr) auto;
  max-width: 100%;
  .nogrid & {
    display: flex;
  }
  + * {
    margin-top: .5em;
  }
}
.BaseHeaderContent {
  .nogrid & {
    flex: 1 1 auto;
  }
}
.BaseHeaderEndSlot {
  .nogrid & {
    flex: 0 0 auto;
  }
  .link {
    color: color('primary');
    font-weight: 700;
  }
}