@import '../../styles/base';

.BaseCalendarHeader {
  .TimeframePicker {
    @media #{$only-phone} {
      background-color: color('primary', .1);
      border-radius: .3em;
      margin-bottom: .5em;
    }
    @media #{$tablet} {
      border-bottom: var(--BorderMinor);
    }
  }
  .TimeframePickerTitle {
    padding-top: .62em;
    padding-bottom: .62em;
  }
}