@import '../../styles/base';

.ChatBubble {
  -webkit-print-color-adjust: exact;
  * {
    font-size: inherit;
  }
  &.left {
    .ChatBubbleArrow {
      left: 1rem;
    }
  }
  &.right {
    @media screen {
      .ChatBubbleInner {
        flex-direction: row-reverse;
      }
      .ChatBubbleArrow {
        right: 1rem;
      }
    }
  }
  @media print {
    .ChatBubbleInner {
      flex-direction: row-reverse;
    }
  }
  &.inverted {
    .ChatBubbleBackgroundShape {
      opacity: 1;
    }
  }
  &.sm {
    .ChatBubbleBubble {
      padding: .38em .62em .38em;
      .ChatBubbleBackgroundShape {
        border-radius: 1em;
      }
    }
    &.editing {
      .ChatBubbleBubble {
        padding: .38em;
      }
    }
  }
  &.md {
    .ChatBubbleBubble {
      padding: 1em 1.38em .62em + .8em;
      .ChatBubbleBackgroundShape {
        border-radius: 1.62em;
        margin-bottom: .8rem;
      } 
      &.editing {
        .ChatBubbleBubble {
          padding: 1em;
        }
      }
    }
  }
  &.interactable {
    .ChatBubbleBubble {
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  &.isHidden {
    .ChatBubbleBubble {
      opacity: .5;
      font-style: italic;
    }
  }
  .ChatMessageComposer {
    // border-radius: 1.38rem;
    overflow: hidden;
    .BaseInputTextarea {
      background-color: color('background');
      color: color('foreground');
      padding-left: .5em;
      border-radius: .62em;
    }
    .BaseButton {
      border-radius: .62em;
    }
    .BaseButtonInner {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .BaseButtonStaticLabel {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .BaseButtonLabel {
      display: none;
    }
  }
}

.ChatBubbleInner {
  position: relative;
  display: flex;
  align-items: baseline;
}

.ChatBubbleBubble {
  flex: 0 1 auto;
  display: block;
  line-height: 1.19;
  position: relative;
  max-width: calc(100% - 2em);
  @media print {
    flex: 1 1 auto;
  }
}

.ChatBubbleBackgroundShape {
  background-color: color('primary');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .38;
  @media print {
    background-color: transparent;
    opacity: 1;
    border-radius: 0 !important;
  }
}

.ChatBubbleArrow {
  position: absolute;
  width: 2.4rem;
  height: 2.2rem;
  fill: color('primary');
  bottom: -.8rem;
  @media print {
    display: none;
  }
}

.ChatBubbleContent {
  position: relative;
  text-align: left;
  overflow-wrap: break-word;
  font-weight: 500;
  @media print {
    page-break-inside: avoid;
  }
}

.ChatBubbleTimestamp {
  flex: 1 0 auto;
  font-size: 1rem;
  margin-left: .3em;
  margin-right: .3em;
  opacity: .3;
  font-weight: 600;
  @media print {
    flex: 0 0 2rem;
  }
}

.ChatBubbleControls {
  @media print {
    display: none;
  }
  padding-left: .62em;
  margin-bottom: .19em;
  .PseudoLink {
    color: color('primary', .8);
    font-weight: 700;
    font-size: 85%;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: color('primary');
    }
    + .PseudoLink {
      margin-left: .5em;
    }
  }
}

.ChatBubbleEditedTag {
  opacity: .5;
  font-size: 1.4rem;
}