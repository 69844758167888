@import '../../../../../styles/base';

.ExploreServices {
  overflow: hidden;
  position: relative;
  .SwiperContainer {
    overflow: visible;
  }
}

.ExploreServicesGrid {
  display: flex;
  > * {
    flex: 1 1 33.33%;
    + * {
      margin-left: 1em;
    }
  }
}