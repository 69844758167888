@import '../../styles/base';

.SwiperPagination {
	.swiper-pagination-bullet {
		background: currentColor;
		opacity: .5;
		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}