@import '../../styles/base';

.CommentSectionToggle {
  appearance: none;
  color: inherit;
  border: none;
  background-color: transparent;
  border-radius: 4em;
  width: 4.2rem;
  height: 4.2rem;
  transition: .1s;
  user-select: none;
  padding: .25em;
  cursor: pointer;
  &:active {
    transform: scale(.9);
  }
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
}