@import '../../../../styles/base';

.DashBlock {
	background-color: color('background');
	color: color('foreground');
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	.--cypress & {
    scroll-behavior: unset;
  }
	&.clipContent {
		overflow: hidden;
	}
	&.fullWidthContent {
		.DashBlockContent {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&.headerEndAlign--end {
		.DashBlockHeader {
			align-items: flex-end;
		}
	}
}

.DashBlockHeader {
	position: sticky;
	left: 0;
	background-color: color('background');
	padding: size(md) size(md) 0 size(md);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	+ .DashBlockContent {
		padding-top: .75em;
	}
}

.DashBlockHeaderInner {
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	> * {
		+ * {
			margin-left: .5em;
		}
	}
}

.DashBlockHeading {
	h2, h3, h4 {
		font-size: inherit;
		font-weight: 700;
	}
}

.DashBlockContent {
	padding: size(md);
}