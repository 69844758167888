@import '../../styles/base';

.BaseCalendarWeek {
  .fitInnerToAvailableHeight & {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    > * {
      flex: 0 0 #{(100 / 7) * 1%};
      + * {
        border-left: var(--BorderMinor);
      }
    }
  }
  [data-view-mode="week"] & {
    @media #{$tablet} {
      padding-top: 0;
    }
  }
  .shouldDisplayAsTimeline & {
    display: block;
    .BaseCalendarWeekInner {
      display: flex;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      align-items: stretch;
      > * {
        flex: 0 1 #{(100 / 7) * 1%};
        &.BaseCalendarTimelineAxis {
          flex: 0 0 auto;
        }
        + * {
          border-left: var(--BorderMinor);
        }
      }
    }
  }
  .shouldDisplayAsTimeline &,
  [data-view-mode="week"].fitInnerToAvailableHeight & {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    .--cypress & {
      scroll-behavior: unset;
    }
  }
}