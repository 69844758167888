@import '../../styles/base';

@keyframes ChatBubbleEnter {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ChatMessageList {
  border-color: transparent;
  &[data-is-counsellor="true"] {
    border-color: color('brightPurple', .5);
    header {
      font-weight: 700;
    }
    @media print {
      border-left: 2px solid color('brightPurple');
    }
    small {
      color: color('brightPurple');
    }
  }
  &[data-is-moderator="true"] {
    border-color: color('emerald', .5);
    header {
      font-weight: 700;
    }
    @media print {
      border-left: 2px solid color('emerald');
    }
    small {
      color: color('emerald');
    }
  }
  &[data-is-facilitator="true"] {
    border-color: color('steel', .5);
    header {
      font-weight: 700;
    }
    @media print {
      border-left: 2px solid color('steel');
    }
    small {
      color: color('steel');
    }
  }
  small {
    font-weight: 700;
    font-style: normal;
  }
  .ChatBubble {
    + .ChatBubble {
      margin-top: 1px;
    }
  }
  @media print {
    display: block;
    text-align: left !important;
    page-break-before: always;
    padding-bottom: .5em;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5em;
    + .ChatMessageList {
      border-top: 1px solid color('primary', .38);
      margin-top: 0 !important;
    }
  }
}