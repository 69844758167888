@import '../../styles/base';

.ContactItem {
  border: 2px solid color('primary', .2);
  border-radius: .5em;
  &.primary {
    @include setPrimaryColor('green');
    border: 2px solid color('primary', .8);
  }
  &.landscape {
    display: flex;
  }
}
.ContactItemContent {
  flex: 1 1 auto;
  background-color: color('primary', .1);
  padding: .5em;
  @media #{$tablet} {
    padding: .62em 1em;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: inherit;
  }
  a {
    color: color('primary');
    font-weight: 600;
  }
  > * {
    + * {
      margin-top: .19em;
    }
  }
}
.ContactItemControls {
  display: flex;
  flex: 0 1 20rem;
  > * {
    flex: 1 1 33.33%;
    border-left: 1px solid color('primary', .2);
    .ContactItem.portrait & {
      &:first-child {
        border-left: 0;
      }
    }
  }
}