@import '../../../../../styles/base';

.ExploreThoughtCatcher {
  margin-top: -17.5em;
  padding-top: 17.5em;
  padding-bottom: size(md);
  overflow: hidden;
  > .SwiperContainer {
    height: 17.5rem;
    width: calc(100% + 1em);
    margin-left: -.5em;
    overflow: visible;
    > .swiper-wrapper {
      > .SwiperSlide {
        padding-left: .5em;
        padding-right: .5em;
      }
    }
  }
}