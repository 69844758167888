@import '../../styles/base';


.OverlayFeedbackForm {
  text-align: center;
  padding: size(lg);
  border-top-color: color('white', .1);
  background-color: color('primary');
  background-image: radial-gradient(138% 100% at 90% 75%, color('primary', .1), color('blue', .2));
  color: contrastColor('primary');
  [data-appearance="sheet"] & {
    min-height: 100%;
  }
  > * {
    + * {
      margin-top: 1em;
    }
  }
  h3 {
    margin-top: 0.19em;
    font-size: 2.8rem;
    font-weight: 300;
  }
  footer {
    font-style: italic;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 2px solid contrastColor('primary', .2);
  }
  .OverlayCloseButton {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
  }
}
.OverlayFeedbackFormIcon {
  font-size: 2.4rem;
}