@import '../../styles/base';

.VoipCallStarter {
  position: relative;
  .BaseInput {
    input {
      padding-right: 3.8rem;
      border-color: transparent;
      border-radius: 0;
      &:hover {
        background-color: color('primary', .38);
      }
    }
  }
  .SearchBarSubmitButton {
    min-width: 3.8rem;
    .BaseButton {
      border-radius: 0;
    }
  }
  .AutosuggestSearchBarResultItem {
    margin: .38em;
    border-radius: .3em;
    padding-left: .62em;
    padding-right: .5em;
    .BaseButton {
      background-color: color('green', .2);
      color: color('green');
    }
  }
  .AutosuggestSearchBarResultItemInner {
    padding-top: 0;
    padding-bottom: 0;
  }
}