@import '../../styles/base';

.MessengerPanel {
  @media print {
    display: none !important;
  }
  .LoadingIndicatorSection {
    border-radius: 0;
  }
}

.MessengerPanelInner {
}

.MessengerPanelHeader {
  padding: 1em;
  @media #{$tablet} {
    .ModuleClient & {
      padding-top: 1.38em;
      padding-bottom: 1.38em;
    }
    .ModuleAdmin & {
      padding-top: 1.62em;
    }
  }
  [data-mode="normal"] & {
    padding-top: 1em;
    padding-left: size(md);
  }
  border-bottom: 1px solid color('primary', .19);
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  .BaseButton {
    padding: 0;
    color: color('primary');
    + .BaseButton {
      margin-left: .5em;
    }
  }
}

h1.MessengerPanelTitle {
  font-size: 2.6rem;
  line-height: 1;
  font-weight: 800;
  @media #{$desktop} {
    font-size: 2.2rem;
  }
  span {
    display: block;
  }
}

.MessengerPanelContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  .--cypress & {
    scroll-behavior: unset;
  }
}
.MessengerPanelContentEmptyNotice {
  padding: 1em;
  font-style: italic;
}

.MessengerPanelFooter {
  .AppVersionBadge {
    @media #{$only-phone} {
      display: none;
    }
  }
}

.MessengerPanelContentErrorContainer {
  padding: 1em;
  background-color: color('red', .1);
}