@import '../../styles/base';

.CounsellingApplicationInfoTable {
  thead {
    th {
      border-top: 0 !important;
    }
  }
  .InfoTable {
    table-layout: auto;
  }
  tbody {
    th {
      @media #{$only-phone} {
        width: 61.8%;
        max-width: 16.8em;
      }
    }
    td {
      @media #{$only-phone} {
        width: 38.2%;
        min-width: 12.8em;
      }
      &.ApplicantContactAddress {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }
}