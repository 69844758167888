@import '../../styles/base';

.AppNavTab {
  appearance: none;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  border: none;
  flex: 1 1 1px;
  user-select: none;
  @media #{$tablet} {
    flex: 0 0 auto;
  }
  transition: padding-bottom .1s;
  box-sizing: content-box;
}
.AppNavTabInner {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: .75em .5em;
  padding-bottom: calc(env(safe-area-inset-bottom) + .75em);
  @media #{$only-small-phone} {
    padding-top: .38em;
    padding-bottom: .38em;
  }
  @media #{$tablet} {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: calc(env(safe-area-inset-left) * .75 + .5em);
  }
  @media #{$landscape-phone} {
    padding-top: .62em;
    padding-bottom: .62em;
  }
  @media #{$only-phone} {
    pointer-events: none;
  }
  text-decoration: none;
  &.active {
    background-color: color('skyblue', .1);
    [data-perf="high"] & {
      @media #{$only-phone} {
        background: radial-gradient(150% 150% at 50% 150%, color('primary', 0.38) 0%, color('primary', 0) 100%);
      }
      @media #{$tablet} {
        background-color: color('skyblue', .06);
      }
    }
    [data-perf="high"] .ModuleAdmin & {
      @media #{$tablet} {
        background-color: color('skyblue', .19);
      }
    }
    font-weight: 900;
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(.9);
  }
}
.AppNavTabIconWrapper {
  display: inline-flex;
  position: relative;
  padding-bottom: .19em;
}
.AppNavTabIcon {
  width: 2.4rem;
  height: 2.4rem;
}
.AppNavTabBadge {
  position: absolute;
  background-color: color('red');
  color: contrastColor('red');
  padding: .2em .5em;
  border-radius: 5em;
  font-weight: 800;
  font-size: 1rem;
  top: 0;
  right: 0;
  transform: translate(38%, -19%);
}
.AppNavTabTitle {
  font-size: 1.2rem;
  font-weight: 600;
}