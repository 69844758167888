@import '../../../../styles/base';

.CounsellorApplicationsOverviewItem {
  padding: .5em 0;
  border-top: 2px color(blueGreen, .19) solid;
  h4 {
    font-size: inherit;
    opacity: .85;
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
  em {
    opacity: .75;
  }
  .CounsellingClientProgressGraph {
    background-color: transparent;
    padding: 0;
  }
}

.CounsellorApplicationsOverviewItemHeader {
  display: flex;
  align-items: flex-start;
}

.CounsellorApplicationsOverviewItemHeaderInner {
  flex: 1 1 auto;
  > * {
    + * {
      margin-top: .5em;
    }
  }
}