@import '../../../../../styles/base';

.StatSection {
  padding: size(md);
  border-top: 2px solid color('foreground', .19);
  @media print {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
  &.contentHidden {
    @media print {
      display: none;
    }
  }
}
.StatSectionHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media #{$only-phone} {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  @media print {
    text-align: left;
  }
}
.StatSectionHeaderInner {
  flex: 1 1 auto;
}
.StatSectionHeaderControls {
  text-align: right;
  @media print {
    display: none;
  }
  .BaseIcon {
    width: 1.6rem;
    height: 1.6rem;
    color: color('foreground');
    @media #{$only-phone} {
      margin-top: -25%;
      margin-right: .19em;
      opacity: .75;
    }
    @media #{$tablet} {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
.StatSectionTitle {
  font-size: 1.4rem;
  @media #{$tablet} {
    font-size: 1.8rem;
  }
  color: inherit;
}
.StatSectionSubtitle {
  font-size: inherit;
  color: color('foreground', .75);
  @media #{$only-phone} {
    font-size: 1.2rem;
  }
}
.StatSectionInner {
  margin-top: 1em;
}