@import '../../../../../styles/base';

.PageChatSingle {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  padding-top: var(--TitleBarHeight);
  width: 100%;
  .nogrid & {
    display: block;
  }
  @media #{$only-phone} {
    height: calc(var(--AppHeight) - var(--AppNavigationHeight));
  }
  @media #{$tablet} {
    padding-top: 0;
    height: 100%;
  }
  @media print {
		height: auto;
    padding-top: 0;
	}
}