@import '../../styles/base';

.CustomScrollbar {
  width: 100%;
  border-radius: inherit;
  .ScrollbarsCustom-Track {
    background-color: color('primary', .05) !important;
  }
  .ScrollbarsCustom-Thumb {
    background-color: color('primary', .3) !important;
    &:hover {
      background-color: color('primary', .4) !important;
    }
  }
}