@import '../../styles/base';

.InfoDisplayList {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -.38em -.5em;
  list-style-type: none;
  > .InfoDisplayItem {
    margin: .38em .5em;
    flex: 0 0 auto;
  }
}