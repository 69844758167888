@import '../../styles/base';

.ChatMessageComposer {
  display: flex;
  align-items: stretch;
  position: relative;
  @media print {
    display: none;
  }
  .BaseInput {
    flex: 1 1 auto;
  }
  .BaseInputInner {
    height: 100%;
  }
  .BaseInputTextarea {
    border: none;
    display: block;
    border-radius: 0;
    min-height: 100%;
    padding-right: 4em;
  }
  .BaseButton {
    flex: 0 0 auto;
    text-transform: uppercase;
    border-radius: 50%;
    position: absolute;
    right: 0.5em;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
    &.subtle {
      background-color: color('primary', .38);
    }
    .BaseButtonInner {
      padding: 0;
    }
    .BaseIcon {
      position: relative;
      top: -.1em;
      left: -.1em;
    }
  }
}