@import '../../styles/base';

.ThoughtCatcherDisabledNoticeCard {
  border-radius: var(--CardRadius);
  background-color: color('white');
  background-image: radial-gradient(122.97% 151.94% at 53.51% 104.08%, rgba(0, 172, 211, 0.7) 0%, #004bac 100%);
  color: color('white');
  overflow: hidden;
  padding: size(md);
  text-align: center;
  line-height: 1.38;
  @extend %cardShadow;
  p, div {
    font-weight: 500;
  }
  > * {
    + * {
      margin-top: .5em;
    }
  }
}