$whitepointHsl: (33,0%,100%);
$blackpointHsl: (25,3%,8%);
$deepPurpleHsl: (248,33%,38%);

$colors: (
  
  'red': ( 
    hsl: (7,73%,56%), 
    contrast: $whitepointHsl,
  ),
  'orange': ( 
    hsl: (32,100%,50%), 
    contrast: $whitepointHsl,
  ),
  'yellow': ( 
    hsl: (43,98%,50%), 
    contrast: $deepPurpleHsl,
  ),
  'blueGreen': (
    hsl: (169,92%,34%),
    contrast: $whitepointHsl,
  ),
  'green': (
    hsl: (124,66%,38%),
    contrast: $whitepointHsl,
  ),
  'skyblue': ( 
    hsl: (198,100%,45%),
    contrast: $whitepointHsl,
  ),
  'blue': ( 
    hsl: (216,100%,47%),
    contrast: $whitepointHsl,
  ),
  'brighterBlue': ( 
    hsl: (216,100%,52%),
    contrast: $whitepointHsl,
  ),
  'brightPurple': (
    hsl: (241,73%,63%),
    contrast: $whitepointHsl,
  ),
  'purple': (
    hsl: (241,50%,50%),
    contrast: $whitepointHsl,
  ),
  'deepPurple': (
    hsl: (248,33%,38%),
    contrast: $whitepointHsl,
  ),
  'firebrick': ( 
    hsl: (7,48%,53%), 
    contrast: $whitepointHsl,
  ),
  'brown': ( 
    hsl: (26,55%,30%), 
    contrast: $whitepointHsl,
  ),
  'navy': ( 
    hsl: (220,53%,37%), 
    contrast: $whitepointHsl,
  ),
  'emerald': ( 
    hsl: (148,93%,30%), 
    contrast: $whitepointHsl,
  ),
  'neonGreen': ( 
    hsl: (151,86%,60%), 
    contrast: $deepPurpleHsl,
  ),
  'steel': (
    hsl: (199,28%,63%), 
    contrast: $whitepointHsl,
  ),
  'magenta': (
    hsl: (342,92%,53%), 
    contrast: $whitepointHsl,
  ),
  'ash': (
    hsl: (235,26%,30%),
    contrast: $whitepointHsl,
  ),
  'aqua': (
    hsl: (176,63%,54%),
    contrast: $whitepointHsl,
  ),

  'white': (
    hsl: $whitepointHsl,
    contrast: $deepPurpleHsl,
  ),
  'light': (
    hsl: (30,7%,94%),
    contrast: $whitepointHsl,
  ),
  'median': (
    hsl: (235,16%,45%),
    contrast: $whitepointHsl,
  ),
  'dark': (
    hsl: (238,22%,17%),
    contrast: $whitepointHsl,
  ),
  'black': ( // default darkest
    hsl: $blackpointHsl,
    contrast: $whitepointHsl,
  ),
  
) !default;

$contextColors: (
  'foreground': 'deepPurple',
  'background': 'white',
  'primary': 'skyblue',
  'secondary': 'blueGreen',
  'alert': 'red',
  'attention': 'orange',
  'neutral': 'skyblue',
  'positive': 'green',
);

@function staticColor($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  $color: map-get($colors, $colorName);
  $hsl: map-get($color, hsl);
  @if ($lx == 0 and $sx == null) {
    $lx: 1 + $lx;
    @if $sx == null {
      $sx: abs($lx);
    }
    @if ($alpha == 1) {
      @return hsl(nth($hsl,1),nth($hsl,2),nth($hsl,3));
    } @else {
      @return hsla(nth($hsl,1),nth($hsl,2),nth($hsl,3), $alpha);
    }
  } @else {
    $lx: 1 + $lx;
    @if $sx == null {
      $sx: abs($lx);
    }
    $sx: 1 + $sx;
    @return hsla(nth($hsl, 1),nth($hsl, 2)*$sx,nth($hsl, 3)*$lx, $alpha);   
  }
}
@function color($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  @if (map-get($colors, $colorName)) {
    @return staticColor($colorName, $alpha, $lx, $sx);
  }
  @if type-of($lx) == 'number' {
    $lx: 1 + $lx;
  }
  @if type-of($sx) == 'number' {
    $sx: 1 + $sx;
  }
  @if $sx == null {
    @if type-of($lx) == 'number' {
      $sx: abs($lx);
    } @else {
      $sx: $lx;
    }
  }
  @if ($lx == 1 & $sx == 1) {
    @return unquote('hsla(')
      var(--#{$namespace}-c-#{'' + $colorName}-hsl),
      $alpha
    unquote(')');
  } @else {
    @return hsla(
      var(--#{$namespace}-c-#{'' + $colorName}-h),
      calc(var(--#{$namespace}-c-#{'' + $colorName}-s)*#{$sx}),
      calc(var(--#{$namespace}-c-#{'' + $colorName}-l)*#{$lx}),
      $alpha
    );
  }
}
@function contrastColor($colorName: a, $alpha: 1, $lx: 0, $sx: null) {
  $lx: 1 + $lx;
  @if $sx == null {
    $sx: abs($lx);
  }
  @if $lx == 1 {
    @return unquote('hsla(')
      var(--#{$namespace}-c-#{'' + $colorName}_hsl),
      $alpha
    unquote(')');
  } @else {
    @return hsla(
      var(--#{$namespace}-c-#{'' + $colorName}_h),
      calc(var(--#{$namespace}-c-#{'' + $colorName}_s)*#{$sx}),
      calc(var(--#{$namespace}-c-#{'' + $colorName}_l)*#{$lx}),
      $alpha
    );
  }
}

@mixin setContextColor($contextColorName, $colorName) {
  --#{$namespace}-c-#{'' + $contextColorName}-h: var(--#{$namespace}-c-#{'' + $colorName}-h);
  --#{$namespace}-c-#{'' + $contextColorName}-s: var(--#{$namespace}-c-#{'' + $colorName}-s);
  --#{$namespace}-c-#{'' + $contextColorName}-l: var(--#{$namespace}-c-#{'' + $colorName}-l);
  --#{$namespace}-c-#{'' + $contextColorName}-hsl: var(--#{$namespace}-c-#{'' + $colorName}-hsl);
  --#{$namespace}-c-#{'' + $contextColorName}_h: var(--#{$namespace}-c-#{'' + $colorName}_h);
  --#{$namespace}-c-#{'' + $contextColorName}_s: var(--#{$namespace}-c-#{'' + $colorName}_s);
  --#{$namespace}-c-#{'' + $contextColorName}_l: var(--#{$namespace}-c-#{'' + $colorName}_l);
  --#{$namespace}-c-#{'' + $contextColorName}_hsl: var(--#{$namespace}-c-#{'' + $colorName}_hsl);
}

@mixin setForegroundColor($colorName) {
  @include setContextColor('foreground', $colorName);
}
@mixin setBackgroundColor($colorName) {
  @include setContextColor('background', $colorName);
}
@mixin setPrimaryColor($colorName) {
  @include setContextColor('primary', $colorName);
}
@mixin setSecondaryColor($colorName) {
  @include setContextColor('secondary', $colorName);
}
