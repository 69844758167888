@import '../../styles/base';

.ChatWindowParticipantEntry {
  padding: .38em 1em;
  min-width: 13em;
  max-width: 20rem;
  &.isSelf {
    font-weight: 700;
  }
  th, td {
    padding: .3em;
  }
  th {
    font-weight: inherit;
    padding-left: 1em;
    border-left: 3px solid transparent;
  }
  &.showOptions {
    th {
      padding-left: .3em;
    }
  }
  &.offline {
    opacity: .62;
  }
  .UsernameRenderer {
    @media screen {
      max-width: 9em;
      overflow: hidden;
    }
    font-size: 1.4rem;
  }
  &.removed {
    opacity: .62;
    font-style: italic;
  }
  .canViewUserDetails {
    cursor: pointer;
    &:hover {
      background-color: color('primary', .1);
    }
  }
}

td.ChatWindowParticipantEntryOnlineStatusCell {
  text-align: right;
  padding-right: .62em;
  @media print {
    display: none;
  }
}

td.ChatWindowParticipantEntryOnlineOptionsCell {
  padding: 0;
  width: 0;
  @media print {
    display: none;
  }
  .BaseButton {
    .BaseButtonInner {
      padding: .5em .38em;
    }
  }
  .BaseIcon {
    color: color('primary');
    font-size: 1.2rem;
    &:hover {
      filter: brightness(1.1);
    }
  }
}
.ChatWindowParticipantEntryOnlineOptionsCellInner {
  display: flex;
  > * {
    flex: 0 0 auto;
  }
}